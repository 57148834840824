import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastrSettings } from "../../../data/toastr";
import { ToastContainer, toast } from 'react-toastify';

interface IProps {
  id: number; // season id
}

const NotifyEntrants: React.FC<IProps> = ({ id }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [totalOutstanding, setTotalOutstanding] = useState<number>(0);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    async function fetchData() {
      await axios.get(`transactions/outstanding/count/${id}`).then(response => {
        const { data } = response;
        setTotalOutstanding(data.length);
      });
		}
		fetchData();
  }, [id]);

  const sendOustandingPaymentEmails = async () => {
    await axios.put(`transactions/outstanding/notify/${id}`).then(response => {
      const { data } = response;

      toast.success(`Notified ${data.length} entrants for ${totalOutstanding} entries`, ToastrSettings);
      handleClose();
    });
  }

  return (
    <div style={{ textAlign: 'right' }}>
      <ToastContainer />
      <Button 
        variant="contained" 
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />} 
        disableElevation 
      >
        Notify Entrants
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={sendOustandingPaymentEmails}>
          Outstanding Payments ({totalOutstanding})
        </MenuItem>
      </Menu>
    </div>
  );
}

export default NotifyEntrants;