import { TabPanelProps } from '../models/shared.model';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export function tabProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export function TabPanelHorisontal(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ paddingTop: 'var(--spacer-xs)' }}>
					<Typography component="div">{children}</Typography>
				</Box>
			)}
		</div>
	);
}
