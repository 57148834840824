import React, { useEffect, useState } from 'react';
import AdminLayout from '../../components/layouts/Admin';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { URLParams } from '../../models/shared.model';
import ReviewerDashboard from '../../components/dashboards/ReviewerDashboard';
import { IUser } from '../../models/user.model';

type IProps = {};

const ReviewerDashboardForAdmin: React.FC<IProps> = () => {
	let { id } = useParams<URLParams | any>(); // user ID
	const [user, setUser] = useState<IUser>();

	useEffect(() => {
		async function fetchData() {
			await axios.get(`users/${Number(id)}`).then((user) => {
				if (user && user?.data && user.data?.id) setUser(user.data);
			});
		}
		fetchData();
	}, [id]);

	return (
		<AdminLayout
			breadcrumbs={['Reviewer', `${user?.first_name} ${user?.last_name}`]}
		>
			{id && <ReviewerDashboard reviewerId={Number(id)} />}
		</AdminLayout>
	);
};

export default ReviewerDashboardForAdmin;
