import { Button, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ToastrSettings } from "../../../data/toastr";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { downloadCsv, reviewsCsvName } from "../../../utils/downloadCsv";

interface IProps {
  id: string | number | undefined;
  isInTable?: boolean;
  title?: string;
}

const SeasonOptions: React.FC<IProps> = ({ id, isInTable = false, title = '' }) => {
  const [isExportingReviews, setIsExportingReviews] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportReviews = async () => {
    setIsExportingReviews(true);

    const fileName = reviewsCsvName(title);

    await axios.get(`export/reviews/${id}`, {
      responseType: 'blob'
    }).then(({ data }) => {
      setIsExportingReviews(false);
      downloadCsv(fileName, data);
    });
  }

  return (
    <>
      <ToastContainer />
      {!isInTable && 
        <Button 
          className="me-3"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="outlined"
          color="secondary" 
        >
          <SettingsIcon 
            fontSize="small" 
            className="me-2" 
          />
          Options
        </Button>
      }

      {isInTable && 
        <Tooltip title="More Options" placement="top" arrow>
          <IconButton 
            color="secondary" 
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreHorizIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      }

      <Menu 
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {id && 
          <>
            {isInTable && 
              <MenuItem component={Link} to={`/seasons/${id}/edit/`}>
                Update
              </MenuItem>
            }
            <MenuItem component={Link} to={`/seasons/${id}/terms/`}>
              Terms and conditions
            </MenuItem>
            <MenuItem component={Link} to={`/seasons/${id}/form/`}>
              Entry Form
            </MenuItem>
            <MenuItem component={Link} to={`/seasons/${id}/grading/`}>
              Grading Categories
            </MenuItem>
            <MenuItem 
              onClick={exportReviews}
              disabled={isExportingReviews}
            >
              Export Reviews 
              {isExportingReviews &&
                <Spinner 
                  size="sm"
                  animation="border" 
                  role="status" 
                  style={{ 
                    marginLeft: '10px' 
                  }}
                />
              }
            </MenuItem>
            {!isInTable && <Divider />}
          </>
        }
        {!isInTable && 
          <MenuItem component={Link} to="/seasons">
            {id ? 'Back to list' : 'Cancel'}
          </MenuItem>
        }
      </Menu>
    </>
  );
}

export default SeasonOptions;