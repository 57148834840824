import { ILanguageOptions } from './translations';

export const genres: ILanguageOptions[] = [
	{
		eng: 'Poetic',
		afr: 'Poëties',
	},
	{
		eng: 'Expository',
		afr: 'Verklaring',
	},
	{
		eng: 'Observational',
		afr: 'Waarneming',
	},
	{
		eng: 'Participatory',
		afr: 'Deelnemend',
	},
	{
		eng: 'Reflexive',
		afr: 'Refleksief',
	},
	{
		eng: 'Performative',
		afr: 'Uitvoerend',
	},
];

export const translateGenre = (genre_english: string): string => {
	let genre_afrikaans = '';

	genres.forEach((item: ILanguageOptions) => {
		if (genre_english == item.eng) {
			genre_afrikaans = item.afr;
		}
	});

	return genre_afrikaans;
};
