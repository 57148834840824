import { Box, Card, CardContent, CardHeader, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AdminLayout from '../../components/layouts/Admin';
import AdminSubmissionsListView from '../../components/submissions/AdminListView';
import { ILanguage } from '../../models/language.model';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import { tabProps } from '../../functions/Tabs';
import NotifyEntrants from './components/NotifyEntrants';
import { ISeason } from '../../models/seasons.model';
import TabPanelVertical from '../../components/TabPanelVertical';

type IProps = {
	language: ILanguage;
};

const Submissions: React.FC<IProps> = ({ language }) => {
	const [seasons, setSeasons] = useState<ISeason[]>([]);
	const [value, setValue] = useState<number>(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get('seasons/active/keys');
			setSeasons(data);
		}
		fetchData();
	}, []);

	return (
		<AdminLayout maxWidth="lg">
			<Card sx={{ minWidth: 275 }}>
				<CardHeader
					style={{ backgroundImage: 'none' }}
					title={language.isEnglish ? 'All Submissions' : 'Alle Inskrywings'}
				/>
				<CardContent>
					<Box
						sx={{
							flexGrow: 1,
							display: 'flex',
						}}
					>
						<Tabs
							orientation="vertical"
							variant="scrollable"
							value={value}
							onChange={handleChange}
							sx={{
								maxWidth: 200,
							}}
						>
							{seasons.map((tab, index) => {
								return (
									<Tab
										sx={{ alignItems: 'flex-end' }}
										key={index}
										label={language.isEnglish ? tab.title : tab.title_afr}
										{...tabProps(index)}
									/>
								);
							})}
						</Tabs>
						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							{seasons.map((item, index) => {
								return (
									<TabPanelVertical key={index} value={value} index={index}>
										<NotifyEntrants id={item.id} />
										<AdminSubmissionsListView
											season_id={item.id}
											submissions={item.submissions}
										/>
									</TabPanelVertical>
								);
							})}
						</Box>
					</Box>
				</CardContent>
			</Card>
		</AdminLayout>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(Submissions);
