import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ILanguage } from '../../models/language.model';
import { ISubmission } from '../../models/submissions.model';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { ISeason, Season } from '../../models/seasons.model';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import EntryDetails from './components/EntryDetails';
import Icon from '../Icon';
import PublishModal from './actions/PublishModal';
import { User } from '../../models/user.model';
import { isStudent } from '../../utils/shared';
import EntryTabs from './components/EntryTabs';
import EditModal from './actions/EditModal';
import { ToastrSettings } from '../../data/toastr';
import PaymentIcon from '@mui/icons-material/Payment';

type IProps = {
	language: ILanguage;
	id: number;
	isAdmin?: boolean; // admin or user view (set to FALSE for user view)
	user: User;
};

const EntryDetailView: React.FC<IProps> = ({
	language,
	id,
	isAdmin = false,
	user,
}) => {
	const [season, setSeason] = useState<ISeason>(new Season());
	const [entry, setEntry] = useState<ISubmission>();
	const [reload, setReload] = useState<boolean>(false);
	const [publishStep, setPublishStep] = useState<number>(0);

	// Modals
	const [undoPaymentDialogOpen, setUndoPaymentDialogState] =
		useState<boolean>(false);
	const [paymentDialogOpen, setPaymentDialogState] = useState<boolean>(false);
	const [publishDialogOpen, setPublishDialogState] = useState<boolean>(false);
	const [editModal, setEditModal] = useState<boolean>(false);

	const openOverridePaymentDialog = () => {
		setPaymentDialogState(true);
	};

	const closeOverridePaymentDialog = () => {
		setPaymentDialogState(false);
	};

	const openUndoPaymentOverrideDialog = () => {
		setUndoPaymentDialogState(true);
	};

	const closeUndoPaymentOverrideDialog = () => {
		setUndoPaymentDialogState(false);
	};

	useEffect(() => {
		async function fetchData() {
			const endpoint = isAdmin ? 'submission' : 'entry';
			const { data } = await axios.get(`${endpoint}/${id}`);
			const { answers, season, ...entry } = data;

			setSeason(season);
			setEntry(entry);
			setReload(false);
		}
		fetchData();
	}, [id, reload]);

	const overridePayment = async () => {
		await axios.put(`submission/${id}/paymentoverride`);
		toast.success('Payment override successfully!', ToastrSettings);
		closeOverridePaymentDialog();
	};

	const undoPaymentOverride = async () => {
		await axios.put(`submission/${id}/undopaymentoverride`);
		toast.success('Payment override reversed successfully!', ToastrSettings);
		closeUndoPaymentOverrideDialog();
	};

	return (
		<>
			<ToastContainer />
			<Card>
				<CardHeader
					avatar={
						<Link
							to={isAdmin ? '/submissions' : '/entries'}
							className="icon-button small"
						>
							<span className="icon icon-chevron-left"></span>
						</Link>
					}
					title={`${language.isEnglish ? 'Entry' : 'Inskrywing'} #${id}`}
					action={
						<>
							{entry?.transaction && entry.transaction.payment_date && (
								<Chip
									sx={{
										marginTop: '4px',
									}}
									color="primary"
									label="Paid"
								/>
							)}
							{isAdmin && !entry?.transaction && (
								<>
									{!entry?.payment_override && (
										<Button
											onClick={openOverridePaymentDialog}
											disableRipple
											size="small"
										>
											<PaymentIcon fontSize="small" className="me-1" />
											Override Payment
										</Button>
									)}

									{entry?.payment_override && (
										<Button
											onClick={openUndoPaymentOverrideDialog}
											disableRipple
											size="small"
										>
											<PaymentIcon fontSize="small" className="me-1" />
											Undo Payment Override
										</Button>
									)}
								</>
							)}
							{!isAdmin && (
								<>
									{entry?.draft && (
										<Button
											variant="contained"
											color="secondary"
											onClick={() => setEditModal(true)}
										>
											<Icon icon="edit" />
											Edit
										</Button>
									)}

									{entry?.draft && (
										<Button
											variant="contained"
											color="primary"
											onClick={() => {
												setPublishDialogState(true);
											}}
										>
											<Icon icon="publish" />
											Publish
										</Button>
									)}

									{!entry?.draft &&
										entry?.transaction &&
										!entry?.transaction.payment_date &&
										!isStudent(user.entry_type) && (
											<Button
												variant="contained"
												color="primary"
												onClick={() => {
													setPublishDialogState(true);
													setPublishStep(1);
												}}
											>
												<Icon icon="payments" />
												Pay
											</Button>
										)}
								</>
							)}
						</>
					}
					sx={{ backgroundImage: 'none' }}
				/>
				<CardContent>
					<EntryDetails
						cost={season?.cost}
						title={season?.title}
						title_afr={season?.title_afr}
						end_date={season.end_date}
					/>

					<EntryTabs
						seasonId={season.id}
						id={id}
						isAdmin={isAdmin}
						reload={reload}
					/>
				</CardContent>
			</Card>

			<PublishModal
				entryID={id}
				cost={season?.cost ? season?.cost : 0}
				open={publishDialogOpen}
				step={publishStep}
				transactionID={entry?.transaction ? entry?.transaction.id : 0}
				onCloseModal={(closed: boolean, reload: boolean) => {
					setPublishDialogState(!closed);
					setReload(reload);
				}}
			/>

			<EditModal
				entryID={id}
				open={editModal}
				language={language}
				onCloseModal={(close: boolean, reload: boolean) => {
					setEditModal(false);
					setReload(reload);
				}}
			/>

			{/* Override Payment */}
			<Dialog open={paymentDialogOpen} onClose={closeOverridePaymentDialog}>
				<DialogTitle>Override payment</DialogTitle>
				<DialogContent>
					<DialogContentText sx={{ marginTop: 2 }}>
						You can always undo this by clicking on "Options" then "Undo Payment
						Override".
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						autoFocus
						onClick={closeOverridePaymentDialog}
					>
						Cancel
					</Button>
					<Button variant="contained" onClick={overridePayment}>
						Save and continue
					</Button>
				</DialogActions>
			</Dialog>

			{/* Undo Payment Override */}
			<Dialog
				open={undoPaymentDialogOpen}
				onClose={closeUndoPaymentOverrideDialog}
			>
				<DialogTitle>Reverse Payment Override</DialogTitle>
				<DialogContent>
					<DialogContentText sx={{ marginTop: 2 }}>
						You can always undo this by clicking on "Options" then "Override
						Payment".
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						autoFocus
						onClick={closeUndoPaymentOverrideDialog}
					>
						Cancel
					</Button>
					<Button variant="contained" onClick={undoPaymentOverride}>
						Save and continue
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default connect(mapLanguageStateToProps)(EntryDetailView);
