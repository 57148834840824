import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BurgerMenu from '../burger-menu';
import { connect } from 'react-redux';
import { HeaderNavItem } from '../../models/shared.model';
import AppBarLanguage from '../admin/bar/AppBarLanguage';
import {
	mapDispatchToProps,
	mapStateToProps,
} from '../../redux/props/user.props';
import { User } from '../../models/user.model';

type Anchor = 'bottom';

interface IProps {
	menu: HeaderNavItem[];
}

const MobileMenu: React.FC<IProps> = ({ menu }) => {
	const [state, setState] = React.useState({
		bottom: false,
	});

	const toggleDrawer =
		(anchor: Anchor, open: boolean) =>
		(event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event &&
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			setState({ ...state, [anchor]: open });
		};

	const list = (anchor: Anchor) => (
		<Box
			sx={{ width: 'auto' }}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List>
				{menu.map((item, index) => (
					<ListItem key={`mobile-menu-${index}`} disablePadding>
						<ListItemButton href={item.url}>
							<ListItemIcon>
								<span className={`icon icon-${item.icon}`}></span>
							</ListItemIcon>
							<ListItemText primary={item.label} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Divider sx={{ margin: 0 }} />
			<List sx={{ paddingLeft: 0, paddingRight: 0 }}>
				<ListItem>
					<AppBarLanguage mobile={true} />
				</ListItem>
			</List>
		</Box>
	);

	return (
		<Box sx={{ marginLeft: 'var(--spacer)', order: 2 }}>
			{(['bottom'] as const).map((anchor) => (
				<React.Fragment key={anchor}>
					<BurgerMenu
						className="mobile-burger-menu-main"
						open={state[anchor]}
						onClick={toggleDrawer(anchor, true)}
					/>
					<SwipeableDrawer
						anchor={anchor}
						open={state[anchor]}
						onClose={toggleDrawer(anchor, false)}
						onOpen={toggleDrawer(anchor, true)}
					>
						{list(anchor)}
					</SwipeableDrawer>
				</React.Fragment>
			))}
		</Box>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
