import Languages from '../../data/languages';

export const setLanguage = (language: string) => {
	const isEnglish = language === Languages[1] ? false : true;

	return {
		type: 'SET_LANGUAGE',
		active: language,
		isEnglish: isEnglish,
	};
};
