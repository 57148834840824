import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { ILanguage } from '../../models/language.model';
import axios from 'axios';
import Review from './Review';
import AdminLayout from '../../components/layouts/Admin';
import Title from '../../components/Typography/Title';
import { ISeasonMenu } from '../../models/seasons.model';
import Loading from '../../components/Loading';
import NoDataAvailable from '../../components/NoDataAvailable';
import { Box, Tab } from '@mui/material';
import { TabPanelHorisontal, tabProps } from '../../functions/Tabs';
import HorizontalTabs from '../../components/HorizontalTabs';

interface IProps {
	language: ILanguage;
}

const Reviews: React.FC<IProps> = ({ language }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [seasons, setSeasons] = useState<ISeasonMenu[]>([]);
	const [value, setValue] = useState<number>(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			await axios.get(`menu/seasons`).then((seasons) => {
				setSeasons(seasons.data);
				setLoading(false);
				setValue(0);
			});
		}
		fetchData();
	}, []);

	return (
		<AdminLayout maxWidth="lg">
			<Title>Reviews</Title>
			{loading && <Loading text="Please wait while we fetch all seasons" />}

			{!loading && seasons.length === 0 && (
				<NoDataAvailable message="There are no seasons available" />
			)}

			{!loading && seasons.length > 0 && (
				<Box
					sx={{
						bgcolor: 'background.paper',
						marginTop: 3,
					}}
				>
					<HorizontalTabs active={value} onChange={handleChange}>
						{seasons.map((season: ISeasonMenu, index: number) => {
							return (
								<Tab
									sx={{ alignItems: 'flex-end' }}
									key={index}
									label={language.isEnglish ? season.title : season.title_afr}
									{...tabProps(index)}
								/>
							);
						})}
					</HorizontalTabs>
					<Box
						sx={{
							flexGrow: 1,
						}}
					>
						{seasons.map((season: ISeasonMenu, index: number) => {
							return (
								<TabPanelHorisontal key={index} value={value} index={index}>
									<Review season_id={season.id} season_title={season.title} />
								</TabPanelHorisontal>
							);
						})}
					</Box>
				</Box>
			)}
		</AdminLayout>
	);
};

export default connect(mapLanguageStateToProps)(Reviews);
