import _ from "lodash";
import React from "react";
import { Pagination } from "react-bootstrap";

interface IProps {
  lastPage: number;
  page: number;
  pageChanged: (page: number) => void;
}

const Paginator: React.FC<IProps> = ({ lastPage, page, pageChanged }) => {
  const first = () => {
    pageChanged(1);
  }

  const last = () => {
    pageChanged(lastPage);
  }

  const previous = () => {
		if (page >= 1) {
			pageChanged(page - 1);
		}
  }

  const next = () => {
		if (page < lastPage) {
			pageChanged(page + 1);
		}
  }

	const changePage = (pageNumber: number) => (event: any) => {
		pageChanged(pageNumber);
	}

  return (
    <>
    {lastPage > 1 && (
      <Pagination>
        <Pagination.First onClick={first} disabled={page === 1} />
        <Pagination.Prev onClick={previous} disabled={page === 1} />
        {_.times(lastPage, (i) => (
          <Pagination.Item onClick={changePage(i+1)} key={i} disabled={page === (i+1)}>{i+1}</Pagination.Item>
        ))}
        <Pagination.Next onClick={next} disabled={lastPage === page} />
        <Pagination.Last onClick={last} disabled={lastPage === page} />
      </Pagination>
    )}
    </>
  );
}

export default Paginator;