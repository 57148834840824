import { Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

const HeadingThree: React.FC<any> = ({ children, ...props }) => {
	return (
		<Typography
			variant="h3"
			sx={{
				lineHeight: 1,
				marginBottom: 2,
			}}
			{...props}
		>
			{children}
		</Typography>
	);
};

export default styled(HeadingThree)({});
