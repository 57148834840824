import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { ILanguage } from '../../models/language.model';
import { connect } from 'react-redux';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import { ISeasonID } from '../../models/submissions.model';
import EntriesPerSeason from '../reviewer/EntriesPerSeason';

type IProps = {
	language: ILanguage;
	reviewerId?: number;
};

const ReviewerDashboard: React.FC<IProps> = ({ language, reviewerId }) => {
	const [seasons, setSeasons] = useState<ISeasonID[]>([]);

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get('seasons/active/keys');
			setSeasons(data);
		}
		fetchData();
	}, []);

	return (
		<Box gap="16px" display="flex" flexDirection="column">
			{seasons.map((item: ISeasonID) => {
				return (
					<EntriesPerSeason
						language={language}
						season={item}
						season_id={item.id}
						reviewerId={reviewerId}
					/>
				);
			})}
		</Box>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(ReviewerDashboard);
