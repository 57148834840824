import * as React from 'react';
import { MDBCol, MDBContainer, MDBFooter, MDBRow } from 'mdb-react-ui-kit';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Brand from '../../data/brand';
import { ILanguage } from '../../models/language.model';
import { connect } from 'react-redux';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import { Container, Typography } from '@mui/material';

interface IProps {
	language: ILanguage;
}

const Footer: React.FC<IProps> = ({ language }) => {
	return (
		<>
			<MDBFooter
				className="text-center text-lg-start text-muted"
				style={{ marginTop: 'auto', background: 'var(--grey-020)' }}
			>
				<Container
					component="main"
					maxWidth="xl"
					className="text-center text-md-start mt-4"
					sx={{ paddingTop: '0 !important', paddingBottom: '0 !important' }}
				>
					<MDBRow className="mt-3">
						<MDBCol md="12" lg="4" xl="4" className="mb-4">
							<Typography fontWeight="bold" variant="bodyL">
								{language.isEnglish ? 'Need help?' : 'Het jy hulp nodig?'}
							</Typography>
							<Typography variant="bodyL">
								{language.isEnglish
									? 'If you need any assistance, or have any questions – please contact us at'
									: 'Indien jy enige hulp nodig het, of vrae het – kontak ons gerus by'}
								&nbsp;
								<a className="text-reset" href={`mailto:${Brand.email}`}>
									{Brand.email}
								</a>
							</Typography>
						</MDBCol>

						<MDBCol md="4" lg="3" xl="3" className="mb-md-0 mb-4">
							<Typography fontWeight="bold" variant="bodyL" mb={1}>
								{language.isEnglish ? 'Follow us' : 'Volg ons'}
							</Typography>
							<a
								href="https://www.facebook.com/Silwerskermfees/"
								target="_blank"
								className="me-3 text-reset"
								rel="noopener noreferrer"
							>
								<FacebookIcon fontSize="medium" />
							</a>
							<a
								href="https://twitter.com/silwerskermfees?lang=en"
								target="_blank"
								className="me-3 text-reset"
								rel="noopener noreferrer"
							>
								<TwitterIcon fontSize="medium" />
							</a>
							<a
								href="https://www.instagram.com/explore/tags/silwerskermfees/?hl=en"
								target="_blank"
								className="me-3 text-reset"
								rel="noopener noreferrer"
							>
								<InstagramIcon fontSize="medium" />
							</a>
						</MDBCol>
					</MDBRow>
				</Container>
			</MDBFooter>
		</>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(Footer);
