import { Link } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';

interface IParams {
	row: {
		id: number;
		season_id: number;
		reviewed: number;
		reviewer_id: number;
	};
}

export const RenderReviewersButtons = (params: IParams) => {
	return (
		<Button
			component={Link}
			to={`/review-entry/${params.row.season_id}/${params.row.id}/${params.row.reviewer_id}`}
			variant="contained"
			color="secondary"
			sx={{ fontWeight: 'bold', justifyContent: 'flex-start' }}
			fullWidth
		>
			{params.row.reviewed && (
				<>
					<span className="icon icon-readmore"></span>
					View
				</>
			)}

			{!params.row.reviewed && (
				<>
					<span className="icon icon-rate"></span>
					Review
				</>
			)}
		</Button>
	);
};
