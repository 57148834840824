import { Box, Button, ButtonProps, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";

interface IProps extends ButtonProps {
  isLoading: boolean;
  text: string;
}

const LoadingButton: React.FC<IProps> = ({ isLoading, text, ...props }) => {
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Button disableElevation disabled={isLoading} {...props}>
          {text}
        </Button>
        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[900],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    </>
  );
};

export default LoadingButton;
