import { styled } from "@mui/material/styles";
import { StyledProps } from "@mui/styles";
import { Typography } from "@mui/material";
import React from "react";

interface IProps {
  message?: string;
  props?: StyledProps;
}

const NoDataAvailable: React.FC<IProps> = ({ 
  message = 'There is no data available',
  ...props
}) => {
  return (
    <Typography 
      gutterBottom
      {...props}
    >
      {message}
    </Typography>
  );
}

export default styled(NoDataAvailable)({});