import React, { useEffect, useState } from 'react';
import { ISubmission } from '../../../models/submissions.model';
import { useSearchParams } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import DialogClose from '../../DialogClose';
import StepperNav from '../../transactions/StepperNav';
import Step1Confirmation from '../../transactions/Step1Confirmation';
import Step2PaymentDetails from '../../transactions/Step2PaymentDetails';
import Step3ReviewOrder from '../../transactions/Step3ReviewOrder';
import { User } from '../../../models/user.model';
import { mapStateToProps } from '../../../redux/props/user.props';
import { connect } from 'react-redux';
import { isStudent } from '../../../utils/shared';

type ITransaction = {
	transaction_id: number;
	entry_id: number;
	status: string;
};

interface IProps {
	onCloseModal: (close: boolean, reload: boolean) => void;
	entryID: number | null;
	open: boolean;
	cost: number;
	step: number;
	transactionID: number;
	user: User;
}

const PublishModal: React.FC<IProps> = ({
	entryID,
	open,
	cost,
	step,
	transactionID,
	onCloseModal,
	user,
}) => {
	const [publishDialogOpen, setPublishDialogState] = useState<boolean>(open);
	const [selectedSubmission, setSelectedSubmission] = useState<number | null>(
		entryID
	);
	const [publishStep, setPublishStep] = useState<number>(0);
	const [seasonCost, setSeasonCost] = useState<number>(cost);
	const [transactionId, setTransactionId] = useState<number>(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const [retry, setRetry] = useState<boolean>(false);
	const [transaction, setTransaction] = useState<ITransaction | null>(null);

	useEffect(() => {
		setPublishDialogState(open);
		setSelectedSubmission(entryID);
		setTransactionId(transactionID);
		setSeasonCost(cost);
		setPublishStep(step);

		if (searchParams.has('status')) {
			const transaction: ITransaction = {
				transaction_id: Number(searchParams.get('transaction_id')),
				entry_id: Number(searchParams.get('entry_id')),
				status: String(searchParams.get('status')),
			};

			setTransaction(transaction);
			setTransactionId(transaction.transaction_id);
			setSelectedSubmission(transaction.entry_id);
			setPublishDialogState(true);
			setPublishStep(2);
		}
	}, [open, step, entryID]);

	const closePublishDialog = (reload: boolean) => {
		setPublishDialogState(false);
		setTransaction(null);
		clearSearchParams();
		onCloseModal(true, reload);
	};

	const clearSearchParams = () => {
		setSearchParams({});
	};

	return (
		<>
			<Dialog open={publishDialogOpen} onClose={closePublishDialog}>
				<DialogTitle>
					Finalise Entry #{selectedSubmission}
					<DialogClose
						onClick={(e) => {
							e.preventDefault();
							closePublishDialog(false);
							setPublishStep(0);
						}}
					/>
				</DialogTitle>

				{!isStudent(user.entry_type) && <StepperNav active={publishStep} />}

				{selectedSubmission && (
					<DialogContent
						sx={{
							font: 'var(--subheading-s)',
							color: 'var(--grey-800)',
							paddingTop: 'var(--spacer-xs)',
							marginTop: isStudent(user.entry_type) ? 'var(--px)' : 0,
						}}
					>
						{publishStep === 0 && (
							<Step1Confirmation
								entryId={selectedSubmission}
								toggleNextStep={(num, transaction, done) => {
									setPublishStep(num);
									setTransactionId(transaction);

									if (done) {
										closePublishDialog(true);
									}
								}}
							/>
						)}

						{publishStep === 1 && (
							<Step2PaymentDetails
								transactionId={transactionId}
								entryId={selectedSubmission}
								cost={seasonCost}
								retry={retry}
								toggleNextStep={(num) => {
									setPublishStep(num);
								}}
							/>
						)}

						{publishStep === 2 && transactionId > 0 && (
							<Step3ReviewOrder
								onDone={(done, retry) => {
									if (done) {
										clearSearchParams();
										setSearchParams(searchParams);
										setTransaction(null);
										closePublishDialog(true);
										setRetry(false);
										setPublishStep(0);
									}

									if (retry && searchParams.has('transaction_id')) {
										setTransactionId(
											Number(searchParams.has('transaction_id'))
										);

										setRetry(true);
										setSelectedSubmission(Number(searchParams.get('entry_id')));
										setSeasonCost(Number(cost));
										setPublishStep(1);
									}
								}}
								transactionId={transactionId}
								transactionStatus={transaction?.status}
							/>
						)}
					</DialogContent>
				)}
			</Dialog>
		</>
	);
};

export default connect(mapStateToProps)(PublishModal);
