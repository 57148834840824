import { Stack } from "@mui/material";
import React, { forwardRef } from "react"

export const SortableWrapper = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
		<Stack 
			direction="column" 
			spacing={1} 
			width={'100%'}
			ref={ref} 
			className={(!React.Children.count(props.children)) ? 'dropzone' : ''} 
			sx={{ position: 'relative' }}
		>
			{props.children} 
		</Stack>
	);
});