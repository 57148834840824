import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/font/inter.css';
import './assets/icons/styles.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { configureStore } from './redux/configureStore';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReCAPTCHA from './data/recaptcha';

export const TEST_MODE: boolean = true;

axios.defaults.baseURL = 'http://silwerskerm.tv/api/';
// axios.defaults.baseURL = 'http://localhost:8000/api/';
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const store = configureStore();

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<GoogleReCaptchaProvider reCaptchaKey={ReCAPTCHA.site_key}>
					<App />
				</GoogleReCaptchaProvider>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
