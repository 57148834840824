import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Checkbox,
	Container,
	FormControlLabel,
	Paper,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React, { SyntheticEvent, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import AdminLayout from '../../components/layouts/Admin';
import StepperNav from '../../components/transactions/StepperNav';

interface IProps {}

interface URLParams {
	id: any;
}

const ConfirmEntry: React.FC<IProps> = () => {
	const { id } = useParams<URLParams | any>();
	const cookies = new Cookies();

	const [loading, setLoading] = useState<boolean>(false);
	const [checked, setChecked] = useState<boolean>(false);
	const [redirect, setRedirect] = useState<boolean>(false);
	const [transaction_id, setTransactionID] = useState<number>();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const onNextClick = async (e: SyntheticEvent) => {
		e.preventDefault();
		setLoading(true);

		const response = await axios.post(`entry/${id}/start`, {
			agreed_to_terms: checked,
			user_id: Number(cookies.get('user')),
		});

		if (response.data) {
			setTransactionID(response.data.id);
			setRedirect(true);
		}
	};

	if (redirect) {
		return <Navigate to={`/entries/${id}/transact/pay/${transaction_id}`} />;
	}

	return (
		<AdminLayout>
			<Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
				<Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
					<StepperNav active={0} />

					{loading && <p>Loading...</p>}

					<Typography variant="h3" className="mb-3">
						By submitting your entry
					</Typography>

					<Typography variant="subtitle1">
						You agree to the terms and conditions located
						<Button
							variant="outlined"
							size="small"
							className="ms-2 mx-2"
							component={Link}
							to={`/terms`}
						>
							here
						</Button>
					</Typography>

					<FormControlLabel
						control={
							<Checkbox
								checked={checked}
								onChange={handleChange}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label="I understand"
					/>

					<Alert severity="error" className="mt-3">
						<AlertTitle>Please note</AlertTitle>
						Once your payment has been made, you won't be able to make changes
						to your entry again.
					</Alert>

					<Box
						component="div"
						sx={{ display: 'flex', justifyContent: 'flex-end' }}
					>
						<Button
							disabled={!checked}
							variant="contained"
							sx={{ mt: 3, ml: 1 }}
							onClick={onNextClick}
						>
							Next
						</Button>
					</Box>
				</Paper>
			</Container>
		</AdminLayout>
	);
};

export default ConfirmEntry;
