import React, { SyntheticEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../../Loading';
import Comment from '../../Comment';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	TextField,
	Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonTooltip from '../../ButtonTooltip';
import axios from 'axios';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { Form } from 'react-bootstrap';
import { User } from '../../../models/user.model';
import {
	mapDispatchToProps,
	mapStateToProps,
} from '../../../redux/props/user.props';
import { IComment } from '../../../models/comment.model';
import { formatDateTime } from '../../../functions/Global';
import { ToastrSettings } from '../../../data/toastr';
import { ToastContainer, toast } from 'react-toastify';

type IProps = {
	user: User;
	id: number;
	isAdmin?: boolean;
};

const EntryComments: React.FC<IProps> = ({ user, id, isAdmin = false }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [comments, setComments] = useState<IComment[]>([]);
	const [comment, setComment] = useState<string>('');
	const [deleteModal, setOpenDeleteModal] = useState<boolean>(false);
	const [updateModal, setOpenUpdateModal] = useState<boolean>(false);
	const [selectedComment, setSelectedComment] = useState<string>();
	const [selected, setSelected] = useState<number>();

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			await axios.get(`comments/${id}`).then((response) => {
				const { data } = response;
				setComments(data);
				setLoading(false);
			});
		}
		fetchData();
	}, [id]);

	const addComment = async (e: SyntheticEvent) => {
		e.preventDefault();

		const comments = await axios.post(`comments/${id}`, {
			comment,
			user_id: user.id,
		});

		toast.success('Comment added successfully!', ToastrSettings);
		setComments(comments.data);
		setComment('');
	};

	const deleteComment = async (e: SyntheticEvent) => {
		e.preventDefault();

		const comments = await axios.delete(`comments/${id}/${selected}`);

		toast.success('Comment deleted successfully!', ToastrSettings);
		setComments(comments.data);
		setSelected(undefined);
		closeDeleteModal();
	};

	const updateComment = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const form_data = new FormData(event.currentTarget);

		const comments = await axios.put(`comments/${id}/${selected}`, {
			comment: form_data.get('comment'),
		});

		toast.success('Comment updated successfully!', ToastrSettings);
		setComments(comments.data);
		setSelected(undefined);
		closeUpdateModal();
	};

	const openDeleteModal = (id: number) => {
		setSelected(id);
		setOpenDeleteModal(true);
	};

	const openUpdateModal = (id: number, comment: string) => {
		setSelected(id);
		setOpenUpdateModal(true);
		setSelectedComment(comment);
	};

	const closeDeleteModal = () => {
		setSelected(undefined);
		setOpenDeleteModal(false);
	};

	const closeUpdateModal = () => {
		setOpenUpdateModal(false);
		setSelected(undefined);
	};

	return (
		<>
			<ToastContainer />
			<Form onSubmit={addComment}>
				<FormControl variant="outlined" fullWidth sx={{ marginBottom: 5 }}>
					<InputLabel>Add a comment</InputLabel>
					<OutlinedInput
						label="Add a comment"
						value={comment}
						type="text"
						onChange={(e) => setComment(e.target.value)}
						required
						name="comment"
						endAdornment={
							<InputAdornment position="end">
								<IconButton edge="end" type="submit">
									<AddCommentIcon color="secondary" />
								</IconButton>
							</InputAdornment>
						}
					/>
				</FormControl>
			</Form>

			{loading && <Loading />}

			{!loading && comments.length === 0 && (
				<Typography sx={{ marginBottom: 4 }}>
					There are no comments available
				</Typography>
			)}

			{!loading && comments.length > 0 && (
				<>
					{comments.map((item) => (
						<Comment
							key={item.id}
							person={`${item.user.first_name} ${item.user.last_name}`}
							date={formatDateTime(new Date(item.created_date))}
							role={item.user.role.name}
							comment={item.comment}
							actions={
								<>
									{item.user.id === user.id && (
										<>
											<ButtonTooltip text="Update comment">
												<IconButton
													onClick={() => openUpdateModal(item.id, item.comment)}
												>
													<EditIcon color="secondary" />
												</IconButton>
											</ButtonTooltip>
											<ButtonTooltip text="Delete comment">
												<IconButton onClick={() => openDeleteModal(item.id)}>
													<DeleteIcon color="secondary" />
												</IconButton>
											</ButtonTooltip>
										</>
									)}
								</>
							}
						/>
					))}
				</>
			)}

			<Dialog open={deleteModal} onClose={closeDeleteModal}>
				<DialogTitle>Delete Comment</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this comment?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button size="small" variant="contained" onClick={closeDeleteModal}>
						No, Go back
					</Button>
					<Button
						size="small"
						variant="contained"
						color="secondary"
						onClick={deleteComment}
					>
						Yes, Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={updateModal}
				onClose={closeUpdateModal}
				sx={{ minWidth: '300px' }}
			>
				<Form onSubmit={updateComment}>
					<DialogTitle>Update Comment</DialogTitle>
					<DialogContent>
						<TextField
							defaultValue={selectedComment}
							fullWidth
							id="name"
							label="Comment"
							type="text"
							variant="standard"
							name="comment"
							multiline
						/>
					</DialogContent>
					<DialogActions sx={{ p: 3, paddingTop: 0 }}>
						<Button
							size="small"
							variant="contained"
							onClick={closeUpdateModal}
							type="button"
						>
							Back to comments
						</Button>
						<Button
							size="small"
							variant="contained"
							color="secondary"
							type="submit"
						>
							Update comment
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryComments);
