import {
	Box,
	Breadcrumbs,
	Button,
	Grid,
	Link,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import SaveIcon from '@mui/icons-material/Save';
import { Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastrSettings } from '../../data/toastr';
import { ISeason, Season } from '../../models/seasons.model';
import SeasonOptions from './components/Options';
import { connect } from 'react-redux';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import { ILanguage } from '../../models/language.model';
import translations from '../../data/translations';

interface IProps {
	language: ILanguage;
}

interface URLParams {
	id: number;
}

// TODO NB

const SeasonEdit: React.FC<IProps> = ({ language }) => {
	let { id } = useParams<URLParams | any>();
	const [redirect, setRedirect] = useState<boolean>(false);
	const [value, setValue] = useState('');
	const [valueAfr, setValueAfr] = useState('');
	const [season, setSeason] = useState<ISeason>(new Season());

	useEffect(() => {
		async function fetchData() {
			if (id) {
				const { data } = await axios.get(`seasons/${id}`, {
					params: {
						file: 'src/pages/seasons/SeasonTerms.tsx',
					},
				});

				setSeason(data);
				setValue(data.terms_and_conditions);
				setValueAfr(data.terms_and_conditions_afr);
			}
		}
		fetchData();
	}, [id]);

	const submit = async (e: SyntheticEvent) => {
		e.preventDefault();

		await axios
			.put(`seasons/${id}`, {
				terms_and_conditions: value,
				terms_and_conditions_afr: valueAfr,
			})
			.then((response) => {
				toast.success(
					'Terms and conditions updated successfully!',
					ToastrSettings
				);
			});

		//setRedirect(true);
	};

	if (redirect) {
		return <Navigate to={'/seasons'} />;
	}

	return (
		<AdminLayout>
			<ToastContainer />
			<Breadcrumbs aria-label="breadcrumb">
				<Link underline="hover" color="inherit" href="/seasons">
					Seasons
				</Link>
				<Link underline="hover" color="inherit" href={`/seasons/${id}/edit`}>
					{season.title}
				</Link>
				<Typography color="text.primary">
					{language.isEnglish ? translations.terms.eng : translations.terms.afr}
				</Typography>
			</Breadcrumbs>
			<Form onSubmit={submit}>
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<Typography variant="h3" component="h2">
							{language.isEnglish
								? translations.thSeason.eng
								: translations.thSeason.afr}{' '}
							{language.isEnglish
								? translations.terms.eng
								: translations.terms.afr}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Box
							sx={{
								marginBottom: '1.5em',
								float: 'right',
							}}
						>
							<SeasonOptions id={id} />

							<Button variant="contained" color="success" type="submit">
								<SaveIcon fontSize="small" className="me-2" />
								Save Details
							</Button>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h2" component="h3">
							English
						</Typography>
						<ReactQuill theme="snow" value={value} onChange={setValue} />
					</Grid>
					<Grid item xs={12} className="mt-4">
						<Typography variant="h2" component="h3">
							Afrikaans
						</Typography>
						<ReactQuill theme="snow" value={valueAfr} onChange={setValueAfr} />
					</Grid>
				</Grid>
			</Form>
		</AdminLayout>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(SeasonEdit);
