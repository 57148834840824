import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import React, { Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import Languages from '../../../data/languages';
import { ILanguage } from '../../../models/language.model';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../../redux/props/language.props';

type IProps = {
	language: ILanguage;
	setLanguage: (language: string) => void;
	mobile?: boolean;
};

const AppBarLanguage: React.FC<IProps> = ({
	language,
	setLanguage,
	mobile = false,
}) => {
	const changeLanguage =
		(newLanguage: string) => (event: React.MouseEvent<HTMLElement>) => {
			event.preventDefault();
			setLanguage(newLanguage);
			localStorage.setItem('language', newLanguage);
		};

	const breadbrumbs = (): ReactNode => {
		return Languages.map((lang) => {
			return language.active === lang ? (
				<Typography
					key={`language-${lang}`}
					fontWeight="800"
					color="var(--grey-900)"
				>
					{lang}
				</Typography>
			) : (
				<Link
					key={`language-${lang}`}
					underline="hover"
					href="#"
					onClick={changeLanguage(lang)}
				>
					{lang}
				</Link>
			);
		});
	};

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
			<span
				className="icon icon-language"
				style={{ color: 'var(--grey-800)' }}
			></span>
			<Breadcrumbs aria-label="breadcrumb" className="me-4 mb-0">
				{breadbrumbs()}
			</Breadcrumbs>
		</Box>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(AppBarLanguage);
