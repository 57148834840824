import React from 'react';
import AdminLayout from '../../components/layouts/Admin';
import { User } from '../../models/user.model';
import { connect } from 'react-redux';
import {
	mapDispatchToProps,
	mapStateToProps,
} from '../../redux/props/user.props';
import {
	Avatar,
	Button,
	Chip,
	createTheme,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
} from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import TodayIcon from '@mui/icons-material/Today';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { themeOptions } from '../../styles/theme';
import EditIcon from '@mui/icons-material/Edit';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PublicIcon from '@mui/icons-material/Public';

interface IProps {
	user: User;
	setUser: (user: User) => void;
}

const theme = createTheme(themeOptions);

const useStyles = makeStyles({
	header: {
		display: 'flex',
		width: 'calc(100%)',
		alignItems: 'center',
		'& > *': {
			margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
		},
		marginBottom: theme.spacing(2),
	},
	actionGroup: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: 0,
		marginLeft: 'auto',
	},
	summaryCards: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	tripCard: {
		margin: theme.spacing(1),
		padding: theme.spacing(2),
	},
});

const ProfileView: React.FC<IProps> = ({ user, setUser }) => {
	const classes = useStyles();
	let type_icon = <ManageAccountsIcon />;

	if (user.entry_type === 'Student') {
		type_icon = <SchoolIcon />;
	}

	if (user.entry_type === 'Company') {
		type_icon = <PersonIcon />;
	}

	return (
		<AdminLayout>
			<div className={classes.header}>
				<Typography variant={'h5'} className="me-3">
					{`${user.first_name} ${user.last_name}`}
				</Typography>
				<Chip
					variant={'outlined'}
					icon={type_icon}
					label={user.entry_type}
					className="me-3"
				/>
				{/* <Rating name="read-only" value={4.3} readOnly /> USE LATER (ON ENTRY PAGE) */}
				<div className={classes.actionGroup}>
					<Button
						variant="contained"
						component={Link}
						to="/profile/edit"
						startIcon={<EditIcon />}
					>
						Update My Profile
					</Button>
				</div>
			</div>
			{/* <div className={classes.summaryCards}>
        <SummaryCard title="Last 30 Days" component={<RevenueLine />} />
        <SummaryCard title="By Vehicle" component={<VehiclePie />} />
      </div> */}
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
						<ListItem className="ps-0">
							<ListItemAvatar>
								<Avatar>
									<EmailIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="Email" secondary={user.email} />
						</ListItem>
						<ListItem className="ps-0">
							<ListItemAvatar>
								<Avatar>
									<PublicIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="Based in" secondary={user.based_in} />
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={4}>
					<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
						<ListItem className="ps-0">
							<ListItemAvatar>
								<Avatar>
									<InterpreterModeIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="Language" secondary={user.language} />
						</ListItem>
						{user.entry_type === 'Student' && (
							<ListItem className="ps-0">
								<ListItemAvatar>
									<Avatar>
										<BadgeIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="Student number"
									secondary={user.student_number}
								/>
							</ListItem>
						)}
						{user.entry_type === 'Company' && (
							<ListItem className="ps-0">
								<ListItemAvatar>
									<Avatar>
										<BusinessCenterIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Company" secondary={user.company} />
							</ListItem>
						)}
					</List>
				</Grid>
				<Grid item xs={4}>
					<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
						<ListItem className="ps-0">
							<ListItemAvatar>
								<Avatar>
									<CalendarMonthIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Registered on"
								secondary={new Date(user.registered_date).toLocaleString()}
							/>
						</ListItem>
						<ListItem className="ps-0">
							<ListItemAvatar>
								<Avatar>
									<TodayIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Last logged in on"
								secondary={new Date(user.logged_in_date).toLocaleString()}
							/>
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</AdminLayout>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
