import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { iRows } from '../EntriesPerSeason';
import { RenderEntryStatus } from './Status';
import { RenderReviewersButtons } from './Buttons';
import { useState } from 'react';
import { TabPanelHorisontal } from '../../../functions/Tabs';
import { DataTableSizeOptions } from '../../../functions/DataTable';

export const EntriesTable: React.FC<{
	rows: iRows;
	value: number;
	index: number;
	reviewerId?: number;
}> = ({ rows, value, index, reviewerId }) => {
	const columns: GridColDef[] = [
		{
			disableColumnMenu: true,
			field: 'reviewer_id',
			headerName: '',
		},
		{
			disableColumnMenu: true,
			field: 'reviewed',
			headerName: 'Status',
			renderCell: RenderEntryStatus,
			filterable: false,
			sortable: false,
			minWidth: 110,
		},
		{
			disableColumnMenu: true,
			field: 'entry_title',
			headerName: 'Title',
			filterable: false,
			sortable: false,
			flex: 1,
		},
		{
			disableColumnMenu: true,
			field: 'actions',
			headerName: '',
			renderCell: RenderReviewersButtons,
			filterable: false,
			sortable: false,
			width: 130,
		},
	];

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 5,
		page: 0,
	});

	return (
		<TabPanelHorisontal value={value} index={index}>
			<DataGrid
				autoHeight
				rows={rows}
				columns={columns}
				columnVisibilityModel={{
					reviewer_id: false,
				}}
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				pageSizeOptions={DataTableSizeOptions(rows)}
				disableColumnFilter
				disableRowSelectionOnClick
				disableVirtualization
			/>
		</TabPanelHorisontal>
	);
};
