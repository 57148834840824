import React, { useEffect } from 'react';
import AdminLayout from '../../components/layouts/Admin';
import { connect } from 'react-redux';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { useParams } from 'react-router-dom';
import ReviewerBreadcrumbs from './components/ReviewerBreadcrumbs';
import { ToastContainer } from 'react-toastify';
import { LanguageProps, URLParams } from '../../models/shared.model';

const AssignEntries: React.FC<LanguageProps> = ({ language }) => {
  let { id } = useParams<URLParams | any>();
  
  useEffect(() => {
    async function fetchData() {
		}
		fetchData();
  }, [id]);

	return (
		<AdminLayout>
			<ToastContainer />
			<ReviewerBreadcrumbs id={Number(id)} />
      Coming soon...
		</AdminLayout>
	);
}

export default connect(mapLanguageStateToProps)(AssignEntries);