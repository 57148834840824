import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { mapLanguageStateToProps } from "../../redux/props/language.props";
import axios from "axios";
import { IReviews } from "../../models/reviews.model";
import Loading from "../../components/Loading";
import { Table } from "react-bootstrap";
import { Typography } from "@mui/material";
import HeadingFour from "../../components/Typography/HeadingFour";
import { useParams } from "react-router-dom";

interface IProps {}

interface URLParams {
  id: number;
}

const ReviewPrint: React.FC<IProps> = () => {
  let { id } = useParams<URLParams | any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [reviews, setReviews] = useState<IReviews[]>([]);
  
  useEffect(() => {
    async function fetchData() {
      setLoading(true);

			await axios.get(`reviews/${id}`).then(reviews => {
        setReviews(reviews.data)
				setLoading(false);
			});
		}
		fetchData();
  }, []);

  return (
    <>
      {loading &&
        <Typography>Please wait while we fetch entries and their grades</Typography>
      }

      {!loading && reviews.length > 0 && 
        <Table bordered cellPadding={10} cellSpacing={0}>
          <thead>
            <tr>
              <td>Concept</td>
              <td>Reviewer</td>
              <td>Total&nbsp;score</td>
              <td>Overall&nbsp;%</td>
            </tr>
          </thead>
          <tbody>
            {reviews.map((review, index) => {
              return (
                <React.Fragment key={`review${index}`}>
                  <tr key={index}>
                    <td valign="top">
                      <table>
                        <tr>
                          <td valign="top" width={30}>
                            <strong>{index + 1}</strong>
                          </td>
                          <td valign="top">
                            <HeadingFour>
                              {review.concept_name}
                            </HeadingFour>
                            {review.concept_details.map((concept, con_id) => {
                              return (
                                <Typography key={`concept${con_id}`} gutterBottom>
                                  <strong>{concept.question}:</strong><br />
                                  {concept.answer}
                                </Typography>
                              )
                            })}
                            {/* add concept details here */}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td valign="top" style={{ padding: 0 }}>
                      <Table bordered cellPadding={10} cellSpacing={0}>
                        <thead>
                          <tr>
                            <td>Name</td>
                            <td>Mark</td>
                            <td>Comments</td>
                          </tr>
                        </thead>
                        <tbody>
                          {review.grades.length > 0 && review.grades.map((reviewer, rev_id) => 
                            <tr key={`rev${rev_id}`}>
                              <td>{reviewer.reviewer}</td>
                              <td>{reviewer.total}%</td>
                              <td>{reviewer.feedback}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </td>
                    <td valign="top">
                      {review.total_score}
                    </td>
                    <td valign="top">
                      {review.total_average.toFixed(0)}%
                    </td>
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </Table>
      }
    </>
  );
}

export default connect(mapLanguageStateToProps)(ReviewPrint);