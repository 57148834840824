import {
	Alert,
	AlertTitle,
	Button,
	Checkbox,
	DialogContentText,
	FormLabel,
} from '@mui/material';
import axios from 'axios';
import React, { FC, SyntheticEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { mapStateToProps } from '../../redux/props/user.props';
import { connect } from 'react-redux';
import { User } from '../../models/user.model';
import { isStudent } from '../../utils/shared';

interface IProps {
	toggleNextStep: (step: number, transactionId: number, done: boolean) => void;
	entryId: number;
	user: User;
}

const Step1Confirmation: FC<IProps> = ({ toggleNextStep, entryId, user }) => {
	const [checked, setChecked] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const onNextClick = async (e: SyntheticEvent) => {
		e.preventDefault();
		setLoading(true);

		await axios.put(`entry/${entryId}/publish`);

		if (!isStudent(user.entry_type)) {
			const response = await axios.post(`entry/${entryId}/start`, {
				agreed_to_terms: checked,
				user_id: Number(user.id),
			});

			if (response.data) {
				toggleNextStep(1, response.data.id, false);
				setLoading(false);
			}
		}

		if (isStudent(user.entry_type)) {
			toggleNextStep(1, 0, true);
		}
	};

	return (
		<>
			<Alert severity="error" sx={{ marginBottom: '10px' }}>
				<AlertTitle>Please note!</AlertTitle>
				Once your payment has been made, you won't be able to make changes to
				your entry again.
			</Alert>
			<DialogContentText>
				By submitting your entry you agree to the terms and conditions located{' '}
				<Link className="text-link" to="/terms">
					Here <span className="icon icon-small icon-external"></span>
				</Link>
			</DialogContentText>
			<FormLabel className="cursor-pointer">
				<Checkbox checked={checked} onChange={() => setChecked(!checked)} /> I
				agree
			</FormLabel>
			<Button
				onClick={onNextClick}
				variant="contained"
				fullWidth
				sx={{ marginTop: 'var(--px)' }}
				disabled={!checked || loading}
			>
				{!loading && !isStudent(user.entry_type) && <>Next</>}
				{!loading && isStudent(user.entry_type) && <>Confirm and Publish</>}
				{loading && <>....</>}
			</Button>
		</>
	);
};

export default connect(mapStateToProps)(Step1Confirmation);
