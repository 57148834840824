import { Box, Tabs, TabsProps } from '@mui/material';
import React, { ReactNode } from 'react';

interface IProps extends TabsProps {
	active: number;
	children: ReactNode;
}

const HorizontalTabs: React.FC<IProps> = ({ active, children, ...props }) => {
	return (
		<Tabs
			orientation="horizontal"
			value={active}
			variant="scrollable"
			scrollButtons="auto"
			{...props}
		>
			{children}
		</Tabs>
	);
};

export default HorizontalTabs;
