import { ILanguageOptions } from './translations';

export const capacities: ILanguageOptions[] = [
	{
		eng: 'Not sure yet',
		afr: 'Nognie seker nie',
	},
	{
		eng: 'Writer',
		afr: 'Skrywer',
	},
	{
		eng: 'Director',
		afr: 'Reggiseur',
	},
	{
		eng: 'Producer',
		afr: 'Vervaardiger',
	},
	{
		eng: 'Cinematographer',
		afr: 'Kinematograaf',
	},
	{
		eng: 'Other',
		afr: 'Ander',
	},
];

export const translateCapacity = (english: string): string => {
	let genre_afrikaans = '';

	capacities.forEach((item: ILanguageOptions) => {
		if (english == item.eng) {
			genre_afrikaans = item.afr;
		}
	});

	return genre_afrikaans;
};
