import { Box } from '@mui/material';

interface IProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanelVertical: React.FC<IProps> = ({ children, value, index }) => {
  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ 
            p: 3, 
            paddingTop: 2,
            paddingRight: 0
          }}
        >
          {children}
        </Box>
      )}
    </span>
  );
}

export default TabPanelVertical;