import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import AdminLayout from '../../components/layouts/Admin';
import RejectionEmail from './RejectionEmail';
import { tabProps } from '../../functions/Tabs';
import System from './System';
import TabPanelVertical from '../../components/TabPanelVertical';
import { TabContent } from '../../models/tab.model';
import AcceptanceEmail from './AcceptanceEmail';

type IProps = {};

const Settings: React.FC<IProps> = () => {
	const id = 1;
	const [activeTab, setActiveTab] = useState<number>(0);

	const tabbedContent: TabContent[] = [
		{
			title: 'System',
			content: <System id={id} />,
		},
		// {
		//   title: "Rejection Email",
		//   content: <RejectionEmail id={id} />,
		// },
		// {
		//   title: "Acceptance Email",
		//   content: <AcceptanceEmail id={id} />,
		// },
		// {
		//   title: "Email Template",
		//   content: <Typography>Coming soon...</Typography>,
		// },
	];

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	return (
		<AdminLayout>
			<Typography variant="h3" component="h2">
				Settings
			</Typography>

			<Box
				sx={{
					flexGrow: 1,
					bgcolor: 'background.paper',
					display: 'flex',
				}}
			>
				<Tabs
					orientation="vertical"
					variant="scrollable"
					value={activeTab}
					onChange={handleTabChange}
					sx={{
						borderRight: 1,
						borderColor: 'divider',
					}}
				>
					{tabbedContent.map((tab: TabContent, index: number) => {
						return (
							<Tab
								sx={{ alignItems: 'flex-end' }}
								key={index}
								label={tab.title}
								{...tabProps(index)}
							/>
						);
					})}
				</Tabs>
				<Box
					sx={{
						flexGrow: 1,
					}}
				>
					{tabbedContent.map((tab: TabContent, index: number) => {
						return (
							<TabPanelVertical key={index} value={activeTab} index={index}>
								{tab.content}
							</TabPanelVertical>
						);
					})}
				</Box>
			</Box>
		</AdminLayout>
	);
};

export default Settings;
