import { ReactFragment, ReactNode } from 'react';

export const AddIcon = () => {
	return (
		<span
			className="icon icon-add"
			style={{ marginLeft: '-5px', marginRight: '5px' }}
		></span>
	);
};
