import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { ITransactOutstandingTableData } from '../../models/transactions.model';
import { connect } from 'react-redux';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { ILanguage } from '../../models/language.model';
import { RenderViewEntryButton } from './components/ViewEntryButton';
import { formatDateTime } from '../../functions/Global';
import { DataTableSizeOptions } from '../../functions/DataTable';
import DataTable from '../../data/data-table';
import { IFieldAnswerV2 } from '../../models/form.model';

interface TableRow {
	id: number;
	title: string;
	season: string;
	user_name: string;
	user_email: string;
	student_number: string;
	actions: any;
}

interface IProps {
	language: ILanguage;
	id?: number | string | null;
}

const PaymentsOutstanding: React.FC<IProps> = ({ language, id }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [rows, setRows] = useState<TableRow[]>([]);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			await axios.get(`transactions/outstanding`).then((payments) => {
				const data: TableRow[] = payments.data.map(
					(item: ITransactOutstandingTableData) => {
						const entry_type =
							item.user.student_number ||
							item.user.student_school ||
							item.user.entry_type == 'student'
								? 'Student'
								: item.user.entry_type;

						return {
							id: item.id,
							title: item.title,
							last_updated: formatDateTime(new Date(item.updated_date)),
							user_name: `${item.user.first_name} ${item.user.last_name}`,
							student_number: entry_type ? entry_type : '—',
							user_email: item.user.email,
							season: language.isEnglish
								? item.season.title
								: item.season.title_afr,
							actions: item.id,
						};
					}
				);

				setRows(data);
				setLoading(false);
			});
		}
		fetchData();
	}, [id]);

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			width: 25,
		},
		{
			field: 'user_name',
			headerName: 'Name',
			flex: 1,
		},
		{
			field: 'title',
			headerName: 'Title',
			flex: 1,
		},
		{
			field: 'user_email',
			headerName: 'Email',
			flex: 1,
		},
		{
			field: 'season',
			headerName: 'Season',
			flex: 1,
		},
		{
			field: 'student_number',
			headerName: 'Student Number',
			flex: 1,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			renderCell: RenderViewEntryButton,
		},
	];

	return (
		<DataGrid
			autoHeight
			rows={rows}
			columns={columns}
			initialState={{ pagination: DataTable.pagination }}
			pageSizeOptions={DataTableSizeOptions(rows)}
			slots={{
				toolbar: GridToolbar,
			}}
			componentsProps={{
				toolbar: {
					showQuickFilter: true,
					quickFilterProps: { debounceMs: 500 },
				},
			}}
		/>
	);
};

export default connect(mapLanguageStateToProps)(PaymentsOutstanding);
