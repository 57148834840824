import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { IMenu } from '../../../models/menu.model';
import { connect } from 'react-redux';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../../redux/props/language.props';
import { ILanguage } from '../../../models/language.model';
import { User } from '../../../models/user.model';

// KEEP
// import ListItemIcon from '@mui/material/ListItemIcon';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import PeopleIcon from '@mui/icons-material/People';
// import { AddModerator } from '@mui/icons-material';

type IProps = {
	language: ILanguage;
	user: User;
};

const NavItems: React.FC<IProps> = ({ language, user }) => {
	const [navItems, setNavItems] = React.useState<[]>([]);
	const dashUrl = '/dashboard';

	React.useEffect(() => {
		async function getData() {
			const { data } = await axios.get('menu');
			setNavItems(data);
		}
		getData();
	}, []);

	return (
		<>
			{user.role.id !== 5 && (
				<ListItemButton
					sx={{ marginBottom: '4px' }}
					to={dashUrl}
					component={Link}
					selected={dashUrl === window.location.pathname}
				>
					<span className="icon icon-dashboard"></span>
					<ListItemText
						primary={user.role.name == 'User' ? 'My Entries' : 'Dashboard'}
					/>
				</ListItemButton>
			)}

			{navItems.map((item: IMenu) => {
				if (item.show_in_menu) {
					return (
						<ListItemButton
							sx={{ marginBottom: '4px' }}
							key={item.id}
							to={item.url}
							component={Link}
							selected={item.url === window.location.pathname}
						>
							{item.icon && <span className={`icon icon-${item.icon}`}></span>}
							<ListItemText
								primary={language.isEnglish ? item.name : item.name_afr}
							/>
						</ListItemButton>
					);
				}

				return '';
			})}
		</>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(NavItems);
