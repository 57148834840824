import React, { useEffect, useState } from 'react';
import AdminLayout from '../../components/layouts/Admin';
import ReviewerOptionsMenu from './components/ReviewerOptionsMenu';
import { connect } from 'react-redux';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import {
	Alert,
	Button,
	Chip,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { IReviewer, IUser } from '../../models/user.model';
import axios from 'axios';
import { ReactSortable } from 'react-sortablejs';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonTooltip from '../../components/ButtonTooltip';
import { useParams } from 'react-router-dom';
import ReviewerBreadcrumbs from './components/ReviewerBreadcrumbs';
import SaveIcon from '@mui/icons-material/Save';
import { ReviewerGroup } from '../../models/reviewers.model';
import { ToastrSettings } from '../../data/toastr';
import { ToastContainer, toast } from 'react-toastify';
import { SortableWrapper } from '../../components/sortable/SortableWrapper';
import { LanguageProps, URLParams } from '../../models/shared.model';
import Loading from '../../components/Loading';
import AssignmentIcon from '@mui/icons-material/Assignment';
import _ from 'lodash';

const AssignToGroups: React.FC<LanguageProps> = ({ language }) => {
	let { id } = useParams<URLParams | any>();
	const [loading, setLoading] = useState<boolean>(false);
	const [assigningEntries, setAssigningEntries] = useState<boolean>(false);
	const [groups, setGroups] = useState<ReviewerGroup[]>([]);
	const [reviewers, setReviewers] = useState<IUser[]>([]);
	const [total_submissions, setTotalSubmissions] = useState<number>(0);
	const [submissions, setSubmissions] = useState<number[]>([]);

	const group = 'reviewers';
	const animation = 150;

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			await axios.get(`reviewers/unassigned/${id}`).then((users) => {
				setReviewers(users.data);

				axios.get(`reviewer-groups/${id}`).then((groups) => {
					setTotalSubmissions(groups.data.total_submissions);
					setGroups(groups.data.reviewer_groups);
					setSubmissions(groups.data.submissions);
					setLoading(false);
				});
			});
		}
		fetchData();
	}, [id]);

	useEffect(() => {}, [groups]);

	const isEntryAvailable = (entryId: number) => {
		const selectedEntries = groups.map((group) => group.submission);

		// TODO
	};

	const setGroup = (items: IReviewer[], groupIndex: number) => {
		groups[groupIndex].reviewers = items;
		setGroups([...groups]);
	};

	const setFrom = (from: number, groupIndex: number) => {
		groups[groupIndex].from = from;
		setGroups([...groups]);
	};

	const setTo = (to: number, groupIndex: number) => {
		groups[groupIndex].to = to;
		setGroups([...groups]);
	};

	const addGroup = () => {
		const newGroup = {
			from: 0,
			to: 0,
			reviewers: [],
		};
		setGroups([...groups, newGroup]);
	};

	const setEntry = (groupIndex: number, id: number) => {
		let newGroups = groups;
		newGroups[groupIndex] = {
			...newGroups[groupIndex],
			submission: id,
		};

		setGroups([...newGroups]);
	};

	const removeGroup = (groupIndex: number) => {
		if (groups.length === 1) {
			setGroups([]);
		} else {
			setGroups([...groups.filter((item, index) => groupIndex !== index)]);
		}
	};

	const saveGroups = async () => {
		await axios
			.put(`reviewer-groups/${id}`, {
				groups: JSON.stringify(groups),
			})
			.then((response) => {
				toast.success('Groups saved successfully!', ToastrSettings);
			});
	};

	const assignEntries = async () => {
		setAssigningEntries(true);

		await axios
			.put(`reviewer-entries/${id}`)
			.then((response) => {
				setAssigningEntries(false);
				toast.success(
					'Entries assigned to reviewers successfully!',
					ToastrSettings
				);
			})
			.catch((error) => {
				setAssigningEntries(false);
			});
	};

	// const remainingEntries = () => {
	// 	const entries = _.sortBy(submissions);

	// 	const filtered = submissions.map((entry) => {
	// 		return groups.filter((group) => group.submission !== entry);
	// 	});

	// 	console.log('entries', entries.length);
	// 	console.log('filtered', filtered.length);
	// };

	// remainingEntries();

	return (
		<AdminLayout maxWidth="lg">
			<ToastContainer />
			<ReviewerBreadcrumbs id={Number(id)} />
			<ReviewerOptionsMenu
				title={language.isEnglish ? 'Groups' : 'Groepe'}
				total_submissions={total_submissions}
				actions={
					<>
						{groups && groups.length > 0 && (
							<Button
								onClick={assignEntries}
								variant="outlined"
								color="secondary"
								size="small"
								disabled={assigningEntries}
								startIcon={<AssignmentIcon />}
								sx={{ marginRight: '1em' }}
							>
								Assign Entries
							</Button>
						)}
						<Button
							onClick={addGroup}
							variant="outlined"
							size="small"
							startIcon={<GroupAddIcon />}
							sx={{ marginRight: '1em' }}
						>
							Add Group
						</Button>
						<Button
							onClick={saveGroups}
							variant="contained"
							color="secondary"
							size="small"
							startIcon={<SaveIcon />}
							sx={{ marginRight: '1em' }}
						>
							Save
						</Button>
					</>
				}
			/>

			<Alert
				severity="success"
				sx={{
					marginTop: 2,
					background: '#fff',
					boxShadow: '0 1px 2px 0px rgba(0,0,0,0.2)',
				}}
			>
				Entry ID + FROM + TO are all optional fields. If you leave ENTRY ID
				empty then you must fill in FROM and TO. If you leave FROM and TO empty,
				then you must choose ENTRY ID.
			</Alert>

			{loading && <Loading />}

			{!loading && (
				<Grid container spacing={2} className="mt-1">
					<Grid item xs={12} md={9} lg={10}>
						{groups.length === 0 && (
							<Alert severity="warning">
								You have no groups available.&nbsp; Click on the&nbsp;
								<strong className="text-black me-1 ms-1 border border-dark p-1">
									<GroupAddIcon fontSize="small" /> Add Group
								</strong>
								&nbsp; button (top right corner).
							</Alert>
						)}
						{groups && (
							<Grid container spacing={2} rowSpacing={5}>
								{groups.map((items, groupIndex: number) => (
									<Grid key={groupIndex} item xs={12} md={4} lg={2}>
										<Typography variant="h5" marginBottom={1}>
											<Stack
												direction="row"
												spacing={1}
												alignItems="center"
												marginBottom={2}
											>
												{items.submission
													? items.submission
													: `#${groupIndex + 1}`}
												<ButtonTooltip text={`Remove group and its contents`}>
													<IconButton
														sx={{ marginLeft: 1 }}
														onClick={() => removeGroup(groupIndex)}
													>
														<DeleteIcon color="secondary" />
													</IconButton>
												</ButtonTooltip>
												<FormControl
													size="small"
													sx={{
														marginLeft: 'auto !important',
														minWidth: '100px',
													}}
												>
													<InputLabel id="demo-simple-select-label">
														Entry ID
													</InputLabel>
													<Select
														size="small"
														labelId="demo-simple-select-label"
														label="Entry ID"
														value={String(items.submission)}
														onChange={(e: SelectChangeEvent) =>
															setEntry(groupIndex, Number(e.target.value))
														}
													>
														<MenuItem value="">---</MenuItem>
														{_.sortBy(submissions).map((sub, i) => {
															return (
																<MenuItem
																	key={`group-${groupIndex}-entry-${i}`}
																	value={sub}
																>
																	{sub}
																</MenuItem>
															);
														})}
													</Select>
												</FormControl>
											</Stack>
											{!items.submission && (
												<Stack direction="row" spacing={1}>
													<TextField
														size="small"
														label="From"
														name="from"
														variant="outlined"
														defaultValue={items.from}
														onChange={(e) =>
															setFrom(Number(e.target.value), groupIndex)
														}
													/>
													<TextField
														size="small"
														label="To"
														name="to"
														variant="outlined"
														defaultValue={items.to}
														onChange={(e) =>
															setTo(Number(e.target.value), groupIndex)
														}
													/>
												</Stack>
											)}
										</Typography>
										<ReactSortable
											group={group}
											tag={SortableWrapper}
											list={items.reviewers}
											animation={animation}
											setList={(e) => setGroup(e, groupIndex)}
										>
											{items.reviewers &&
												items.reviewers.map((user, itemIndex: number) => {
													return (
														<Chip
															clickable={true}
															key={itemIndex}
															label={user.email}
														/>
													);
												})}
										</ReactSortable>
									</Grid>
								))}
							</Grid>
						)}
					</Grid>

					<Grid item xs={12} md={3} lg={2} className="unassigned-list">
						<Paper
							variant="outlined"
							sx={{ padding: 2, position: 'sticky', top: 0 }}
							elevation={4}
						>
							<Typography variant="h5" marginBottom={2}>
								Unassigned
							</Typography>
							<ReactSortable
								group={group}
								tag={SortableWrapper}
								animation={animation}
								list={reviewers}
								// setList={setReviewers}
								setList={() => {}}
							>
								{reviewers.length > 0 &&
									reviewers.map((user) => {
										return (
											<Chip
												key={user.id}
												clickable={true}
												label={user.email}
												color="primary"
											/>
										);
									})}
							</ReactSortable>
						</Paper>
					</Grid>
				</Grid>
			)}
		</AdminLayout>
	);
};

export default connect(mapLanguageStateToProps)(AssignToGroups);
