import React, { Fragment, useEffect, useState } from 'react';
import { ISeason, ISeasonFormField } from '../../models/seasons.model';
import { connect } from 'react-redux';
import axios from 'axios';
import { IFieldAnswer } from '../../models/form.model';
import { User } from '../../models/user.model';
import { mapStateToProps } from '../../redux/props/user.props';
import {
	Alert,
	Button,
	FormControl,
	InputLabel,
	NativeSelect,
	OutlinedInput,
} from '@mui/material';
import { ILanguageOptions } from '../../data/translations';
import { ILanguage } from '../../models/language.model';
import { Form } from 'react-bootstrap';
import { genres } from '../../data/genre';
import { Navigate } from 'react-router-dom';
import { capacities } from '../../data/capacity';

type IProps = {
	language: ILanguage;
	season?: ISeason | null;
	user: User;
};

const EntryForm: React.FC<IProps> = ({ season = null, language, user }) => {
	const [form, setForm] = useState<ISeasonFormField[]>([]);
	const [errorResponse, setErrorResponse] = useState<string>('');
	const [redirect, setRedirect] = useState<boolean>(false);

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get(`seasons/${season?.id}`, {
				params: {
					file: 'src/components/landing/EntryForm.tsx',
				},
			});
			setForm(data.form);
		}
		fetchData();
	}, [season]);

	const validateMaxLength = (text: string, maxWords = 300) => {
		const words = text.split(' ');
		return words.length <= 300 ? true : false;
	};

	const submitEntry = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const form_data = new FormData(event.currentTarget);

		let answers: IFieldAnswer[] = [];

		form_data.forEach((answer, field_id) => {
			answers.push({
				answer: String(answer),
				question: Number(field_id),
			});
		});

		await axios
			.post(`seasons/${season?.id}/apply`, {
				user: user.id,
				answers: answers,
			})
			.then((response) => {
				setRedirect(true);
			})
			.catch((error) => {
				const response = JSON.parse(error.request.response);
				setErrorResponse(response.message);
			});
	};

	if (redirect) {
		return <Navigate to={'/dashboard'} />;
	}

	return (
		<>
			{errorResponse !== '' && (
				<Alert severity="warning" className="mb-3">
					{errorResponse}
				</Alert>
			)}

			{season && (
				<>
					<Form onSubmit={submitEntry}>
						{form.map((item, index) => {
							return (
								<Fragment key={`entry-form-field-${index}`}>
									{item.type?.id === 5 && (
										<FormControl className="mt-3" fullWidth variant="standard">
											<InputLabel>
												{language.isEnglish ? item.label : item.label_afr}
											</InputLabel>
											<NativeSelect name={`${item.id}`}>
												{capacities.map((genre: ILanguageOptions, index) => {
													return (
														<option key={index} value={genre.eng}>
															{language.isEnglish ? genre.eng : genre.afr}
														</option>
													);
												})}
											</NativeSelect>
										</FormControl>
									)}

									{item.type?.id === 4 && (
										<FormControl className="mt-3" fullWidth variant="standard">
											<InputLabel>
												{language.isEnglish ? item.label : item.label_afr}
											</InputLabel>
											<NativeSelect name={`${item.id}`}>
												{genres.map((genre: ILanguageOptions, index) => {
													return (
														<option key={index} value={genre.eng}>
															{language.isEnglish ? genre.eng : genre.afr}
														</option>
													);
												})}
											</NativeSelect>
										</FormControl>
									)}

									{item.type?.id && item.type?.id < 4 && (
										<FormControl key={item.id} className="mt-3" fullWidth>
											<InputLabel>
												{language.isEnglish ? item.label : item.label_afr}
											</InputLabel>
											<OutlinedInput
												label={item.label}
												multiline
												fullWidth
												required={true}
												name={`${item.id}`}
												inputProps={{ maxLength: item.max_chars }}
												type={item.type?.id === 3 ? 'number' : 'text'}
												minRows={item.type?.id === 2 ? 5 : 1}
											/>
											{/* {String(validateMaxLength('text', item.max_chars))} */}
										</FormControl>
									)}
								</Fragment>
							);
						})}
						<Button
							className="mt-4"
							variant="contained"
							color="primary"
							type="submit"
							fullWidth
						>
							{language.isEnglish ? 'Create Entry' : 'Dien aansoek in'}
						</Button>
					</Form>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps)(EntryForm);
