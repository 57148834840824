import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import { ILanguage } from '../../models/language.model';
import {
	ISeason,
	ISeasonGroup,
	ISeasonsPerGroup,
} from '../../models/seasons.model';
import { connect } from 'react-redux';
import { AddIcon } from '../../utils/icons';
import DialogClose from '../DialogClose';
import EntryForm from './EntryForm';
import { User } from '../../models/user.model';
import { mapStateToProps } from '../../redux/props/user.props';
import { isStudent } from '../../utils/shared';
import { TEST_MODE } from '../..';
import translations from '../../data/translations';
import Loading from '../Loading';
import { Navigate } from 'react-router-dom';
import { theme } from '../../styles/theme';

interface IProps {
	openLoginModal?: () => void;
	language: ILanguage;
	gridView?: boolean;
	dashboard?: boolean;
	noAuthRequired?: boolean;
	user: User;
}

const ActiveSeasons: React.FC<IProps> = ({
	language,
	gridView = true,
	dashboard = false,
	openLoginModal,
	noAuthRequired = false,
	user,
}) => {
	const [seasons, setSeasons] = React.useState<ISeason[]>([]);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [redirect, setRedirect] = React.useState<boolean>(false);

	// Season terms and conditions modals
	const [season, setSeason] = React.useState<ISeason | null>(null);
	const [openTerms, setOpenTerms] = React.useState<boolean>(false);
	const [openEntry, setOpenEntry] = React.useState<boolean>(false);
	const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
	const [list, setList] = React.useState<ISeasonsPerGroup[]>([]);
	const [groups, setGroups] = React.useState<ISeasonGroup[]>([]);

	// Test mode
	// Don't show seasons in this mode
	const [show, setShow] = React.useState<boolean>(true);

	React.useEffect(() => {
		async function fetchData() {
			const getGroups = await axios.get('season/groups');

			if (getGroups && getGroups.data) {
				setGroups(getGroups.data);
			}

			const url =
				!noAuthRequired && isStudent(user.entry_type) && dashboard
					? 'seasons/active/student'
					: 'active/seasons';

			await axios.get(url).then((data: any) => {
				setLoading(false);
				setSeasons(data.data);

				const allData = groups.map((g) => {
					const count = data.data.filter((s: ISeason) => {
						const countPublishedEntries =
							s?.submissions &&
							s.submissions.filter((sub) => {
								return (
									sub.draft === false &&
									user &&
									sub.user &&
									user.id === sub.user.id
								);
							});

						return countPublishedEntries && countPublishedEntries.length > 0;
					});

					return {
						...g,
						seasons: data.data.filter((s: ISeason) => {
							return s.group && s.group?.id && s.group.id === g.id;
						}),
						totalApplications: count.length,
					};
				});

				setList(allData);
			});

			const verify = await axios.get('auth/verify');

			if (verify.data) {
				setLoggedIn(true);
			}
		}
		fetchData();

		if (TEST_MODE) {
			setShow(false);

			if (dashboard) {
				setShow(true);
			}
		}
	}, [user, loading]);

	const description = (desc: string): string => {
		return desc.split(/\s+/).slice(0, 20).join(' ') + '...';
	};

	if (redirect) {
		return <Navigate to="/entries/create" />;
	}

	return (
		<>
			{seasons.length === 0 && !loading && (
				<Alert severity="warning">
					<AlertTitle>
						{language.isEnglish
							? 'All seasons closed'
							: 'Alle seisoene gesluit'}
					</AlertTitle>
					{language.isEnglish
						? 'Entries are all currently closed. Please follow our social media channels where all information regarding the next competition season, will be announced.'
						: 'Inskrywings is tans almal gesluit. Volg asseblief ons sosiale media kanale waar alle inligting rakende die volgende kompetisie seisoen, aangekondig sal word.'}
				</Alert>
			)}

			{/* {loading && <Loading />} */}

			<Box
				display="flex"
				flexDirection="column"
				sx={{
					gap: {
						xs: 'var(--spacer-l)',
						md: 'var(--spacer-xxl)',
					},
				}}
			>
				{list &&
					list.length > 0 &&
					list.map((item, index) => {
						return (
							<Grid
								key={`group_${index}`}
								container
								spacing={2}
								className="card-grid"
							>
								{item.seasons.length > 0 && (
									<>
										<Grid item xs={12}>
											<Typography
												component="h1"
												sx={{
													font: 'var(--h1-bold)',
													[theme.breakpoints.down('md')]: {
														font: 'var(--h3)',
													},
													color: 'var(--primary-800)',
												}}
											>
												{language.isEnglish ? item.title : item.title_afr}
											</Typography>
										</Grid>
										{item.seasons.map((season: ISeason) => {
											return (
												<Grid
													key={season.id}
													item
													xs={12}
													md={gridView ? 6 : 12}
												>
													<Card sx={{ minWidth: 275 }}>
														<CardHeader
															avatar={
																<span
																	className={`icon icon-${
																		season.type === 'Shortfilms'
																			? 'camera'
																			: 'movie'
																	}`}
																></span>
															}
															className="season-title"
															title={
																language.isEnglish
																	? season.title
																	: season.title_afr
															}
														/>
														<CardContent>
															<Typography
																variant="bodyL"
																fontWeight="bold"
																component="div"
															>
																{language.isEnglish ? 'About ' : 'Oor '}
																{language.isEnglish
																	? season.title
																	: season.title_afr}
															</Typography>
															<Typography variant="bodyL">
																{language.isEnglish
																	? season.description
																		? description(season.description)
																		: ''
																	: season.description_afr
																	? description(season.description_afr)
																	: ''}
															</Typography>
														</CardContent>
														<CardActions>
															{loggedIn && (
																<Button
																	variant="contained"
																	size="small"
																	color="primary"
																	onClick={(e) => {
																		e.preventDefault();
																		if (dashboard) {
																			setSeason(season);
																			setOpenEntry(true);
																		}

																		if (!dashboard) {
																			setRedirect(true);
																		}
																	}}
																	disabled={
																		season.submissions &&
																		season.submissions.filter((s) => {
																			return (
																				s.draft === false &&
																				user &&
																				s.user &&
																				user.id === s.user.id
																			);
																		}).length >= 3
																			? true
																			: false
																	}
																>
																	{AddIcon()}
																	{language.isEnglish
																		? 'Enter now!'
																		: 'Skryf in!'}{' '}
																</Button>
															)}
															{!loggedIn && (
																<Button
																	variant="contained"
																	size="small"
																	color="primary"
																	onClick={openLoginModal}
																>
																	<span className="icon icon-readmore"></span>
																	{language.isEnglish
																		? 'Sign in to enter!'
																		: 'Teken aan om in te skryf!'}
																</Button>
															)}
															<Button
																variant="contained"
																color="secondary"
																onClick={(e) => {
																	e.preventDefault();
																	setSeason(season);
																	setOpenTerms(true);
																}}
															>
																{language.isEnglish
																	? translations.terms.eng
																	: translations.terms.afr}
															</Button>
														</CardActions>
													</Card>
												</Grid>
											);
										})}
									</>
								)}
							</Grid>
						);
					})}
			</Box>

			{/* Terms and conditions */}
			<Dialog
				fullWidth
				maxWidth="xl"
				open={openTerms}
				onClose={() => {
					setOpenTerms(false);
					setSeason(null);
				}}
			>
				<DialogTitle>
					{language.isEnglish ? translations.terms.eng : translations.terms.afr}
					<DialogClose
						onClick={(e) => {
							e.preventDefault();
							setOpenTerms(false);
						}}
					/>
				</DialogTitle>
				<DialogContent sx={{ color: 'var(--grey-800)' }}>
					{season && (
						<div
							className="mt-4"
							dangerouslySetInnerHTML={{
								__html: language.isEnglish
									? season.terms_and_conditions
									: season.terms_and_conditions_afr,
							}}
						></div>
					)}
				</DialogContent>
			</Dialog>

			{/* Entry form */}
			<Dialog
				fullWidth
				open={openEntry}
				onClose={() => {
					setOpenEntry(false);
					setSeason(null);
				}}
			>
				<DialogTitle>
					{season && language.isEnglish ? season.title : season?.title_afr}{' '}
					Entry
					<DialogClose
						onClick={(e) => {
							e.preventDefault();
							setOpenEntry(false);
						}}
					/>
				</DialogTitle>
				<DialogContent sx={{ color: 'var(--grey-800)' }} className="pt-2">
					<EntryForm language={language} season={season} />
				</DialogContent>
			</Dialog>
		</>
	);
};

export default connect(mapStateToProps)(ActiveSeasons);
