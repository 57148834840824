import { IRole, Role } from './role.model';

export interface IUser {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	contact_number?: string;
	based_in?: string;
	password?: string;
	password_confirm?: string;
	role?: IRole;
	student_number?: string;
	student_school?: string;
	company?: string;
	language?: string;
	entry_type?: string;
	registered_date: string;
	logged_in_date: string;
	updated_date?: string;
}

export interface IReviewer {
	id: number;
	first_name: string;
	last_name: string;
	email?: string;

	// Sortable JS
	chosen?: boolean;
	selected?: boolean;
}

export interface IUserMeta {
	last_page: number;
	page: number;
	total: number;
}

export class User {
	constructor(
		public id = 0,
		public first_name = '',
		public last_name = '',
		public email = '',
		public role = new Role(),
		public contact_number = '',
		public based_in = '',
		public student_number = '',
		public company = '',
		public language = '',
		public entry_type = '',
		public registered_date = '',
		public logged_in_date = '',
		public updated_date = ''
	) {}

	get name() {
		return this.first_name + ' ' + this.last_name;
	}
}
