import React, { useCallback, useState } from 'react';
import AuthLayout from '../../components/layouts/Auth';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { NavLink } from "react-router-dom";
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { connect } from 'react-redux';
import { ILanguage } from '../../models/language.model';
import translations from '../../data/translations';
import { Alert, Typography } from '@mui/material';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

interface IProps {
	language: ILanguage
}

const RequestResetPassword: React.FC<IProps> = ({ language }) => {
	const [errorResponse, setErrorResponse] = useState(null);
	const [success, setSuccess] = useState<boolean>(false);
	
	// Google reCAPTCHA
  const [token, setToken] = useState<string>('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);

	const onVerify = useCallback((token: string): void => {
    setToken(token);
  }, [token]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (token) {
			const data = new FormData(event.currentTarget);

			await axios.post('reset-password', {
				email: data.get('email')
			})
			.then(response => {
				setRefreshReCaptcha(r => !r);
        setSuccess(true);
			})
			.catch(error => {
				const response = JSON.parse(error.request.response);
				setErrorResponse(response.message);
			});
		}
	}

  let paragraph1, paragraph2;
  if (language.isEnglish) {
    paragraph1 = 'Reset your password by submitting your email address linked to your account.';
    paragraph2 = 'We will send you an email with a link to choose a new password.';
  } else {
    paragraph1 = 'Stel jou wagwoord terug deur jou e-posadres wat aan jou rekening gekoppel is, in te dien.';
    paragraph2 = `Ons sal vir jou 'n e-pos stuur met 'n skakel om 'n nuwe wagwoord te kies.`;
  }

	return (
		<AuthLayout title={language.isEnglish ? translations.resetPw.eng : translations.resetPw.afr}>
			<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
				<GoogleReCaptcha
					onVerify={onVerify}
					refreshReCaptcha={refreshReCaptcha}
				/>
        {success &&
					<Alert severity="success" className="mb-3">
						{language.isEnglish ? 'Password reset link sent successfully' : 'Wagwoordterugstelskakel is suksesvol gestuur'}
					</Alert>
				}
				{errorResponse !== null &&
					<Alert severity="warning" className="mb-3">
						{errorResponse}
					</Alert>
				}
        <Typography variant="body1" component="p">
          {paragraph1}<br />
          {paragraph2}
        </Typography>
				<TextField
					margin="normal"
					required
					fullWidth
					id="email"
					label={language.isEnglish ? translations.email.eng : translations.email.afr}
					name="email"
					autoComplete="email"
					autoFocus
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
				>
					{language.isEnglish ? translations.resetPw.eng : translations.resetPw.afr}
				</Button>
				<Grid container justifyContent="center">
          <Grid item>
            <Link component={NavLink} to="/sign-in" variant="body2">
              {language.isEnglish ? `Already have an account? ${translations.signIn.eng}` : `Reeds 'n rekening? ${translations.signIn.afr}`}
            </Link>
          </Grid>
        </Grid>
			</Box>
    </AuthLayout>
	);
}

export default connect(mapLanguageStateToProps)(RequestResetPassword);