export const DataTableSizeOptions = (rows: any[]) => {
	const total_rows = rows.length;
	let size_options = [];

	if (total_rows >= 25) size_options.push(25);
	if (total_rows >= 50) size_options.push(50);
	if (total_rows >= 100) size_options.push(100);
	if (total_rows >= 500) size_options.push(500);
	if (total_rows >= 1000) size_options.push(1000);

	return size_options;
};
