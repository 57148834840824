import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import * as React from 'react';
import bannerImage from '../../assets/Web-Banner.jpg';

interface IProps {}

const HeroBanner: React.FC<IProps> = () => {
	return (
		<>
			<MDBCarousel showControls showIndicators>
				<MDBCarouselItem
					className="w-100 d-block"
					itemId={1}
					src={bannerImage}
					alt="..."
				/>
			</MDBCarousel>
		</>
	);
};

export default HeroBanner;
