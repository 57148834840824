import { Box, Tab, Typography } from '@mui/material';
import React, { useState } from 'react';
import AdminLayout from '../../components/layouts/Admin';
import { useParams } from 'react-router-dom';
import { LanguageProps, URLParams } from '../../models/shared.model';
import { connect } from 'react-redux';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { TabPanelHorisontal, tabProps } from '../../functions/Tabs';
import PaymentsOutstanding from './PaymentsOutstanding';
import PaymentsPaid from './PaymentsPaid';
import HorizontalTabs from '../../components/HorizontalTabs';

const Payments: React.FC<LanguageProps> = ({ language }) => {
	let { id } = useParams<URLParams | any>();
	const [activeTab, setActiveTab] = useState<number>(0);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	const tabs = ['Paid', 'Outstanding'];

	return (
		<AdminLayout maxWidth="lg">
			<Typography variant="h3" component="h2">
				Payments
			</Typography>
			<Box sx={{ width: '100%' }}>
				<HorizontalTabs active={activeTab} onChange={handleTabChange}>
					{tabs.map((tab, index) => {
						return <Tab key={index} label={tab} {...tabProps(index)} />;
					})}
				</HorizontalTabs>
			</Box>
			<TabPanelHorisontal key={0} value={activeTab} index={0}>
				<PaymentsPaid id={id} />
			</TabPanelHorisontal>
			<TabPanelHorisontal key={1} value={activeTab} index={1}>
				<PaymentsOutstanding id={id} />
			</TabPanelHorisontal>
		</AdminLayout>
	);
};

export default connect(mapLanguageStateToProps)(Payments);
