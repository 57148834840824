import React from 'react';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import SubmissionsListView from '../submissions/ListView';
import { ILanguage } from '../../models/language.model';
import { connect } from 'react-redux';
import { User } from '../../models/user.model';

type IProps = {
	language: ILanguage;
	user: User;
};

const UserDashboard: React.FC<IProps> = ({ language, user }) => {
	return <SubmissionsListView user={user} latest={true} />;
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(UserDashboard);
