import { Box, Button, Chip, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { IUser } from '../../../models/user.model';
import { SortableWrapper } from '../../sortable/SortableWrapper';

interface IProps {
	id: number;
	seasonId: number;
}

const EntryReviewers: React.FC<IProps> = ({ id, seasonId }) => {
	const [assigned, setAssigned] = useState<IUser[]>([]);
	const [reviewers, setReviewers] = useState<IUser[]>([]);
	const [hasGroup, setHasGroup] = useState<boolean>(false);

	const group = 'reviewers';
	const animation = 150;
	let u: IUser[] = [];

	useEffect(() => {
		async function fetchData() {
			await axios
				.get(`submission-reviewers/${id}`)
				.then((data) => {
					if (data.data) {
						setHasGroup(true);
					}

					if (data && data?.data && data.data?.reviewers) {
						setAssigned(data.data.reviewers);
						u = data.data.reviewers;
					}
				})
				.catch((error) => {});

			// Get unassigned
			await axios
				.post('entry-reviewers/unassigned/', {
					users: u.map((item) => item.id),
				})
				.then((users) => {
					if (users.data.length > 0) {
						setReviewers(users.data);
					}
				})
				.catch((error) => {});
		}
		fetchData();
	}, [id]);

	const onSave = async () => {
		await axios
			.post(`submission-reviewers/${id}`, {
				reviewers: assigned,
				season_id: seasonId,
				has_group: hasGroup,
			})
			.then((response) => {})
			.catch((error) => {
				// do nothing
			});
	};

	return (
		<Grid container sx={{ marginTop: '12px' }} columnSpacing="16px">
			<Grid item xs={12} md={6}>
				<h6>Assigned Reviewers</h6>
				<Box className="reviewer-card">
					<ReactSortable
						group={group}
						tag={SortableWrapper}
						list={assigned}
						animation={animation}
						setList={setAssigned}
					>
						{assigned &&
							assigned.map((user, itemIndex: number) => {
								return (
									<Chip
										clickable={true}
										key={itemIndex}
										label={user.email}
										color="primary"
									/>
								);
							})}
					</ReactSortable>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<h6>Unassigned Reviewers</h6>
				<Box className="reviewer-card unassigned-reviewers">
					<ReactSortable
						group={group}
						tag={SortableWrapper}
						animation={animation}
						list={reviewers}
						setList={setReviewers}
					>
						{reviewers.length > 0 &&
							reviewers.map((user) => {
								return (
									<Chip
										key={user.id}
										clickable={true}
										label={user.email}
										color="primary"
									/>
								);
							})}
					</ReactSortable>
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}
			>
				<Button
					variant="contained"
					size="small"
					color="primary"
					onClick={onSave}
				>
					Save
				</Button>
			</Grid>
		</Grid>
	);
};

export default EntryReviewers;
