import React, { useEffect, useState } from 'react';
import AdminLayout from '../../components/layouts/Admin';
import axios from 'axios';
import { IUser } from '../../models/user.model';
import {
	Button,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import { RenderUserButtons } from './components/Buttons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

type IProps = {};

interface TableRow {
	id: number;
	name: string;
	email: string;
	phone_number: string;
	based_in: string;
	role: string;
	entry_type: string;
	actions: any;
}

const Users: React.FC<IProps> = () => {
	const [users, setUsers] = useState<IUser[]>([]);

	useEffect(() => {
		async function fetchData() {
			await axios.get(`users`).then((users) => {
				setUsers(users.data);
			});
		}
		fetchData();
	}, []);

	const deleteUser = (id: number) => async (event: any) => {
		if (window.confirm('Are you sure you want to delete this user?')) {
			await axios.delete(`users/${id}`);
			setUsers(users.filter((u: IUser) => u.id !== id));
		}
	};

	return (
		<AdminLayout maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<Typography variant="h3" component="h2">
						Users
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Box
						sx={{
							marginBottom: '1.5em',
							float: 'right',
						}}
					>
						<Button variant="contained" component={Link} to="/users/create">
							Create New User
						</Button>
					</Box>
				</Grid>
			</Grid>

			<TableContainer>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell component="th">#</TableCell>
							<TableCell component="th" align="left">
								Name
							</TableCell>
							<TableCell component="th" align="left">
								Email
							</TableCell>
							<TableCell component="th" align="left">
								Number
							</TableCell>
							<TableCell component="th" align="left">
								Based In
							</TableCell>
							<TableCell component="th" align="left">
								Role
							</TableCell>
							<TableCell component="th" align="left">
								Action
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map((user: IUser) => {
							return (
								<TableRow
									key={user.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" scope="row" width={70}>
										{user.id}
									</TableCell>
									<TableCell align="left">
										{`${user.first_name} ${user.last_name}`}
									</TableCell>
									<TableCell align="left">{user.email}</TableCell>
									<TableCell align="left">
										{user.contact_number ? user.contact_number : 'n/a'}
									</TableCell>
									<TableCell align="left">
										{user.based_in ? user.based_in : 'n/a'}
									</TableCell>
									<TableCell align="left">{user.role?.name}</TableCell>
									<TableCell width={130} align="left">
										<IconButton color="secondary" onClick={deleteUser(user.id)}>
											<DeleteIcon fontSize="small" />
										</IconButton>
										<IconButton
											color="secondary"
											component={Link}
											to={`/users/${user.id}/edit`}
										>
											<EditIcon fontSize="small" />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{/* <Paginator lastPage={lastPage} page={page} pageChanged={setPage} /> */}
		</AdminLayout>
	);
};

export default Users;
