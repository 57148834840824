import { Button, Typography } from '@mui/material';
import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import { IPermission } from '../../models/permission.model';

interface IProps {}

interface URLParams {
	id: number;
}

const RoleEdit: React.FC<IProps> = () => {
	const { id } = useParams<URLParams | any>();
	const [name, setRoleName] = useState<string>('');
	const [redirect, setRedirect] = useState<boolean>(false);
	const [not_customisable, setNotCustomisable] = useState<boolean>(false);
	const [permissions, setPermissions] = useState<IPermission[]>([]);
	const [selected, setSelected] = useState<number[]>([]);

	useEffect(() => {
		async function getData() {
			const permissions = await axios.get('permissions');
			setPermissions(permissions.data);

			if (id) {
				const { data } = await axios.get(`roles/${id}`);

				setRoleName(data.name);
				setNotCustomisable(data.not_customisable);
				setSelected(data.permissions.map((p: IPermission) => p.id));
			}
		}
		getData();
	}, [id]);

	const submit = async (e: SyntheticEvent) => {
		e.preventDefault();

		if (id) {
			await axios.put(`roles/${id}`, {
				name,
				permissions: selected,
			});
		} else {
			await axios.post('roles', {
				name,
				permissions: selected,
			});
		}

		setRedirect(true);
	};

	if (redirect) {
		return <Navigate to={'/roles'} />;
	}

	const check = (id: number) => {
		// First check if it already exists then remove it
		// Happens when unchecking a checked checkbox
		if (selected.some((s) => s === id)) {
			setSelected(selected.filter((s) => s !== id));
			return;
		}

		setSelected([...selected, id]);
	};

	return (
		<AdminLayout>
			<Typography variant="h3" component="h2">
				{!not_customisable && <>{id ? 'Update' : 'Create New'}&nbsp;</>}
				User Role
			</Typography>
			<Form onSubmit={submit}>
				<Form.Group className="mb-3" controlId="formRoleName">
					<Form.Label>Name</Form.Label>
					<Form.Control
						type="text"
						placeholder="Name"
						defaultValue={name}
						disabled={not_customisable ? true : false}
						onChange={(e) => setRoleName(e.target.value)}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="formRolePermissions">
					<Form.Label className="me-4">Permissions</Form.Label>
					<div className="p-3 border rounded">
						{permissions.map((p: IPermission) => {
							return (
								<Form.Check
									inline
									value={p.id}
									key={p.id}
									type="checkbox"
									checked={selected.some((s) => s === p.id)}
									id={`permission_${p.id}`}
									label={p.name}
									// disabled={not_customisable ? true : false}
									onChange={() => check(p.id)}
								/>
							);
						})}
					</div>
				</Form.Group>
				{!not_customisable && (
					<Button
						className="mt-3"
						variant="contained"
						color="success"
						type="submit"
					>
						{id ? 'Update' : 'Create New'} User Role
					</Button>
				)}
			</Form>
		</AdminLayout>
	);
};

export default RoleEdit;
