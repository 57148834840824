import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { SettingsModel } from "../../models/settings.model";
import Alert from "@mui/material/Alert";
import SaveIcon from "@mui/icons-material/Save";
import HeadingThree from "../../components/Typography/HeadingThree";

type IProps = {
  id: number;
};

const System: React.FC<IProps> = ({ id }) => {
  const [from_email, setFromEmail] = useState<string>("");
  const [reply_to_email, setReplyToEmail] = useState<string>("");
  const [landing_page_title, setLandingPageTitle] = useState<string>("");
  const [landing_page_title_afr, setLandingPageTitleAfr] = useState<string>("");
  const [updatedSuccessfully, setUpdatedSuccessfully] =
    useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`settings/`);
      const settings: SettingsModel = data;

      setFromEmail(settings.from_email);
      setReplyToEmail(settings.reply_to_email);
      setLandingPageTitle(settings.landing_page_title);
      setLandingPageTitleAfr(settings.landing_page_title_afr);
    }
    fetchData();
  }, [id]);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    await axios
      .put("settings", {
        from_email,
        reply_to_email,
        landing_page_title,
        landing_page_title_afr,
      })
      .then((response) => {
        setUpdatedSuccessfully(true);
      })
      .catch((error) => {
        setUpdatedSuccessfully(false);
      });

    setTimeout(() => {
      setUpdatedSuccessfully(false);
    }, 3000);
  };

  return (
    <>
      {updatedSuccessfully && (
        <Alert className="mb-4" severity="success">
          Settings updated successfully
        </Alert>
      )}
      <Form onSubmit={submit}>
        <HeadingThree className="mb-4">Email</HeadingThree>
        <Grid container spacing={3} className="mb-2">
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-amount">
                From Email
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                label="From Email"
                value={from_email}
                onChange={(e) => setFromEmail(e.target.value)}
                required={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-amount">
                Reply to Email
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                label="Reply to Email"
                value={reply_to_email}
                onChange={(e) => setReplyToEmail(e.target.value)}
                required={true}
              />
            </FormControl>
          </Grid>
        </Grid>
        <HeadingThree className="mt-4">Landing Page</HeadingThree>
        <FormControl fullWidth className="mb-3">
          <InputLabel>Page Title English</InputLabel>
          <OutlinedInput
            label="Page Title English"
            value={landing_page_title}
            onChange={(e) => setLandingPageTitle(e.target.value)}
          />
        </FormControl>
        <FormControl fullWidth className="mb-2">
          <InputLabel>Page Title Afrikaans</InputLabel>
          <OutlinedInput
            label="Page Title Afrikaans"
            value={landing_page_title_afr}
            onChange={(e) => setLandingPageTitleAfr(e.target.value)}
          />
        </FormControl>
        {/* 
        <HeadingThree className="mt-4">
          Branding
        </HeadingThree>
        <Typography>
          Coming soon...
        </Typography>
          <Typography variant="h3">
            Branding
          </Typography>

          1) Upload field for: logo, hero banner(s)
          2) If a logo and banner image exists,
            delete them first then upload the new ones.
        */}
        <Grid item xs={12}>
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" color="success" type="submit">
              <SaveIcon fontSize="small" className="me-2" />
              Save Details
            </Button>
          </Box>
        </Grid>
      </Form>
    </>
  );
};

export default System;
