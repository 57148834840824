import {
	Button,
	Paper,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../redux/props/language.props';
import { Container } from '@mui/system';
import React from 'react';
import Footer from '../components/landing/Footer';
import DefaultLayout from '../components/layouts/Default';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import { LanguageProps } from '../models/shared.model';
import { Link } from 'react-router-dom';

const Instructions: React.FC<LanguageProps> = ({ language }) => {
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set<number>());

	const steps = [
		{
			label: 'Select season',
			content: (
				<>
					Navigate to the&nbsp;
					<Link to={'/entries/create'} target="_blank">
						List of seasons
					</Link>{' '}
					page (you need to be logged in for this.)
					<br />
					On this page you will find a list of active competitions (seasons).
					<br />
					Click on <strong>"Enter now!"</strong> inside the block of the
					competition you want to enter.
				</>
			),
		},
		{
			label: 'Enter details',
			content: (
				<>
					On this page you will see a list of questions.
					<br />
					Answer all the questions.
				</>
			),
		},
		{
			label: 'Submit application',
			content: (
				<>
					At the bottom of the page is a gold button that reads "Submit
					Application". <strong>Click on it.</strong>
					<br />
					After that you will automatically be taken to your dashboard where you
					can see all your entries.
				</>
			),
		},
		{
			label: 'Make payment',
			content: (
				<>
					On your dashboard you will see a heading{' '}
					<strong>"My Latest Entries"</strong>.
					<br />
					Underneath this heading is a table that contains all your entries.
					<br />
					<br />
					In the last column (the action column),{' '}
					<strong>click on the arrow icon</strong>.
					<br />
					This is the 2nd icon in the column (there are only 2).
					<br />
					<br />
					This will take you to a page with more options.
					<br />
					Click on the <strong>"options"</strong> button (top right corner) to
					view your options.
					<br />
					In this menu that pops up, click on the
					<strong>"pay"</strong> button.
					<br />
					<br />
					You will be taken to a page with heading{' '}
					<strong>"Finalise Entry"</strong>.<br />
					Follow the steps provided to make a payment.
				</>
			),
		},
	];

	const isStepSkipped = (step: number) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<DefaultLayout>
			<Container component="main" sx={{ mt: 9, mb: 12 }}>
				<Typography variant="h3" component="h3" className="mb-1">
					{language.isEnglish ? 'Instructions' : 'Instruksies'}
				</Typography>
				<Box sx={{ width: '100%' }}>
					<Stepper activeStep={activeStep} orientation="vertical">
						{steps.map((step, index) => {
							const stepProps: { completed?: boolean } = {};
							const labelProps: {
								optional?: React.ReactNode;
							} = {};
							if (isStepSkipped(index)) {
								stepProps.completed = false;
							}
							return (
								<Step key={step.label} {...stepProps}>
									<StepLabel {...labelProps}>{step.label}</StepLabel>
									<StepContent>
										<Typography marginBottom={3}>{step.content}</Typography>
										<Box sx={{ mb: 2 }}>
											<div
												style={{
													display: 'Flex',
													// justifyContent: "space-between",
												}}
											>
												<Button
													size="small"
													variant="outlined"
													color="secondary"
													disabled={index === 0}
													onClick={handleBack}
													sx={{ mt: 1, mr: 1 }}
												>
													Back
												</Button>
												<Button
													size="small"
													disableElevation
													variant="contained"
													onClick={handleNext}
													sx={{ mt: 1, mr: 1 }}
												>
													{index === steps.length - 1 ? 'Finish' : 'Next'}
												</Button>
											</div>
										</Box>
									</StepContent>
								</Step>
							);
						})}
					</Stepper>
					{activeStep === steps.length && (
						<Paper square sx={{ p: 3 }}>
							<Typography marginBottom={4}>
								That is it! You have now successfully created your first entry.
							</Typography>
							<Button
								variant="contained"
								color="secondary"
								disableElevation
								component={Link}
								to={'/entries/create'}
								target="_blank"
							>
								Create new entry
							</Button>
						</Paper>
					)}
				</Box>
			</Container>
			<Footer />
		</DefaultLayout>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(Instructions);
