import axios from 'axios';
import React from 'react';
import AdminLayout from '../components/layouts/Admin';
import ReviewerDashboard from '../components/dashboards/ReviewerDashboard';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { ILanguage } from '../models/language.model';
import { mapLanguageStateToProps } from '../redux/props/language.props';

type IProps = {
	language: ILanguage;
};

const ReviewerAssignedEntries: React.FC<IProps> = ({ language }) => {
	const breadcrumbs: string[] = language.isEnglish
		? ['Assigned Entries']
		: ['Toegewysde inskrywings'];

	return (
		<AdminLayout maxWidth="md" breadcrumbs={breadcrumbs}>
			<Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
				<ReviewerDashboard />
			</Box>
		</AdminLayout>
	);
};

export default connect(mapLanguageStateToProps)(ReviewerAssignedEntries);
