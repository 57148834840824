export type SeasonType = {
	name: string;
	name_afr: string;
	icon: string;
};

export const SeasonTypes: SeasonType[] = [
	{
		name: 'Short documentaries',
		name_afr: 'Kort dokumentêre films',
		icon: 'icon-movie',
	},
	{
		name: 'Shortfilms',
		name_afr: 'Kortfilms',
		icon: 'icon-camera',
	},
];
