import {
	Alert,
	Button,
	FormControl,
	Grid,
	InputLabel,
	NativeSelect,
	OutlinedInput,
	Typography,
} from '@mui/material';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../redux/props/language.props';
import { Container } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Link, Navigate, useParams } from 'react-router-dom';
import Footer from '../components/landing/Footer';
import HeroBanner from '../components/landing/HeroBanner';
import DefaultLayout from '../components/layouts/Default';
import { ISeason, ISeasonFormField, Season } from '../models/seasons.model';
import Cookies from 'universal-cookie';
import { IFieldAnswer } from '../models/form.model';
import { ILanguage } from '../models/language.model';
import { connect } from 'react-redux';
import translations, { ILanguageOptions } from '../data/translations';
import { genres } from '../data/genre';
import { capacities } from '../data/capacity';

type IProps = {
	language: ILanguage;
};

const Apply: React.FC<IProps> = ({ language }) => {
	let { id } = useParams<URLSearchParams | any>();
	const [season, setSeason] = useState<ISeason>(new Season());
	const [redirect, setRedirect] = useState<boolean>(false);
	const [form, setForm] = useState<ISeasonFormField[]>([]);
	const [logged_in, setLoggedIn] = useState<boolean>(false);
	const [user_id, setUserID] = useState<number>(0);
	const [errorResponse, setErrorResponse] = useState<string>('');

	// Terms and conditions
	const [show, setShow] = useState<boolean>(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get(`seasons/${id}`, {
				params: {
					file: 'src/pages/Apply.tsx',
				},
			});
			const verify = await axios.get('auth/verify');
			const cookies = new Cookies();

			// Remember user's application choice if they have not logged in yet
			if (!verify.data) {
				cookies.set('season', id, { path: '/' });
			} else {
				setUserID(cookies.get('user'));
			}

			setForm(data.form);
			setSeason(data);
			setLoggedIn(verify.data ? true : false);
		}
		fetchData();
	}, [id]);

	const submitEntry = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const form_data = new FormData(event.currentTarget);

		let answers: IFieldAnswer[] = [];

		form_data.forEach((answer, field_id) => {
			answers.push({
				answer: String(answer),
				question: Number(field_id),
			});
		});

		await axios
			.post(`seasons/${id}/apply`, {
				user: user_id, // logged in user's id
				answers: answers,
			})
			.then((response) => {
				setRedirect(true);
			})
			.catch((error) => {
				const response = JSON.parse(error.request.response);
				setErrorResponse(response.message);
			});
	};

	const wordCountCheck = async (
		event: React.FormEvent<HTMLFormElement>,
		value: string,
		label: string
	) => {
		event.preventDefault();

		const words = value.split(' ');

		if (words.length > 300) {
			alert(`Please reduce the number of words you are using for: ${label}`);
		}
	};

	if (redirect) {
		return <Navigate to={'/dashboard'} />;
	}

	return (
		<DefaultLayout>
			<HeroBanner />

			<Container component="main" sx={{ mt: 9, mb: 12 }}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography variant="h3" component="h2" className="mb-0">
							{language.isEnglish ? season?.title : season?.title_afr}
						</Typography>
						{errorResponse !== '' && (
							<Alert severity="warning" className="mb-3">
								{errorResponse}
							</Alert>
						)}
					</Grid>
					<Grid
						item
						xs={6}
						className="d-flex justify-content-end align-content-center"
					>
						<Button
							color="secondary"
							className="me-3"
							variant="outlined"
							onClick={handleShow}
						>
							{language.isEnglish
								? translations.terms.eng
								: translations.terms.afr}
						</Button>
					</Grid>
				</Grid>

				{/* <Typography variant="subtitle2" className="mb-4 mt-3">
          {formatDate(season.start_date)} - {formatDate(season.end_date)}
        </Typography>

        <Typography variant="body1" className="mb-4">
          {season.description}
        </Typography> */}

				{/* {!betweenDates(season.start_date, season.end_date) && 
          <p>Entries are not yet open for this season. Please come back after {formatDate(season.start_date)}</p>
        } */}

				{/* {betweenDates(season.start_date, season.end_date) &&  */}
				<>
					<Typography variant="h2" component="h3" className="mb-1">
						{language.isEnglish ? 'Apply Now' : 'Doen nou aansoek'}
					</Typography>

					{logged_in && (
						<Form onSubmit={submitEntry}>
							{form.map((item) => {
								return (
									<>
										{item.type?.id === 5 && (
											<FormControl
												className="mt-3"
												fullWidth
												variant="standard"
											>
												<InputLabel>
													{language.isEnglish ? item.label : item.label_afr}
												</InputLabel>
												<NativeSelect name={`${item.id}`}>
													{capacities.map((genre: ILanguageOptions, index) => {
														return (
															<option key={index} value={genre.eng}>
																{language.isEnglish ? genre.eng : genre.afr}
															</option>
														);
													})}
												</NativeSelect>
											</FormControl>
										)}

										{item.type?.id === 4 && (
											<FormControl
												className="mt-3"
												fullWidth
												variant="standard"
											>
												<InputLabel>
													{language.isEnglish ? item.label : item.label_afr}
												</InputLabel>
												<NativeSelect name={`${item.id}`}>
													{genres.map((genre: ILanguageOptions, index) => {
														return (
															<option key={index} value={genre.eng}>
																{language.isEnglish ? genre.eng : genre.afr}
															</option>
														);
													})}
												</NativeSelect>
											</FormControl>
										)}

										{item.type && item.type?.id && item.type?.id < 4 && (
											<FormControl key={item.id} className="mt-3" fullWidth>
												<InputLabel>
													{language.isEnglish ? item.label : item.label_afr}
												</InputLabel>
												<OutlinedInput
													label={item.label}
													multiline
													fullWidth
													required={true}
													name={`${item.id}`}
													inputProps={{ maxLength: item.max_chars }}
													type={item.type?.id === 3 ? 'number' : 'text'}
													minRows={item.type?.id === 2 ? 5 : 1}
												/>
											</FormControl>
										)}
									</>
								);
							})}
							<Button
								className="mt-4"
								variant="contained"
								color="primary"
								type="submit"
							>
								{language.isEnglish ? 'Submit Application' : 'Dien aansoek in'}
							</Button>
						</Form>
					)}
				</>
				{/* } */}

				{!logged_in && (
					<Typography variant="body1" className="mt-3">
						Please
						<Button
							variant="outlined"
							size="small"
							className="ms-2 mx-2"
							component={Link}
							to="/sign-in"
						>
							login
						</Button>
						or
						<Button
							variant="outlined"
							size="small"
							className="ms-2 mx-2"
							component={Link}
							to="/sign-up"
						>
							register
						</Button>
						to submit your application.
					</Typography>
				)}
			</Container>

			<Modal show={show} onHide={handleClose} size="xl">
				<Modal.Header closeButton>
					<Modal.Title>
						{language.isEnglish
							? translations.terms.eng
							: translations.terms.afr}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						dangerouslySetInnerHTML={{
							__html: language.isEnglish
								? season.terms_and_conditions
								: season.terms_and_conditions_afr,
						}}
					></div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outlined" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>

			<Footer />
		</DefaultLayout>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(Apply);
