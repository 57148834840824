import React, { useEffect, useState } from 'react';
import { ILanguage } from '../../../models/language.model';
import axios from 'axios';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { mapLanguageStateToProps } from '../../../redux/props/language.props';
import { connect } from 'react-redux';
import translations from '../../../data/translations';

interface IProps {
	language: ILanguage;
	id: number;
}

const ReviewerBreadcrumbs: React.FC<IProps> = ({ language, id }) => {
	const [title, setTitle] = useState<string>('');
	const [title_afr, setTitleAfr] = useState<string>('');

	useEffect(() => {
		async function fetchData() {
			if (id) {
				const { data } = await axios.get(`seasons/${id}`, {
					params: {
						file: 'src/pages/reviewers/components/ReviewerBreadcrumbs.tsx',
					},
				});
				setTitle(data.title);
				setTitleAfr(data.title_afr);
			}
		}
		fetchData();
	}, [id]);

	return (
		<Breadcrumbs sx={{ marginBottom: '0.5em' }}>
			<Link underline="hover" color="inherit" href="/reviewers">
				{language.isEnglish
					? translations.reviewers.eng
					: translations.reviewers.afr}
			</Link>
			<Typography color="text.primary">
				{language.isEnglish ? title : title_afr}
			</Typography>
		</Breadcrumbs>
	);
};

export default connect(mapLanguageStateToProps)(ReviewerBreadcrumbs);
