import {
	Box,
	Button,
	Checkbox,
	Chip,
	Container,
	FormControlLabel,
	Paper,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import StepperNav from '../../components/transactions/StepperNav';
import { ISubmission, Submision } from '../../models/submissions.model';
import { IUser, User } from '../../models/user.model';

interface IProps {}

interface URLParams {
	id: any;
	transactionid: any;
}

const ConfirmPayment: React.FC<IProps> = () => {
	const [entry, setEntry] = useState<Submision>();
	const [checked, setChecked] = useState<boolean>(false);
	const [user, setUser] = useState<IUser>(new User());
	const [form, setForm] = useState<string>('');
	const { id, transactionid } = useParams<URLParams | any>();

	useEffect(() => {
		async function fetchData() {
			const get_user = await axios.get('user');
			setUser(get_user.data);

			// Season details
			const get_entry = await axios.get(`entry/${id}`);
			const entry: Submision = get_entry.data;
			const reference = entry.answers[0].answer;

			await axios.put(`transaction/${transactionid}/${entry.season.cost}`);

			setEntry(entry);

			// Payfast form
			const { data } = await axios.post(`entry/${id}/form`, {
				entry_id: id,
				transaction_id: transactionid,
				transaction_reference: reference,
				first_name: user.first_name,
				last_name: user.last_name,
				email_address: user.email,
				cell_number: user.contact_number,
				amount: entry.season.cost.toFixed(2),
				item_name: `${entry.season.title!}`,
				item_description: `Entry #${id}`,
			});

			setForm(data);
		}
		fetchData();
	}, [id]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	return (
		<AdminLayout>
			<Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
				<Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
					<StepperNav active={1} />

					{(!user.contact_number || !user.first_name || !user.last_name) && (
						<div className="text-center mb-4">
							<Typography variant="body1">
								You are missing the following information:
							</Typography>

							<Typography variant="body1" marginBottom={3}>
								{!user.contact_number && (
									<Chip
										label="Contact number"
										className="me-1"
										variant="outlined"
									/>
								)}
								{!user.first_name && (
									<Chip
										label="First name"
										className="me-1"
										variant="outlined"
									/>
								)}
								{!user.last_name && (
									<Chip label="Last name" className="me-1" variant="outlined" />
								)}
							</Typography>

							<Typography variant="body1" marginBottom={2}>
								Please update your profile before making any payments.
							</Typography>

							<Button
								variant="contained"
								size="small"
								className="ms-2 mx-2 mb-4"
								component={Link}
								to={`/profile/edit`}
							>
								Update My Profile
							</Button>
						</div>
					)}

					{user.contact_number && user.first_name && user.last_name && (
						<>
							<Typography variant="h3" className="mb-3 text-center">
								You are about to pay R{entry?.season.cost.toFixed(2)}
							</Typography>

							<FormControlLabel
								sx={{ display: 'flex', justifyContent: 'center' }}
								control={
									<Checkbox
										checked={checked}
										onChange={handleChange}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								}
								label="I understand"
							/>

							<Form
								action="https://www.payfast.co.za/eng/process"
								method="post"
								id="payfast_form"
							>
								<span dangerouslySetInnerHTML={{ __html: form }}></span>
								<Box
									component="div"
									sx={{ display: 'flex', justifyContent: 'center' }}
								>
									<Button
										disabled={!checked}
										type="submit"
										variant="contained"
										sx={{ mt: 3, ml: 1 }}
									>
										Confirm and pay now
									</Button>
								</Box>
							</Form>
						</>
					)}
				</Paper>
			</Container>

			{/* 
      <p>Show entry details (mark in red if anything is outstanding)</p>
      <p>Show user if their email / phone number is missing with a button to go to their profile page</p>
      <p>The pay button must only be available when all the required data is there</p>
		  <div className="post__content" dangerouslySetInnerHTML={{ __html: form }}></div> */}
		</AdminLayout>
	);
};

export default ConfirmPayment;
