import React from 'react';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { connect } from 'react-redux';
import { ILanguage } from '../../models/language.model';
import translations from '../../data/translations';
import { Typography } from '@mui/material';

type IProps = {
	language: ILanguage;
	toggleSignUp?: (open: boolean) => void;
	toggleSignIn?: (open: boolean) => void;
	signInText?: boolean;
};

const Links: React.FC<IProps> = ({
	language,
	signInText = false,
	toggleSignUp,
	toggleSignIn,
}) => {
	return (
		<Grid
			container
			color="var(--grey-800)"
			bgcolor="var(--grey-100)"
			borderRadius="8px"
			padding="10px 16px"
			marginTop="10px"
		>
			<Grid item xs={7}>
				<Typography variant="bodyL" fontWeight="bold">
					{language.isEnglish
						? !signInText
							? `Don't have an account?`
							: 'Already have an account?'
						: !signInText
						? `Het jy nie 'n rekening nie?`
						: `Het jy reeds 'n profiel?`}
				</Typography>
			</Grid>
			<Grid item xs textAlign="right" display="flex" justifyContent="flex-end">
				<Link
					component={NavLink}
					to="/sign-up"
					variant="bodyM"
					fontWeight="bold"
					display="flex"
					onClick={(e) => {
						e.preventDefault();
						!signInText && toggleSignUp && toggleSignUp(true);
						signInText && toggleSignIn && toggleSignIn(true);
					}}
				>
					{language.isEnglish
						? !signInText
							? translations.signUp.eng
							: translations.signIn.eng
						: !signInText
						? translations.signUp.afr
						: translations.signIn.afr}
					<span className="icon icon-end icon-chevron-right"></span>
				</Link>
			</Grid>
		</Grid>
	);
};

export default connect(mapLanguageStateToProps)(Links);
