import { TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ISubmission } from '../../models/submissions.model';
import { connect } from 'react-redux';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { ILanguage } from '../../models/language.model';
import { GridColDef } from '@mui/x-data-grid';
import { RenderSubmissionButtons } from '../../pages/submissions/components/Buttons';
import Table from '../Table';
import Loading from '../Loading';
import _ from 'lodash';
import { IFieldAnswer, IFieldAnswerV2 } from '../../models/form.model';

type IProps = {
	language: ILanguage;
	season_id?: number;
	has_pagination?: boolean;
	limit?: number;
	dashboard?: boolean;
	submissions?: ISubmission[];
};

interface TableRow {
	id: number;
	title: string;
	// created: string;
	// published: string; // draft ? no : yes
	// last_updated: string;
	payment: string;
	user_name: string;
	// user_email: string;
	actions: any;
}

const AdminSubmissionsListView: React.FC<IProps> = ({
	language,
	season_id,
	has_pagination = false,
	limit,
	dashboard = false,
	submissions = [],
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [rows, setRows] = useState<TableRow[]>([]);

	const columns: GridColDef[] = [
		{
			field: 'id',
		},
		{
			field: 'title',
			headerName: 'Title',
			flex: 1,
		},
		{
			field: 'payment',
			headerName: 'Payment',
			flex: 1,
		},
		{
			field: 'user_name',
			headerName: 'Name',
			flex: 1,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			renderCell: RenderSubmissionButtons,
			flex: 1,
		},
	];

	useEffect(() => {
		async function fetchData() {
			const table_rows: TableRow[] = submissions.map((item: ISubmission) => {
				let entry_title: any[] = [];
				const answers = item.answers as IFieldAnswerV2[];

				entry_title = answers.filter((answer) =>
					answer.question.label.toLowerCase().includes('title')
				);

				let payment_ref =
					!item.payment_reference && item.payment_override ? 'OVERRIDDEN' : '—';

				payment_ref =
					!item.payment_reference && !item.payment_override ? '—' : payment_ref;

				payment_ref =
					!item.payment_override &&
					item.transaction &&
					item.transaction.payment_date
						? item.payment_reference
						: payment_ref;

				return {
					id: item.id,
					title:
						entry_title?.length > 0 ? entry_title[0].answer : '— INCOMPLETE',
					payment: payment_ref,
					user_name: `${item.user.first_name} ${item.user.last_name}`,
					actions: null,
				};
			});

			const sorted_rows = _.sortBy(table_rows, 'title');

			setRows(sorted_rows);
		}
		fetchData();
	}, [submissions, season_id]);

	return (
		<>
			{loading && <Loading />}

			{!loading && (
				<Table
					className={dashboard ? '' : 'mt-3'}
					rows={rows}
					columns={columns}
				/>
			)}
		</>
	);
};

export default connect(mapLanguageStateToProps)(AdminSubmissionsListView);
