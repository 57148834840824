import * as React from 'react';
import { connect } from 'react-redux';
import { mapLanguageStateToProps } from '../../../redux/props/language.props';
import { ILanguage } from '../../../models/language.model';
import AppBarLanguage from '../bar/AppBarLanguage';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import translations from '../../../data/translations';
import Cookies from 'universal-cookie';

type IProps = {
	language: ILanguage;
};

const NavLogout: React.FC<IProps> = ({ language }) => {
	const [redirect, setRedirect] = React.useState<boolean>(false);
	const cookies = new Cookies();

	const logout = async () => {
		await axios.post('logout', {}).then((response) => {
			cookies.remove('user');
			setRedirect(true);
		});
	};

	if (redirect) {
		return <Navigate to={'/'} />;
	}

	return (
		<Box
			paddingLeft="16px"
			display="flex"
			flexDirection="column"
			gap="16px"
			marginBottom="16px"
			marginTop="auto"
		>
			<AppBarLanguage />
			<Link href="#" onClick={logout} display="flex">
				<span className="icon icon-logout icon-start"></span>
				{language.isEnglish ? translations.logOut.eng : translations.logOut.afr}
			</Link>
		</Box>
	);
};

export default connect(mapLanguageStateToProps)(NavLogout);
