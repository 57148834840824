import { Box, Link, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
	bgColor: string;
	label: string;
	color?: string;
	icon?: ReactNode;
	num?: number;
	onClick: () => void;
};

export const StatusBox: React.FC<Props> = ({
	bgColor,
	label,
	color = 'var(--grey-800)',
	icon = <></>,
	num = 0,
	onClick,
}) => {
	return (
		<Link
			sx={{
				backgroundColor: bgColor,
				padding: 'var(--spacer-xxs) 14px',
				borderRadius: 'var(--spacer-xs)',
				font: 'var(--body-s)',
				cursor: 'pointer',
				color,
				maxWidth: {
					xs: '50%',
					md: 112,
				},
				'&:hover': {
					color,
					// boxShadow: 'var(--box-shadow)',
				},
			}}
			width="50%"
			onClick={onClick}
		>
			<Box
				sx={{
					svg: { width: 16, height: 16, fill: color },
					display: 'flex',
					alignItems: 'center',
					gap: 'var(--spacer-xxs)',
					font: 'var(--body-s)',
				}}
			>
				{icon}
				{label}
			</Box>
			<Typography sx={{ font: 'var(--h4-bold)', color }}>{num}</Typography>
		</Link>
	);
};
