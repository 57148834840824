import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { RenderEntryStatus } from '../../components/reviewer/components/Status';
import { GridColDef } from '@mui/x-data-grid';
import {
	IDashboardReviewersEntries,
	IReviewersAssignedEntries,
} from '../../models/reviewers.model';
import axios from 'axios';
import Loading from '../../components/Loading';

interface IProps {
	id: number; // reviewer id
	season_id: number;
}

const AssignedEntries: React.FC<IProps> = ({ id, season_id }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [entries, setEntries] = useState<IReviewersAssignedEntries[]>([]);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			await axios
				.get(`reviewer-entries/${id}/admin/${season_id}`)
				.then((items) => {
					setLoading(false);

					const test = items.data[0].entries;

					if (test.length === 1) {
						const data: IDashboardReviewersEntries[] = items.data;
						const newData = data.map((item) => {
							return item.entries[0];
						});

						setEntries(newData);
					} else {
						setEntries(test);
					}
				});
		}
		fetchData();
	}, [id]);

	const columns: GridColDef[] = [
		{
			field: 'reviewed',
			headerName: 'Status',
			renderCell: RenderEntryStatus,
			width: 130,
		},
		{
			field: 'entry_title',
			headerName: 'Title',
			flex: 1,
		},
		{
			field: 'user_name',
			headerName: 'User',
			flex: 1,
		},
		{
			field: 'user_email',
			headerName: 'Email',
			flex: 1,
		},
		{
			field: 'user_contact_number',
			headerName: 'Number',
			flex: 1,
		},
		{
			field: 'user_based_in',
			headerName: 'Based in',
			flex: 1,
		},
	];
	// TODO
	// Add column to show grade received (final %)

	return (
		<>
			{loading && <Loading text="Loading assigned entries" />}

			{!loading && entries?.length > 0 && (
				<Table rows={entries} columns={columns} />
			)}
		</>
	);
};

export default AssignedEntries;
