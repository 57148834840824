import React from 'react';
import { User } from '../../../models/user.model';
import { mapStateToProps } from '../../../redux/props/user.props';
import { connect } from 'react-redux';
import {
	AppBar,
	Box,
	Container,
	Drawer,
	IconButton,
	List,
	Toolbar,
	Typography,
} from '@mui/material';
import logo from '../../../assets/logo/logo.png';
import { useTheme } from '@mui/material';
import NavItems from './NavItems';
import MenuIcon from '@mui/icons-material/Menu';
import NavLogout from './NavLogout';

type IProps = {
	user: User;
	window?: () => Window;
};

const AdminMobileNav: React.FC<IProps> = ({ user, window }) => {
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const theme = useTheme();
	const drawerWidth = 240;

	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};

	const drawer = (
		<Box
			onClick={handleDrawerToggle}
			style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
		>
			<List component="nav">
				<NavItems user={user} />
			</List>
			<NavLogout />
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<Box
			sx={{
				display: { xs: 'block', md: 'none' },
			}}
		>
			<AppBar
				position="static"
				variant="outlined"
				sx={{
					borderRadius: 0,

					'.MuiContainer-root.MuiContainer-maxWidthXl': {
						marginBottom: 0,
						paddingBottom: {
							xs: 'var(--spacer-xs)',
							sm: 'var(--spacer)',
						},
						paddingTop: {
							xs: 'var(--spacer-xs)',
							sm: 'var(--spacer)',
						},
					},
				}}
			>
				<Container maxWidth="xl">
					<Toolbar
						sx={{
							minHeight: {
								xs: '0',
							},
							display: 'flex',
							alignContent: 'center',
						}}
						disableGutters
					>
						<Typography
							component="a"
							href="/"
							sx={{
								display: 'block',
								height: {
									xs: '26px',
									lg: '40px',
								},
							}}
						>
							<img
								src={logo}
								height="100%"
								alt="Silwerskerm Fees"
								className="me-4"
							/>
						</Typography>

						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{
								marginLeft: 'auto',
								marginRight: 0,
								p: 0,
								svg: { marginRight: 0 },
							}}
						>
							<MenuIcon />
						</IconButton>
					</Toolbar>
				</Container>
			</AppBar>
			<nav>
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
			</nav>
		</Box>
	);
};

export default connect(mapStateToProps)(AdminMobileNav);
