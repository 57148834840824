import { connect } from 'react-redux';
import { ILanguage } from '../../../models/language.model';
import { mapLanguageStateToProps } from '../../../redux/props/language.props';
import {
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import { IFieldAnswerDB } from '../../../models/form.model';
import { useEffect, useState } from 'react';
import axios from 'axios';

type IProps = {
	language: ILanguage;
	id: number;
	isAdmin?: boolean;
	isReviewer?: boolean;
	setHasBeenReviewed?: (bool: boolean) => void;
	userId?: number;
	reload?: boolean;
};

const EntryAnswers: React.FC<IProps> = ({
	language,
	id,
	isAdmin = false,
	isReviewer = false,
	setHasBeenReviewed = () => {},
	reload = false,
	userId,
}) => {
	const [answers, setAnswers] = useState<IFieldAnswerDB[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			let endpoint = isAdmin ? 'submission' : 'entry';
			endpoint = isReviewer ? 'review-entry' : endpoint;

			await axios.get(`${endpoint}/${id}`).then((response) => {
				const { answers, grades } = response.data;
				setAnswers(answers);
				setLoading(false);

				if (userId) {
					setHasBeenReviewed(grades ? true : false);
				}
			});
		}
		fetchData();
	}, [id, reload]);

	return (
		<>
			{/* {loading && <Loading />} */}

			{answers.length > 0 && (
				<List sx={{ p: 0 }}>
					{answers.map((item: IFieldAnswerDB, index) => {
						const hide_on_review_form = item.question?.hide_on_review_form;
						const label = item.question?.label || '';

						return (
							<div key={index}>
								{((label.toLowerCase() !== 'script writer' &&
									label.toLowerCase() !== 'director' &&
									label.toLowerCase() !== 'producer' &&
									isReviewer &&
									!hide_on_review_form) ||
									(!isReviewer && !hide_on_review_form)) && (
									<>
										<ListItem
											alignItems="flex-start"
											sx={{
												paddingLeft: 0,
												paddingRight: 0,
												paddingTop: 0,
												marginTop: 0,
											}}
										>
											<ListItemText
												primary={
													<Typography sx={{ font: 'var(--body-l-bold)' }}>
														{language.isEnglish
															? item.question!.label
															: item.question!.label_afr}
													</Typography>
												}
												secondary={item.answer}
											/>
										</ListItem>
										{index < answers.length - 1 && (
											<Divider
												component="li"
												sx={{
													marginBottom: 'var(--spacer-xs)',
													marginTop: 'var(--spacer-xs)',
												}}
											/>
										)}
									</>
								)}
							</div>
						);
					})}
				</List>
			)}
		</>
	);
};

export default connect(mapLanguageStateToProps)(EntryAnswers);
