import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AdminDashboard from '../components/dashboards/AdminDashboard';
import UserDashboard from '../components/dashboards/UserDashboard';
import AdminLayout from '../components/layouts/Admin';
import { IPermission } from '../models/permission.model';
import ReviewerDashboard from '../components/dashboards/ReviewerDashboard';
import { User } from '../models/user.model';
import { mapStateToProps } from '../redux/props/user.props';
import { connect } from 'react-redux';
import { Box, Breakpoint } from '@mui/material';

type IProps = {
	user: User;
};

const Dashboard: React.FC<IProps> = ({ user }) => {
	// Can view which dashboards
	const [admin_dashboard, setAdminDashboard] = useState<boolean>(false);
	const [user_dashboard, setUserDashboard] = useState<boolean>(false);
	const [reviewer_dashboard, setReviewerDashboard] = useState<boolean>(false);
	const [breadcrumbs, setBreadcrumbs] = useState<string[]>();
	const [maxWidth, setMaxWidth] = useState<Breakpoint | false>('lg');
	// TODO: super admin, auditor, reviewer

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get('user/access');
			const permissions = data.permissions;
			const role_id = Number(data.id);

			if (
				permissions.some((p: IPermission) => p.name.includes('entry')) ||
				role_id === 5 // user
			) {
				setUserDashboard(true);
				setBreadcrumbs(['My Entries']);
			}

			// Admin & super admin
			if (role_id === 1 || role_id === 2) {
				setAdminDashboard(true);
				setMaxWidth('lg');
				setBreadcrumbs(['Entries']);
			}

			// Reviewer
			if (role_id === 4) {
				setMaxWidth('md');
				setReviewerDashboard(true);
				setBreadcrumbs(['Assigned Entries']);
			}
		}
		fetchData();
	}, []);

	return (
		<AdminLayout maxWidth={maxWidth} breadcrumbs={breadcrumbs}>
			<Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
				{user_dashboard && <UserDashboard user={user} />}
				{admin_dashboard && <AdminDashboard />}
				{reviewer_dashboard && <ReviewerDashboard />}
			</Box>
		</AdminLayout>
	);
};

export default connect(mapStateToProps)(Dashboard);
