import React, { useEffect, useState } from 'react';
import { User } from '../../../models/user.model';
import { mapStateToProps } from '../../../redux/props/user.props';
import { connect } from 'react-redux';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	NativeSelect,
	OutlinedInput,
} from '@mui/material';
import DialogClose from '../../DialogClose';
import { IFieldAnswerDB } from '../../../models/form.model';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { ILanguage } from '../../../models/language.model';
import { genres } from '../../../data/genre';
import { ILanguageOptions } from '../../../data/translations';
import { capacities } from '../../../data/capacity';

interface IProps {
	onCloseModal: (close: boolean, reload: boolean) => void;
	entryID: number | null;
	language: ILanguage;
	open: boolean;
	user: User;
}

const EditModal: React.FC<IProps> = ({
	open,
	onCloseModal,
	entryID,
	user,
	language,
}) => {
	const [modalOpen, setOpenModal] = useState<boolean>(open);
	const [answers, setAnswers] = useState<IFieldAnswerDB[]>([]);
	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get(`entry/${entryID}`);

			setAnswers(data.answers);
			setRefresh(false);
		}
		fetchData();
	}, [entryID, refresh]);

	useEffect(() => {
		setOpenModal(open);
	});

	const closeModal = () => {
		onCloseModal(true, false);
	};

	const submitEntry = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const form_data = new FormData(event.currentTarget);

		let answers: IFieldAnswerDB[] = [];

		form_data.forEach((answer, answer_id) => {
			answers.push({
				answer: String(answer),
				id: Number(answer_id),
			});
		});

		await axios.put(`entry/${entryID}/answers`, {
			answers: answers,
		});

		onCloseModal(true, true);
		setRefresh(true);
	};

	return (
		<Dialog open={modalOpen} onClose={closeModal}>
			<DialogTitle>
				Update Entry #{entryID}
				<DialogClose
					onClick={(e) => {
						e.preventDefault();
						closeModal();
					}}
				/>
			</DialogTitle>
			<DialogContent>
				<Form onSubmit={submitEntry}>
					{answers.map((item: IFieldAnswerDB) => {
						return (
							<>
								{item.question?.type?.id === 5 && (
									<FormControl
										key={item.id}
										className="mt-4"
										fullWidth
										variant="standard"
									>
										<InputLabel>
											{language.isEnglish
												? item.question!.label
												: item.question!.label_afr}
										</InputLabel>
										<NativeSelect
											defaultValue={item.answer}
											name={`${item.id}`}
										>
											{capacities.map((genre: ILanguageOptions, index) => {
												return (
													<option key={index} value={genre.eng}>
														{language.isEnglish ? genre.eng : genre.afr}
													</option>
												);
											})}
										</NativeSelect>
									</FormControl>
								)}

								{item.question?.type?.id === 4 && (
									<FormControl
										key={item.id}
										className="mt-4"
										fullWidth
										variant="standard"
									>
										<InputLabel>
											{language.isEnglish
												? item.question!.label
												: item.question!.label_afr}
										</InputLabel>
										<NativeSelect
											defaultValue={item.answer}
											name={`${item.id}`}
										>
											{genres.map((genre: ILanguageOptions, index) => {
												return (
													<option key={index} value={genre.eng}>
														{language.isEnglish ? genre.eng : genre.afr}
													</option>
												);
											})}
										</NativeSelect>
									</FormControl>
								)}

								{item.question &&
									item.question?.type &&
									item.question?.type?.id &&
									item.question?.type?.id < 4 && (
										<FormControl key={item.id} className="mt-4" fullWidth>
											<InputLabel>
												{language.isEnglish
													? item.question!.label
													: item.question!.label_afr}
											</InputLabel>
											<OutlinedInput
												label={
													language.isEnglish
														? item.question!.label
														: item.question!.label_afr
												}
												multiline
												fullWidth
												required={true}
												name={`${item.id}`}
												defaultValue={item.answer}
												type={item.question?.type?.id === 3 ? 'number' : 'text'}
												minRows={item.question?.type?.id === 2 ? 5 : 1}
											/>
										</FormControl>
									)}
							</>
						);
					})}
					<Button
						className="mt-4"
						variant="contained"
						color="primary"
						type="submit"
						fullWidth
					>
						Confirm and save
					</Button>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default connect(mapStateToProps)(EditModal);
