import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import { useForm, useFieldArray } from 'react-hook-form';
import {
	Box,
	Button,
	Grid,
	IconButton,
	TextField,
	Tooltip,
	Typography,
	colors,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import {
	ISeason,
	ISeasonGradingField,
	Season,
} from '../../models/seasons.model';
import SaveIcon from '@mui/icons-material/Save';
import SeasonBreadcrumbs from './components/Breadcrumbs';
import SeasonOptions from './components/Options';

interface IProps {}

interface URLParams {
	id: number;
}

const SeasonGradingCategories: React.FC<IProps> = () => {
	const { id } = useParams<URLParams | any>();
	const [season, setSeason] = useState<ISeason>(new Season());
	const [form, setForm] = useState<ISeasonGradingField[]>([]);
	const [redirect, setRedirect] = useState<boolean>(false);
	const [totalGradingScore, setTotalGradingScore] = useState<number>();

	useEffect(() => {
		async function getData() {
			const get_season = await axios.get(`seasons/${id}`, {
				params: {
					file: 'src/pages/seasons/SeasonGradingCategories.tsx',
				},
			});
			const season = get_season.data;

			setSeason(season);
			setForm(season.grading_categories);
		}
		getData();
	}, [id]);

	const defaultValues = {
		id: null,
		description: null,
		grading_score: null,
	};

	const { control, register, handleSubmit, watch } = useForm({
		defaultValues: {
			form: [defaultValues],
		},
		values: {
			form: form,
		},
	});

	React.useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			if (value?.form && value?.form.length > 0) {
				const sum = value.form
					.map((a) => a?.grading_score)
					.reduce(function (a, b) {
						return Number(a) + Number(b);
					});

				if (sum) {
					setTotalGradingScore(Number(sum));
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'form',
	});

	const onSave = (data: any) => {
		let { form } = data;

		axios.put(`grading/${id}/form`, {
			form,
		});

		setRedirect(true);
	};

	if (redirect) {
		return <Navigate to={`/seasons/${id}/edit`} />;
	}

	return (
		<AdminLayout>
			<Box sx={{ display: 'flex' }}>
				<SeasonBreadcrumbs
					id={id}
					title={season.title}
					page="Grading Categories"
				/>
				{totalGradingScore && totalGradingScore !== 100 && (
					<Typography marginLeft="auto" color={colors['red'][400]}>
						Grading score MUST be 100%
					</Typography>
				)}
			</Box>
			<form onSubmit={handleSubmit(onSave)}>
				<Grid container spacing={2} className="mb-2">
					<Grid item xs={6}>
						<Typography variant="h3" component="h2">
							{form.length > 0 ? 'Update' : 'Create'} Grading Categories
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Box
							sx={{
								marginBottom: '1.5em',
								float: 'right',
							}}
						>
							<SeasonOptions id={id} />

							<Button variant="contained" color="success" type="submit">
								<SaveIcon fontSize="small" className="me-2" />
								Save Details
							</Button>
						</Box>
					</Grid>
				</Grid>

				{fields.map((field, index) => (
					<Grid key={field.id} container spacing={2} className="mb-2">
						<TextField
							type="number"
							value={Number(id)}
							{...register(`form.${index}.season`)}
							hidden
						/>
						<Grid item xs={8}>
							<TextField
								id="outlined-basic"
								label="Description"
								variant="outlined"
								size="small"
								fullWidth
								multiline
								required
								className="mb-2"
								{...register(`form.${index}.description`)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								id="outlined-basic"
								label="Grading Score"
								variant="outlined"
								size="small"
								fullWidth
								required
								className="mb-2"
								type="number"
								{...register(`form.${index}.grading_score`)}
							/>
						</Grid>
						<Grid item>
							{fields.length !== 1 && (
								<Tooltip title="Delete" placement="right" arrow>
									<IconButton color="secondary" onClick={() => remove(index)}>
										<DeleteIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							)}
						</Grid>
					</Grid>
				))}

				<Button
					className="mt-2 mb-2 float-end"
					size="medium"
					variant="outlined"
					color="secondary"
					fullWidth
					endIcon={<AddBoxIcon />}
					onClick={() => append(defaultValues)}
				>
					Add Row
				</Button>
			</form>
		</AdminLayout>
	);
};

export default SeasonGradingCategories;
