import React from "react";

interface IProps {
  price: number;
}

const Currency: React.FC<IProps> = ({ price }) => {
  return (
    <>
    {Intl.NumberFormat('en-ZA', { 
      style: 'currency', 
      currency: 'ZAR'
      }).format(price)}
    </>
  );
}

export default Currency;