import { Box, Button, Container, Paper, Typography } from '@mui/material';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';

interface IProps {}

interface URLParams {
	id: any;
	transactionid: any;
}

const TransactCancelled: React.FC<IProps> = () => {
	const { id, transactionid } = useParams<URLParams | any>();

	return (
		<AdminLayout>
			<Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
				<Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
					<Typography component="h1" variant="h3" align="center">
						Transaction Cancelled
					</Typography>

					<Typography variant="body1" className="mb-3">
						If this was a mistake, you can proceed to make a payment by clicking
						on the button below or you can navigate back to your entries list by
						clicking on the "Entries" button on the left.
					</Typography>

					<Box
						component="div"
						sx={{ display: 'flex', justifyContent: 'center' }}
					>
						<Button
							variant="contained"
							fullWidth={true}
							sx={{ mt: 3, ml: 1 }}
							component={Link}
							to={`/entries/${id}/transact/pay/${transactionid}`}
						>
							Pay Entry
						</Button>
					</Box>
				</Paper>
			</Container>
		</AdminLayout>
	);
};

export default TransactCancelled;
