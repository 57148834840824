const date_current = new Date();

export const gteDate = (date: Date): boolean => {
	if (date >= date_current) {
		return true;
	}

	return false;
};

export const lteDate = (date: Date): boolean => {
	if (date <= date_current) {
		return true;
	}

	return false;
};

export const betweenDates = (start_date: Date, end_date: Date) => {
	return gteDate(start_date) && lteDate(end_date);
};

export const formatDate = (date: Date | string): string => {
	return new Date(date).toISOString().slice(0, 10);
};

export const padTo2Digits = (num: number) => {
	return num.toString().padStart(2, '0');
};

export const formatDateTime = (date: Date): string => {
	return (
		[
			date.getFullYear(),
			padTo2Digits(date.getMonth() + 1),
			padTo2Digits(date.getDate()),
		].join('-') +
		' ' +
		[padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(':')
	);
};

export const stringToColor = (string: string) => {
	let hash = 0;
	let i;

	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}

	return color;
};

export const stringToColorLight = (string: string) => {
	let hash = 0;
	for (var i = 0; i < string.length; i++) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}
	return `hsl(${hash % 360}, 40%, 80%)`;
};

export const stringAvatar = (name: string) => {
	const nameArr = name
		.toUpperCase()
		.split(' ')
		.filter((str) => str !== '');
	const char1 = nameArr[0][0];
	const char2 = nameArr.length > 1 ? nameArr[1][0] : '';

	return {
		style: {
			color: 'white',
		},
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${char1}${char2}`,
	};
};

export const roleColor = (name: string) => {
	return {
		sx: {
			color: 'black',
			marginLeft: 2,
			fontSize: '0.8em',
			bgcolor: stringToColorLight(name),
		},
	};
};
