import { Button, FormControl, Grid, InputLabel, NativeSelect, OutlinedInput, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import { IFieldAnswerDB } from '../../models/form.model';
import { ILanguage } from '../../models/language.model';
import { mapLanguageDispatchToProps, mapLanguageStateToProps } from '../../redux/props/language.props';
import { ISeasonGradingField } from '../../models/seasons.model';
import EntryAnswers from '../../components/submissions/components/EntryAnswers';
import Title from '../../components/Typography/Title';

type IProps = {
  language: ILanguage
}

const EntryReview: React.FC<IProps> = ({ language }) => {
  let { season, entry } = useParams<URLSearchParams | any>();
  const [form, setForm] = useState<ISeasonGradingField[]>([]);
  const [redirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`grading/${season}/form`);
      setForm(data);
		}
		fetchData();
  }, [season, entry]);

  const submitEntry = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		// const form_data = new FormData(event.currentTarget);

    // let answers: IFieldAnswerDB[] = [];

    // form_data.forEach((answer, answer_id) => {
    //   answers.push({
    //     answer: String(answer),
    //     id: Number(answer_id)
    //   })
    // });

    // await axios.put(`entry/${entry}/answers`, {
    //   answers: answers
    // });
    
    setRedirect(true);
  }

  if (redirect) {
    return <Navigate to={'/entries'} />;
  }

	return (
		<AdminLayout>
      <Grid container spacing={5}>
        <Grid item xs={12} md={7} lg={8}>
			    <Title>Entry Details</Title>
          <EntryAnswers id={Number(entry)} isAdmin={true} />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
			    <Title>Review Entry</Title>
          <Form onSubmit={submitEntry}>
            {form.map((item: ISeasonGradingField) => {
              return (
                <>
                  <Typography>
                    {item.description}
                  </Typography>
                  <FormControl 
                    key={item.id} 
                    fullWidth 
                    className="mt-2 mb-4"
                  >
                    <TextField 
                      label={`out of ${item.grading_score}`} 
                      fullWidth 
                      required={true} 
                      name={`${item.id}`} 
                      variant="outlined"
                      size="small"
                      type="number" 
                    />
                  </FormControl>
                </>
              )
            })}
            <Button 
              className="mt-4"
              variant="contained" 
              color="primary" 
              type="submit">
              Confirm and save
            </Button>
          </Form>
        </Grid>
      </Grid>
		</AdminLayout>
	);
}

export default connect(mapLanguageStateToProps, mapLanguageDispatchToProps)(EntryReview);