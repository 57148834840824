import { ISubmission } from '../../models/submissions.model';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ILanguage } from '../../models/language.model';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import { connect } from 'react-redux';
import translations from '../../data/translations';
import { AddIcon } from '../../utils/icons';
import { User } from '../../models/user.model';
import { countOccurrences, entryStatus, isStudent } from '../../utils/shared';
import PublishModal from './actions/PublishModal';

type IProps = {
	language: ILanguage;
	latest?: boolean;
	user: User;
};

const SubmissionsListView: React.FC<IProps> = ({
	language,
	latest = false,
	user,
}) => {
	const [publishDialogOpen, setPublishDialogState] = useState<boolean>(false);
	// const [deleteDialogOpen, setDeleteDialogState] = useState<boolean>(false);
	const [selectedSubmission, setSelectedSubmission] = useState<number | null>(
		null
	);
	const [entries, setEntries] = useState<ISubmission[]>([]);
	const [publishStep, setPublishStep] = useState<number>(0);
	const [seasonCost, setSeasonCost] = useState<number>(0);
	const [transactionId, setTransactionId] = useState<number>(0);
	const [seasons, setSeasons] = useState<number[]>([]);
	const [reload, setReload] = useState<boolean>(false);
	const studentLimit = 3;

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get('user');
			const user_id = data.id;

			const get_entries = await axios.get(
				`entries/${user_id}${latest ? '/latest' : ''}`
			);

			const get_seasons: number[] = get_entries.data.map(
				(item: ISubmission) => {
					return !item.draft ? item.season?.id : null;
				}
			);

			setSeasons(get_seasons);
			setEntries(get_entries.data);
			setReload(false);
		}
		fetchData();
	}, [reload]);

	const openPublishDialog =
		(id: number, cost: number, pay: boolean = false, transactionID: number) =>
		() => {
			setSelectedSubmission(id);
			setPublishDialogState(true);
			setSeasonCost(cost);
			setTransactionId(transactionID);

			if (pay) {
				setPublishStep(1);
			} else {
				setPublishStep(0);
			}
		};

	// const openDeleteDialog = (id: number) => () => {
	// 	setSelectedSubmission(id);
	// 	setDeleteDialogState(true);
	// };

	// const closeDeleteDialog = () => {
	// 	setSelectedSubmission(null);
	// 	setDeleteDialogState(false);
	// };

	// const deleteSubmission = async () => {
	// 	await axios.delete(`entries/${selectedSubmission}/delete`);
	// 	setEntries(
	// 		entries.filter((entry: ISubmission) => entry.id !== selectedSubmission)
	// 	);
	// 	toast.success('Entry deleted successfully!', ToastrSettings);
	// 	closeDeleteDialog();
	// };

	return (
		<Card sx={{ minWidth: 275 }}>
			<CardHeader
				style={{ backgroundImage: 'none' }}
				title={language.isEnglish ? 'My Entries ' : 'My Inskrywings'}
			/>
			<CardContent>
				<ToastContainer />
				<TableContainer>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell component="th">#</TableCell>
								<TableCell component="th" align="left">
									{language.isEnglish
										? translations.thSeason.eng
										: translations.thSeason.afr}
								</TableCell>
								{!isStudent(user.entry_type) && (
									<TableCell component="th" align="left">
										{language.isEnglish
											? translations.thPayRef.eng
											: translations.thPayRef.afr}
									</TableCell>
								)}

								{isStudent(user.entry_type) && (
									<TableCell component="th" align="left">
										Limit
									</TableCell>
								)}
								<TableCell component="th" align="left">
									{language.isEnglish
										? translations.thDraft.eng
										: translations.thDraft.afr}
								</TableCell>
								<TableCell component="th" align="left">
									{language.isEnglish
										? translations.thAction.eng
										: translations.thAction.afr}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{entries.length === 0 && (
								<TableRow>
									<TableCell colSpan={8} align="center">
										{language.isEnglish
											? 'There are no entries available'
											: 'Daar is geen inskrywings beskikbaar nie'}
									</TableCell>
								</TableRow>
							)}
							{entries.map((entry: ISubmission) => {
								return (
									<TableRow
										key={entry.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row" width={70}>
											{entry.id}
										</TableCell>

										<TableCell align="left">
											{language.isEnglish
												? entry.season!.title
												: entry.season!.title_afr}
										</TableCell>

										{!isStudent(user.entry_type) && (
											<TableCell align="left">
												{entry?.transaction?.payment_date && (
													<Chip label="Paid" color="primary" />
												)}

												{!entry?.transaction?.payment_date && (
													<Chip label="Unpaid" />
												)}
											</TableCell>
										)}

										{isStudent(user.entry_type) && (
											<>
												<TableCell align="left">
													<Chip
														label={`${countOccurrences(
															seasons,
															entry.season?.id
														)}/${studentLimit}`}
														color="primary"
														sx={{
															' .MuiChip-label': {
																minWidth: 0,
																display: 'inline-flex',
																paddingRight: '16px',
																paddingLeft: '16px',
															},
														}}
													/>
												</TableCell>
											</>
										)}

										<TableCell align="left">
											{entryStatus(entry.draft)}
										</TableCell>
										<TableCell align="right" width={1}>
											<Box display="flex" gap="8px" justifyContent="flex-end">
												{isStudent(user.entry_type) && entry.draft && (
													<Button
														variant="contained"
														size="small"
														onClick={openPublishDialog(entry.id, 0, false, 0)}
														color="secondary"
													>
														Publish
													</Button>
												)}

												{(entry.draft || !entry?.transaction?.payment_date) &&
													!isStudent(user.entry_type) && (
														<Button
															variant="contained"
															size="small"
															onClick={openPublishDialog(
																entry.id,
																entry.season?.cost as number,
																!entry.draft &&
																	!entry?.transaction?.payment_date
																	? true
																	: false,
																entry?.transaction?.id
																	? entry?.transaction?.id
																	: 0
															)}
															color="secondary"
														>
															{entry.draft && (
																<>
																	<span className="icon icon-publish"></span>
																	Publish
																</>
															)}

															{!entry.draft &&
																!entry?.transaction?.payment_date && (
																	<>
																		<span className="icon icon-payments"></span>
																		Pay
																	</>
																)}
														</Button>
													)}

												<Button
													variant="contained"
													size="small"
													component={Link}
													to={`/entries/${entry.id}/view`}
													color="secondary"
												>
													<span className="icon icon-readmore"></span>
													View
												</Button>
											</Box>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>

				<PublishModal
					entryID={selectedSubmission}
					cost={seasonCost}
					open={publishDialogOpen}
					step={publishStep}
					transactionID={transactionId}
					onCloseModal={(closed: boolean, reload: boolean) => {
						setPublishDialogState(!closed);
						setReload(reload);
					}}
				/>
			</CardContent>
			<CardActions>
				<Button
					variant="contained"
					size="small"
					component={Link}
					to="/entries/create"
					color="primary"
				>
					{AddIcon()}
					{language.isEnglish ? 'Create New Entry' : 'Skryf in!'}
				</Button>
			</CardActions>
		</Card>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(SubmissionsListView);
