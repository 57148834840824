import {
	Box,
	Button,
	Grid,
	IconButton,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Tooltip,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import { IRoleAdvanced } from '../../models/role.model';
import Table from '@mui/material/Table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IUser } from '../../models/user.model';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { formatDate, formatDateTime } from '../../functions/Global';

interface IProps {}

const Roles: React.FC<IProps> = () => {
	const [roles, setRoles] = useState<IRoleAdvanced[]>([]);
	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	useEffect(() => {
		async function getData() {
			const { data } = await axios.get('roles/detailed');
			setRoles(data);
		}
		getData();
	}, []);

	const deleteRole = (id: number) => async (event: any) => {
		if (window.confirm('Are you sure you want to delete this role?')) {
			await axios.delete(`roles/${id}`);
			setRoles(roles.filter((role: IRoleAdvanced) => role.id !== id));
		}
	};

	return (
		<AdminLayout>
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<Typography variant="h3" component="h2">
						User Roles
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Box
						sx={{
							marginBottom: '1.5em',
							float: 'right',
						}}
					>
						<Button variant="contained" component={Link} to="/roles/create">
							Create New User Role
						</Button>
					</Box>
				</Grid>
			</Grid>
			{roles.map((role: IRoleAdvanced) => {
				return (
					<Accordion
						style={{ boxShadow: 'none' }}
						key={role.id}
						expanded={expanded === `r${role.id}`}
						onChange={handleChange(`r${role.id}`)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							className="align-items-center"
						>
							<Typography>{role.name}</Typography>
							<Typography className="ms-2" sx={{ color: 'text.secondary' }}>
								({role.users.length})
							</Typography>
							<div className="ms-auto">
								{role.not_customisable && (
									<Tooltip title="View role" placement="top" arrow>
										<IconButton
											size="small"
											color="secondary"
											component={Link}
											to={`/roles/${role.id}/edit`}
										>
											<ReadMoreIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								)}
								{!role.not_customisable && (
									<>
										<Tooltip title="Delete role" placement="top" arrow>
											<IconButton
												size="small"
												color="secondary"
												onClick={deleteRole(role.id)}
											>
												<DeleteIcon fontSize="inherit" />
											</IconButton>
										</Tooltip>
										<Tooltip title="Update role" placement="top" arrow>
											<IconButton
												size="small"
												color="secondary"
												component={Link}
												to={`/roles/${role.id}/edit`}
											>
												<EditIcon fontSize="inherit" />
											</IconButton>
										</Tooltip>
									</>
								)}
							</div>
						</AccordionSummary>
						<AccordionDetails>
							{role.users.length === 0 && (
								<Typography sx={{ color: 'text.secondary' }}>
									This role does not have any users assigned to it
								</Typography>
							)}
							{role.users.length > 0 && (
								<TableContainer>
									<Table sx={{ minWidth: 650 }} size="small">
										<TableHead>
											<TableRow>
												<TableCell component="th">#</TableCell>
												<TableCell component="th" align="left">
													Name
												</TableCell>
												<TableCell component="th" align="left">
													Email
												</TableCell>
												<TableCell component="th" align="left">
													Registered on
												</TableCell>
												<TableCell component="th" align="left">
													Last Login
												</TableCell>
												<TableCell component="th" align="left">
													Actions
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{role.users.map((user: IUser) => {
												return (
													<TableRow
														key={user.id}
														sx={{
															'&:last-child td, &:last-child th': { border: 0 },
														}}
													>
														<TableCell component="th" scope="row" width={70}>
															{user.id}
														</TableCell>
														<TableCell align="left">
															{`${user.first_name} ${user.last_name}`}
														</TableCell>
														<TableCell align="left">{user.email}</TableCell>
														<TableCell align="left">
															{formatDateTime(new Date(user.registered_date))}
														</TableCell>
														<TableCell align="left">
															{user.logged_in_date &&
																formatDateTime(new Date(user.logged_in_date))}
															{!user.logged_in_date && <>n/a</>}
														</TableCell>
														<TableCell width={70} align="right">
															<Tooltip
																title="Update User"
																placement="top"
																arrow
															>
																<IconButton
																	color="secondary"
																	component={Link}
																	to={`/users/${user.id}/edit`}
																	size="small"
																>
																	<EditIcon fontSize="inherit" />
																</IconButton>
															</Tooltip>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
							)}
						</AccordionDetails>
					</Accordion>
				);
			})}
		</AdminLayout>
	);
};

export default Roles;
