import { Breadcrumbs, Link, Typography } from '@mui/material';
import React from 'react';

interface IProps {
	id: any;
	title: string;
	page: string;
}

const SeasonBreadcrumbs: React.FC<IProps> = ({ id, title, page }) => {
	return (
		<Breadcrumbs aria-label="breadcrumb" className="mb-3">
			<Link underline="hover" color="inherit" href="/seasons">
				Seasons
			</Link>
			<Link underline="hover" color="inherit" href={`/seasons/${id}/edit`}>
				{title}
			</Link>
			<Typography color="text.primary">Grading Categories</Typography>
		</Breadcrumbs>
	);
};

export default SeasonBreadcrumbs;
