import { useState } from 'react';
import { TabContent } from '../../../models/tab.model';
import EntryAnswers from './EntryAnswers';
import EntryComments from './EntryComments';
import EntryReviews from './EntryReviews';
import { Box, Tab, Typography } from '@mui/material';
import HorizontalTabs from '../../HorizontalTabs';
import { TabPanelHorisontal, tabProps } from '../../../functions/Tabs';
import EntryReviewers from './EntryReviewers';

type IProps = {
	id: number;
	isAdmin: boolean;
	reload?: boolean;
	seasonId: number;
};

const EntryTabs: React.FC<IProps> = ({ id, isAdmin, reload, seasonId }) => {
	const [activeTab, setActiveTab] = useState<number>(0);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	const tabs: TabContent[] = [
		{
			title: 'Answers',
			content: <EntryAnswers reload={reload} id={id} isAdmin={isAdmin} />,
			onlyAdmin: false,
		},
		{
			title: 'Comments',
			content: <EntryComments id={id} isAdmin={isAdmin} />,
			onlyAdmin: true,
		},
		{
			title: 'Reviews',
			content: <EntryReviews id={id} />,
			onlyAdmin: true,
			allowAuditor: true,
		},
		{
			title: 'Assign Reviewers',
			content: <EntryReviewers seasonId={seasonId} id={id} />,
			onlyAdmin: true,
			allowAuditor: true,
		},
	];

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<HorizontalTabs active={activeTab} onChange={handleTabChange}>
					{tabs.map((tab, index) => {
						const show = (isAdmin && tab.onlyAdmin) || !tab.onlyAdmin;

						if (show) {
							return <Tab key={index} label={tab.title} {...tabProps(index)} />;
						}
					})}
				</HorizontalTabs>
			</Box>
			{tabs.map((tab, index) => {
				const show = (isAdmin && tab.onlyAdmin) || !tab.onlyAdmin;

				if (show) {
					return (
						<TabPanelHorisontal
							key={`entry-tab-${index}`}
							value={activeTab}
							index={index}
						>
							{tab.content}
						</TabPanelHorisontal>
					);
				}
			})}
		</>
	);
};

export default EntryTabs;
