import { Box, Button } from '@mui/material';
import * as React from 'react';
import { connect } from 'react-redux';
import { ILanguage } from '../../models/language.model';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import { NavLink } from 'react-router-dom';
import translations from '../../data/translations';
import MobileMenu from './MobileMenu';
import { HeaderNavItem } from '../../models/shared.model';

interface IProps {
	language: ILanguage;
}

const HeaderNav: React.FC<IProps> = ({ language }) => {
	const [openLogin, setOpenLogin] = React.useState<boolean>(false);

	const menu_items: HeaderNavItem[] = [
		{
			url: '/',
			label: language.isEnglish
				? translations.competition.eng
				: translations.competition.afr,
			icon: 'movie',
		},
		{
			url: '/terms',
			label: language.isEnglish
				? translations.terms.eng
				: translations.terms.afr,
			icon: 'mui',
		},
	];

	const onOpenLoginModal = () => {
		setOpenLogin(true);
	};

	const closeLogin = (close: boolean) => {
		setOpenLogin(!close);
	};

	return (
		<>
			<Box
				sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex', gap: '12px' } }}
			>
				{menu_items.map((page, index) => (
					<Button
						key={index}
						to={page.url}
						sx={{ my: 2, display: 'block' }}
						variant="text"
						color="secondary"
						component={NavLink}
					>
						<span className={`icon icon-${page.icon}`}></span>
						{page.label}
					</Button>
				))}
			</Box>

			<MobileMenu menu={menu_items} />
		</>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(HeaderNav);
