import React, { useCallback, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { connect } from 'react-redux';
import { ILanguage } from '../../models/language.model';
import translations from '../../data/translations';
import { Alert, Typography } from '@mui/material';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Links from './Links';

type IProps = {
	language: ILanguage;
	toggleSignUp: (open: boolean) => void;
	// toggleForgotPW: (open: boolean) => void;
};

const ResetPassword: React.FC<IProps> = ({
	language,
	toggleSignUp,
	// toggleForgotPW,
}) => {
	const [errorResponse, setErrorResponse] = useState(null);
	const [success, setSuccess] = useState<boolean>(false);

	// Google reCAPTCHA
	const [token, setToken] = useState<string>('');
	const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);

	const onVerify = useCallback(
		(token: string): void => {
			setToken(token);
		},
		[token]
	);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (token) {
			const data = new FormData(event.currentTarget);

			await axios
				.post('reset-password', {
					email: data.get('email'),
				})
				.then((response) => {
					setRefreshReCaptcha((r) => !r);
					setSuccess(true);
				})
				.catch((error) => {
					const response = JSON.parse(error.request.response);
					setErrorResponse(response.message);
				});
		}
	};

	let paragraph1, paragraph2;
	if (language.isEnglish) {
		paragraph1 =
			'Reset your password by submitting your email address linked to your account.';
		paragraph2 =
			'We will send you an email with a link to choose a new password.';
	} else {
		paragraph1 =
			'Stel jou wagwoord terug deur jou e-posadres wat aan jou rekening gekoppel is, in te dien.';
		paragraph2 = `Ons sal vir jou 'n e-pos stuur met 'n skakel om 'n nuwe wagwoord te kies.`;
	}

	return (
		<>
			<Box
				component="form"
				noValidate
				onSubmit={handleSubmit}
				sx={{ mt: 'var(--px)' }}
			>
				<GoogleReCaptcha
					onVerify={onVerify}
					refreshReCaptcha={refreshReCaptcha}
				/>
				{success && (
					<Alert severity="success" className="mb-3">
						{language.isEnglish
							? 'Password reset link sent successfully'
							: 'Wagwoordterugstelskakel is suksesvol gestuur'}
					</Alert>
				)}
				{errorResponse !== null && (
					<Alert severity="warning" className="mb-3">
						{errorResponse}
					</Alert>
				)}
				<Typography variant="bodyL" color="var(--grey-800)" component="p">
					<strong>{paragraph1}</strong>
					<br />
					{paragraph2}
				</Typography>
				<TextField
					margin="normal"
					required
					fullWidth
					id="email"
					label={
						language.isEnglish ? translations.email.eng : translations.email.afr
					}
					name="email"
					autoComplete="email"
					style={{ marginBottom: '5px' }}
					autoFocus
				/>
				<Links toggleSignUp={toggleSignUp} />
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 'var(--spacer)' }}
				>
					{language.isEnglish
						? translations.resetPw.eng
						: translations.resetPw.afr}
				</Button>
			</Box>
		</>
	);
};

export default connect(mapLanguageStateToProps)(ResetPassword);
