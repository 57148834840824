import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { ITransactTableData } from '../../models/transactions.model';
import { connect } from 'react-redux';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { ILanguage } from '../../models/language.model';
import { RenderViewEntryButton } from './components/ViewEntryButton';
import { DataTableSizeOptions } from '../../functions/DataTable';
import DataTable from '../../data/data-table';
import { Card, Grid, Typography, colors } from '@mui/material';
import translations from '../../data/translations';
import Currency from '../../components/Currency';

interface TableRow {
	id: number;
	payment_date: string;
	payment_reference: string;
	amount: number;
	user_name: string;
	user_email: string;
	payment_override: boolean;
	season: string;
	actions: any;
}

interface IProps {
	language: ILanguage;
	id?: number | string | null;
}

const PaymentsPaid: React.FC<IProps> = ({ language, id }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [rows, setRows] = useState<TableRow[]>([]);
	const [paymentOverrides, setPaymentOverrides] = useState<number>(0);
	const [paymentsReceived, setPaymentsReceived] = useState<number>(0);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			await axios.get(`transactions`).then((payments) => {
				const data: TableRow[] = payments.data.transactions.map(
					(item: ITransactTableData) => {
						return {
							id: item.id,
							payment_date: item.transaction
								? item.transaction.payment_date
								: '—',
							payment_reference: item.transaction
								? item.transaction.payment_reference
								: '—',
							amount: item.transaction
								? `R${item.transaction.amount.toFixed(2)}`
								: '—',
							user_name: `${item.user.first_name} ${item.user.last_name}`,
							user_email: item.user.email,
							user_contact_number: item.user.contact_number,
							payment_override: item.payment_override ? 'Yes' : '',
							season: language.isEnglish
								? item.season.title
								: item.season.title_afr,
							actions: item.id,
						};
					}
				);

				setRows(data);
				setLoading(false);
				setPaymentOverrides(payments.data.paymentOverrides);
				setPaymentsReceived(payments.data.totalPaymentsReceived);
			});
		}
		fetchData();
	}, [id]);

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			width: 25,
		},
		{
			field: 'payment_reference',
			headerName: 'Reference',
			flex: 1,
		},
		{
			field: 'amount',
			headerName: 'Amount',
			flex: 1,
		},
		{
			field: 'user_name',
			headerName: 'Name',
			flex: 1,
		},
		{
			field: 'user_email',
			headerName: 'Email',
			flex: 1,
		},
		{
			field: 'user_contact_number',
			headerName: 'Contact Number',
			flex: 1,
		},
		{
			field: 'payment_override',
			headerName: 'Payment Override',
			flex: 1,
		},
		{
			field: 'season',
			headerName: 'Season',
			flex: 1,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			renderCell: RenderViewEntryButton,
		},
	];

	return (
		<>
			{/* add loading circle here */}
			{!loading && rows && (
				<>
					<Grid marginBottom={4} container gap={2} justifyContent="flex-end">
						<Grid item xs={12} md={4} lg={3}>
							<Card
								sx={{
									padding: 2,
									backgroundColor: colors['lime'][500],
								}}
							>
								<Typography component="h2" variant="h6" gutterBottom>
									{language.isEnglish
										? translations.thPayments.eng
										: translations.thPayRef.afr}
								</Typography>
								<Typography component="p" variant="h3" marginBottom={0}>
									<Currency price={paymentsReceived} />
								</Typography>
							</Card>
						</Grid>
						<Grid item xs={12} md={4} lg={3}>
							<Card
								sx={{
									padding: 2,
									backgroundColor: colors['cyan'][500],
								}}
							>
								<Typography component="h2" variant="h6" gutterBottom>
									Total Payments Overridden
								</Typography>
								<Typography component="p" variant="h3" marginBottom={0}>
									{paymentOverrides}
								</Typography>
							</Card>
						</Grid>
					</Grid>
					<DataGrid
						autoHeight
						rows={rows}
						columns={columns}
						initialState={{ pagination: DataTable.pagination }}
						pageSizeOptions={DataTableSizeOptions(rows)}
						slots={{
							toolbar: GridToolbar,
						}}
						componentsProps={{
							toolbar: {
								showQuickFilter: true,
								quickFilterProps: { debounceMs: 500 },
							},
						}}
					/>
				</>
			)}
		</>
	);
};

export default connect(mapLanguageStateToProps)(PaymentsPaid);
