import { Box, Button, Container, Paper, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import StepperNav from '../../components/transactions/StepperNav';

interface IProps {}

interface URLParams {
	id: any;
	transactionid: any;
}

const ConfirmSuccess: React.FC<IProps> = () => {
	const [payment_reference, setPaymentReference] = useState<string>('');
	const { id, transactionid } = useParams<URLParams | any>();

	useEffect(() => {
		async function fetchData() {
			const get_transaction = await axios.get(`transaction/${transactionid}`);
			setPaymentReference(get_transaction.data.payment_reference);
		}
		fetchData();
	}, [id]);

	return (
		<AdminLayout>
			<Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
				<Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
					<StepperNav active={2} />

					<Typography variant="h3" className="mb-3">
						You have successfully paid for your entry
					</Typography>

					<Typography variant="body1" marginBottom={2}>
						Your payment reference is {payment_reference}
					</Typography>

					<Box
						component="div"
						sx={{ display: 'flex', justifyContent: 'flex-end' }}
					>
						<Button
							variant="contained"
							sx={{ mt: 3, ml: 1 }}
							component={Link}
							to={`/dashboard`}
						>
							Dashboard
						</Button>
					</Box>
				</Paper>
			</Container>
		</AdminLayout>
	);
};

export default ConfirmSuccess;
