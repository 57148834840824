import React from 'react';

interface IProps {
	className?: string;
	icon:
		| 'edit'
		| 'camera'
		| 'close'
		| 'dashboard'
		| 'roles'
		| 'seasons'
		| 'language'
		| 'users'
		| 'payments'
		| 'chevron-right'
		| 'chevron-left'
		| 'lock'
		| 'profile'
		| 'readmore'
		| 'entries'
		| 'submissions'
		| 'mui'
		| 'menu-open'
		| 'logout'
		| 'settings'
		| 'external'
		| 'publish'
		| 'reviewers'
		| 'reviews'
		| 'facebook'
		| 'instagram'
		| 'twitterx'
		| 'movie'
		| 'add'
		| 'star';
}

const Icon: React.FC<IProps> = ({ className, icon }) => {
	return <span className={`icon icon-${icon} ${className}`}></span>;
};

export default Icon;
