import { Button, DialogContentText } from '@mui/material';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';

interface IProps {
	transactionId: number;
	onDone: (done: boolean, retry: boolean) => void;
	transactionStatus?: string;
}

const Step3ReviewOrder: FC<IProps> = ({
	transactionId,
	onDone,
	transactionStatus,
}) => {
	const [paymentReference, setPaymentReference] = useState<string>('');

	useEffect(() => {
		async function fetchData() {
			if (transactionId) {
				const get_transaction = await axios.get(`transaction/${transactionId}`);
				setPaymentReference(get_transaction.data.payment_reference);
			}
		}
		fetchData();
	}, [transactionId]);

	return (
		<>
			<DialogContentText
				sx={{ marginTop: 'var(--spacer)', textAlign: 'center' }}
			>
				{transactionStatus !== 'cancelled' && (
					<>
						You have successfully paid for your entry.
						<br />
						Your payment reference is:
						<br />
						<br />
						<strong>{paymentReference}</strong>
					</>
				)}

				{transactionStatus === 'cancelled' && (
					<>Your payment was unsuccessful.</>
				)}

				<Button
					onClick={() =>
						onDone(
							transactionStatus !== 'cancelled' ? true : false,
							transactionStatus !== 'cancelled' ? false : true
						)
					}
					variant="contained"
					fullWidth
					sx={{ marginTop: 'var(--px)' }}
				>
					{transactionStatus !== 'cancelled' ? 'Done' : 'Make Payment'}
				</Button>
			</DialogContentText>
		</>
	);
};

export default Step3ReviewOrder;
