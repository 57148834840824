import { Box, Grid, Typography, Button } from '@mui/material';
import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import { IRole } from '../../models/role.model';
import { Link } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';

interface IProps {}

interface URLParams {
	id: number;
}

const UserEdit: React.FC<IProps> = () => {
	let { id } = useParams<URLParams | any>();
	const [first_name, setFirstName] = useState<string>('');
	const [last_name, setLastName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [contact_number, setContactNumber] = useState<string>('');
	const [based_in, setBasedIn] = useState<string>('');
	const [role_id, setRoleId] = useState<string>('');
	const [roles, setRoles] = useState<IRole[]>([]);
	const [redirect, setRedirect] = useState<boolean>(false);
	const [super_admin, setSuperAdmin] = useState<boolean>(false);

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get(`roles`);
			setRoles(data);

			if (id) {
				const getUser = await axios.get(`users/${id}`);
				const user = getUser.data;

				setFirstName(user.first_name);
				setLastName(user.last_name);
				setEmail(user.email);
				setRoleId(user.role.id);
				setSuperAdmin(user.super_admin);
				setContactNumber(user.contact_number);
				setBasedIn(user.based_in);
			}
		}
		fetchData();
	}, [id]);

	const submit = async (e: SyntheticEvent) => {
		e.preventDefault();
		if (id) {
			await axios.put(`users/${id}`, {
				first_name,
				last_name,
				email,
				role_id,
				based_in,
				contact_number,
			});
		} else {
			await axios.post('users', {
				first_name,
				last_name,
				email,
				role_id,
				based_in,
				contact_number,
			});
		}

		setRedirect(true);
	};

	if (redirect) {
		return <Navigate to={'/users'} />;
	}

	return (
		<AdminLayout>
			<Form onSubmit={submit}>
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<Typography variant="h3" component="h2">
							{id ? 'Edit' : 'Create New'} User
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Box
							sx={{
								marginBottom: '1.5em',
								float: 'right',
							}}
						>
							<Button
								className="me-3"
								color="secondary"
								variant="outlined"
								component={Link}
								to="/users"
							>
								{id ? 'Back to list' : 'Cancel'}
							</Button>
							<Button
								className=""
								variant="contained"
								color="success"
								type="submit"
							>
								<SaveIcon fontSize="small" className="me-2" />
								Save Details
							</Button>
						</Box>
					</Grid>
				</Grid>

				<Form.Group className="mb-3" controlId="formFirstName">
					<Form.Label>First Name</Form.Label>
					<Form.Control
						type="text"
						placeholder="First Name"
						defaultValue={first_name}
						onChange={(e) => setFirstName(e.target.value)}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="formLastName">
					<Form.Label>Last Name</Form.Label>
					<Form.Control
						type="text"
						placeholder="Last Name"
						defaultValue={last_name}
						onChange={(e) => setLastName(e.target.value)}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="formBasicEmail">
					<Form.Label>Email address</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter email"
						defaultValue={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Form.Text className="text-muted">
						We'll never share your email with anyone else.
					</Form.Text>
				</Form.Group>
				<Form.Group className="mb-3" controlId="formContactNumber">
					<Form.Label>Contact Number</Form.Label>
					<Form.Control
						type="text"
						placeholder="Enter contact number"
						defaultValue={contact_number}
						onChange={(e) => setContactNumber(e.target.value)}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="formBasedIn">
					<Form.Label>Based in (location)</Form.Label>
					<Form.Control
						type="text"
						placeholder="Enter location"
						defaultValue={based_in}
						onChange={(e) => setBasedIn(e.target.value)}
					/>
				</Form.Group>
				<Form.Group className="mb-4" controlId="formRole">
					<Form.Label>Role</Form.Label>
					<Form.Select
						value={role_id}
						onChange={(e) => setRoleId(e.target.value)}
						disabled={super_admin}
					>
						<option value="0">...</option>
						{roles.map((role: IRole) => {
							return (
								<option value={role.id} key={role.id}>
									{role.name}
								</option>
							);
						})}
					</Form.Select>
				</Form.Group>
			</Form>
		</AdminLayout>
	);
};

export default UserEdit;
