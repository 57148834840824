import React, { useState } from 'react';
import logo from '../../../assets/logo/logo.png';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import AppBarLanguage from '../bar/AppBarLanguage';
import NavItems from './NavItems';
import { Box, Link } from '@mui/material';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { User } from '../../../models/user.model';
import { mapStateToProps } from '../../../redux/props/user.props';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import NavLogout from './NavLogout';

type IProps = { user: User };

const AdminNav: React.FC<IProps> = ({ user }) => {
	const [redirect, setRedirect] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(true);

	const cookies = new Cookies();
	const drawerWidth: number = 240;

	interface AppBarProps extends MuiAppBarProps {
		open?: boolean;
	}

	const AppBar = styled(MuiAppBar, {
		shouldForwardProp: (prop) => prop !== 'open',
	})<AppBarProps>(({ theme, open }) => ({
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}));

	const Drawer = styled(MuiDrawer, {
		shouldForwardProp: (prop) => prop !== 'open',
	})(({ theme, open }) => ({
		'& .MuiDrawer-paper': {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: 'border-box',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: theme.spacing(7),
				[theme.breakpoints.up('sm')]: {
					width: theme.spacing(9),
				},
			}),
		},
	}));

	// const toggleDrawer = () => {
	// 	setOpen(!open);
	// };

	const logout = async () => {
		await axios.post('logout', {}).then((response) => {
			cookies.remove('user');
			setRedirect(true);
		});
	};

	if (redirect) {
		return <Navigate to={'/'} />;
	}

	return (
		<>
			<Drawer
				variant="permanent"
				open={open}
				sx={{
					display: { xs: 'none', md: 'block' },
				}}
			>
				<Toolbar
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						px: [1],
						ml: 1,
					}}
					// component="a"
					// href="/dashboard"
				>
					<img src={logo} height="35" alt="Silwerskerm Fees" />
					{/* <IconButton onClick={toggleDrawer}>
						<ChevronLeftIcon />
					</IconButton> */}
				</Toolbar>
				<List component="nav">
					<NavItems user={user} />
				</List>
				<NavLogout />
			</Drawer>
		</>
	);
};

export default connect(mapStateToProps)(AdminNav);
