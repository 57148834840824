import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React from 'react';
import { DataTableSizeOptions } from '../functions/DataTable';
import DataTable from '../data/data-table';
import { styled } from '@mui/material/styles';

const Table: React.FC<any> = ({ rows, columns, ...props }) => {
	return (
		<DataGrid
			{...props}
			autoHeight
			getRowHeight={() => 'auto'}
			rows={rows}
			columns={columns}
			pageSizeOptions={DataTableSizeOptions(rows)}
			initialState={{
				pagination: DataTable.pagination,
			}}
			slots={{
				toolbar: GridToolbar,
				// pagination: false,
			}}
			componentsProps={{
				toolbar: {
					showQuickFilter: true,
					quickFilterProps: {
						debounceMs: 500,
					},
				},
			}}
			columnVisibilityModel={
				{
					// id: false,
				}
			}
			sx={{
				color: '#000',
			}}
		/>
	);
};

export default styled(Table)({});
