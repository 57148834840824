import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { connect } from 'react-redux';
import { ILanguage } from '../../models/language.model';
import translations from '../../data/translations';
import {
	Alert,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from '@mui/material';
import Cookies from 'universal-cookie';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Links from './Links';

type IProps = {
	language: ILanguage;
	toggleSignUp: (open: boolean) => void;
	toggleForgotPW: (open: boolean) => void;
};

const SignIn: React.FC<IProps> = ({
	language,
	toggleSignUp,
	toggleForgotPW,
}) => {
	const [redirect, setRedirect] = useState(false);
	const [errorResponse, setErrorResponse] = useState(null);
	const [showPassword, setShowPassword] = React.useState<boolean>(false);

	const cookies = new Cookies();

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	// Google reCAPTCHA
	const [token, setToken] = useState<string>('');
	const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);

	const onVerify = useCallback(
		(token: string): void => {
			setToken(token);
		},
		[token]
	);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (token) {
			const data = new FormData(event.currentTarget);

			await axios
				.post('login', {
					email: data.get('email'),
					password: data.get('password'),
				})
				.then((response) => {
					setRedirect(true);
					setRefreshReCaptcha((r) => !r);
				})
				.catch((error) => {
					const response = JSON.parse(error.request.response);
					setErrorResponse(response.message);
				});
		}
	};

	if (redirect) {
		return <Navigate to={'/dashboard'} />;
	}

	return (
		<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
			<GoogleReCaptcha
				onVerify={onVerify}
				refreshReCaptcha={refreshReCaptcha}
			/>
			{errorResponse !== null && (
				<Alert severity="warning" className="mb-3">
					{errorResponse}
				</Alert>
			)}
			<TextField
				margin="normal"
				required
				fullWidth
				id="email"
				label={
					language.isEnglish ? translations.email.eng : translations.email.afr
				}
				name="email"
				autoComplete="email"
				autoFocus
			/>
			<FormControl
				required
				margin="normal"
				fullWidth={true}
				variant="outlined"
				className="mt-2"
			>
				<InputLabel htmlFor="outlined-password">
					{language.isEnglish
						? translations.password.eng
						: translations.password.afr}
				</InputLabel>
				<OutlinedInput
					id="outlined-password"
					type={showPassword ? 'text' : 'password'}
					endAdornment={
						<InputAdornment position="end">
							<IconButton onClick={handleClickShowPassword} edge="end">
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					label={
						language.isEnglish
							? translations.password.eng
							: translations.password.afr
					}
					name="password"
				/>
			</FormControl>

			<Grid
				container
				alignItems="center"
				sx={{ marginTop: 'var(--spacer)', marginBottom: 'var(--px)' }}
			>
				<Grid item xs>
					<FormControlLabel
						control={<Checkbox value="remember" color="primary" />}
						label={language.isEnglish ? 'Remember me' : 'Onthou my'}
					/>
				</Grid>
				<Grid
					item
					xs
					textAlign="right"
					display="flex"
					justifyContent="flex-end"
				>
					<Link
						href="/reset-password"
						variant="bodyL"
						onClick={(e) => {
							e.preventDefault();
							toggleForgotPW(true);
						}}
					>
						{language.isEnglish ? 'Forgot password' : 'Wagwoord vergeet'}?
					</Link>
				</Grid>
			</Grid>

			<Links toggleSignUp={toggleSignUp} />

			<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
				{language.isEnglish ? translations.signIn.eng : translations.signIn.afr}
			</Button>
		</Box>
	);
};

export default connect(mapLanguageStateToProps)(SignIn);
