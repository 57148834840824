import { Chip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

interface IParams {
	row: {
		reviewed: boolean;
	};
}

export const RenderEntryStatus = (params: IParams) => {
	return (
		<>
			{!params.row.reviewed && (
				<Chip size="small" icon={<RadioButtonUncheckedIcon />} label="To do" />
			)}
			{params.row.reviewed && (
				<Chip
					size="small"
					icon={<CheckCircleOutlineIcon />}
					label="Done"
					color="success"
				/>
			)}
		</>
	);
};
