import React from 'react';
import { useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import EntryDetailView from '../../components/submissions/EntryDetailView';
import { User } from '../../models/user.model';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/props/user.props';

type IProps = {
	user: User;
};

interface URLParams {
	id: any;
}

const Entry: React.FC<IProps> = ({ user }) => {
	const { id } = useParams<URLParams | any>();

	return (
		<AdminLayout>
			<EntryDetailView user={user} id={Number(id)} isAdmin={false} />
		</AdminLayout>
	);
};

export default connect(mapStateToProps)(Entry);
