import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import AuthLayout from '../../components/layouts/Auth';
import { Navigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import { ILanguage } from '../../models/language.model';
import translations from '../../data/translations';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { connect } from 'react-redux';
import { Alert } from '@mui/material';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

interface IProps {
	language: ILanguage;
}

interface URLParams {
	token: number;
}

const ResetPassword: React.FC<IProps> = ({ language }) => {
	let invalid = false;
	const { id, token } = useParams<URLParams | any>();
	const [redirect, setRedirect] = useState<boolean>(false);
	const [errorResponse, setErrorResponse] = useState<string>('');
	const [isInvalid, setInvalid] = useState<boolean>(false);

	// Google reCAPTCHA
	const [googleToken, setToken] = useState<string>('');
	const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);

	const onVerify = useCallback(
		(token: string): void => {
			setToken(token);
		},
		[googleToken]
	);

	if (!token || !id) {
		invalid = true;
	}

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get(`reset-password/${id}/${token}`);

			if (!data) {
				setInvalid(true);
			}
		}
		fetchData();
	}, [token]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (googleToken && token && id) {
			const data = new FormData(event.currentTarget);

			await axios
				.put(`reset-password/${id}/${token}`, {
					password: data.get('password'),
					password_confirm: data.get('passwordConfirm'),
				})
				.then((response) => {
					setRedirect(true);
					setRefreshReCaptcha(true);
				})
				.catch((error) => {
					const response = JSON.parse(error.request.response);
					setErrorResponse(response.message);
				});
		}
	};

	if (redirect) {
		return <Navigate to={'/'} />;
	}

	return (
		<AuthLayout
			title={
				language.isEnglish ? translations.resetPw.eng : translations.resetPw.afr
			}
		>
			<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
				<GoogleReCaptcha
					onVerify={onVerify}
					refreshReCaptcha={refreshReCaptcha}
				/>
				{errorResponse !== '' && (
					<Alert severity="warning" className="mb-3">
						{errorResponse}
					</Alert>
				)}

				{invalid ||
					(isInvalid && (
						<>
							<Alert severity="warning" className="mb-3">
								Your token is invalid or has expired
							</Alert>
							<Grid container justifyContent="center">
								<Grid item>
									<Link
										component={NavLink}
										to="/reset-password"
										variant="body2"
									>
										Click here to get a new token
									</Link>
								</Grid>
							</Grid>
						</>
					))}

				{!invalid && !isInvalid && (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12}>
								<TextField
									required
									fullWidth
									name="password"
									label={
										language.isEnglish
											? translations.password.eng
											: translations.password.afr
									}
									type="password"
									id="password"
									autoComplete="new-password"
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									required
									fullWidth
									name="passwordConfirm"
									label={
										language.isEnglish
											? `${translations.confirm.eng} ${translations.password.eng}`
											: `${translations.confirm.afr} ${translations.password.afr}`
									}
									type="password"
									id="passwordConfirm"
									autoComplete="new-password-confirm"
								/>
							</Grid>
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							{language.isEnglish
								? translations.resetPw.eng
								: translations.resetPw.afr}
						</Button>
						<Grid container justifyContent="center">
							<Grid item>
								<Link component={NavLink} to="/sign-in" variant="body2">
									{language.isEnglish
										? `Already have an account? ${translations.signIn.eng}`
										: `Reeds 'n rekening? ${translations.signIn.afr}`}
								</Link>
							</Grid>
						</Grid>
					</>
				)}
			</Box>
		</AuthLayout>
	);
};

export default connect(mapLanguageStateToProps)(ResetPassword);
