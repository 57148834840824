import React, { useEffect, useState } from "react";

interface IProps {
  id: number;
}

const EntryReviews: React.FC<IProps> = ({ id }) => {
  const [loading, setLoading] = useState<boolean>(false);
  
  useEffect(() => {
    async function fetchData() {
      // setLoading(true);

			// await axios.get(`reviewer-entries/${id}/admin`).then(items => {
			// 	setLoading(false);
			// });
		}
		fetchData();
  }, [id]);

  return (
    <>
      coming soon
    </>
  );
}

export default EntryReviews;