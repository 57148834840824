import ButtonTooltip from "../../../components/ButtonTooltip";
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';

export const RenderViewEntryButton = (params: any) => {
	return (
		<ButtonTooltip text="View Entry">
			<IconButton 
				color="secondary" 
				component={Link} 
				to={`/submissions/${params.row.id}`}>
				<ReadMoreIcon fontSize='small' />
			</IconButton>
		</ButtonTooltip>
	)
}