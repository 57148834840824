import Languages from '../../data/languages';

// Set "Afrikaans" as default language
if (localStorage.getItem('language') === null) {
	localStorage.setItem('language', Languages[1]);
}

const getLanguage = localStorage.getItem('language');
const isEnglish = getLanguage === Languages[1] ? false : true;

const initialState = {
	active: getLanguage ? getLanguage : Languages[1], // set Afrikaans as default language
	isEnglish: isEnglish,
};

export const setLanguageReducer = (
	state = initialState,
	action: { type: string; active: string; isEnglish: boolean }
) => {
	switch (action.type) {
		case 'SET_LANGUAGE':
			return {
				...state,
				...action,
			};
		default:
			return state;
	}
};
