import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import AdminSubmissionsListView from '../submissions/AdminListView';
import { ILanguage } from '../../models/language.model';
import { connect } from 'react-redux';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import { ISeasonID } from '../../models/submissions.model';
import { ISeason } from '../../models/seasons.model';

type IProps = {
	language: ILanguage;
};

const AdminDashboard: React.FC<IProps> = ({ language }) => {
	const [seasons, setSeasons] = useState<ISeason[]>([]);

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get('seasons/active/keys');
			setSeasons(data);
		}
		fetchData();
	}, []);

	return (
		<Card sx={{ minWidth: 275 }}>
			<CardHeader style={{ backgroundImage: 'none' }} title="Latest Entries" />
			<CardContent>
				{seasons.map((item, index: number) => {
					return (
						<Fragment key={item.id}>
							<Typography
								variant="bodyL"
								component="h3"
								sx={{ marginBottom: 2, marginTop: index > 0 ? 3 : 0 }}
							>
								{language.isEnglish ? item.title : item.title_afr}
							</Typography>
							<AdminSubmissionsListView
								dashboard={true}
								season_id={item.id}
								submissions={item.submissions}
								limit={10}
							/>
						</Fragment>
					);
				})}
			</CardContent>
			<CardActions>
				<Button
					size="small"
					className="me-3"
					variant="contained"
					component={Link}
					to={`/submissions/`}
				>
					View All
				</Button>
			</CardActions>
		</Card>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(AdminDashboard);
