import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { mapLanguageStateToProps } from "../../redux/props/language.props";
import axios from "axios";
import { IReviews } from "../../models/reviews.model";
import Loading from "../../components/Loading";
import { Spinner, Table } from "react-bootstrap";
import { Badge, Box, Button, ButtonGroup, Chip, CircularProgress, Typography } from "@mui/material";
import HeadingFour from "../../components/Typography/HeadingFour";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import ReviewPrint from "./ReviewPrint";
import ButtonTooltip from "../../components/ButtonTooltip";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { ToastrSettings } from "../../data/toastr";
import { green } from "@mui/material/colors";
import { downloadCsv, reviewsCsvName } from "../../utils/downloadCsv";
import LoadingButton from "../../components/LoadingButton";

interface IProps {
  season_id: number;
  season_title: string;
}

const Review: React.FC<IProps> = ({ season_id, season_title }) => {
  const [loadingUnsuccessful, setLoadingUnsuccessful] = useState<boolean>(false);
  const [isExportingReviews, setIsExportingReviews] = useState<boolean>(false);
	const [isSendingEmails, setIsSendingEmails] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reviews, setReviews] = useState<IReviews[]>([]);
  let componentRef = useRef();
  
  useEffect(() => {
    async function fetchData() {
      setLoading(true);

			await axios.get(`reviews/${season_id}`).then(reviews => {
        setReviews(reviews.data)
				setLoading(false);
			});
		}
		fetchData();
  }, []);

  const markAsFailed = async (entry_id: number, undo: boolean = false) => {
    const message = (undo) ? 'undo marking' : 'mark';

    if (window.confirm(`Are you sure you want to ${message} this entry as unsuccessful?`)) {
      const url = (undo) ? `undo/entry-unsuccessful/${entry_id}` : `entry-unsuccessful/${entry_id}`;
      setLoadingUnsuccessful(true); 

      await axios.put(url).then(status => {
        setLoadingUnsuccessful(false);

        const data = reviews.map(review => {
          const { failed, ...entry } = review;
  
          return {
            ...entry,
            failed: (review.concept_id === entry_id) ? !undo : failed
          }
        });

        setReviews(data);
      });
    }
  }

  const notifyUnsuccessful = async () => {
    setIsSendingEmails(true);
    if (window.confirm("Are you sure you want to send out a notification to all entrants that did not make it?")) {
      await axios.post(`notify/unsuccessful-reviews/${season_id}`).then(message => {
        setIsSendingEmails(false);
        toast.success(message.data, ToastrSettings);
      });
    }
  }

  const exportReviews = async () => {
    setIsExportingReviews(true);

    const fileName = reviewsCsvName(season_title);

    await axios.get(`export/reviews/${season_id}`, {
      responseType: 'blob'
    }).then(({ data }) => {
      setIsExportingReviews(false);
      downloadCsv(fileName, data);
    });
  }

  return (
    <>
      <ToastContainer />

      {loading &&
        <Loading 
          text="Please wait while we fetch entries and their grades" 
        />
      }

      {/* {!loading && reviews.length > 0 && 
        <ReactToPrint
          trigger={() => 
            <Button 
              sx={{ marginBottom: 2, float: "right", clear: "both" }} 
              variant="contained" 
              target="new" 
              component={Link} 
              to={`/reviews/${season_id}/print`}>
              Print
            </Button>
          }
          content={() => compnentRef}
        />
      }

      <ReviewPrint ref={(el) => (componentRef = el)} /> */}

      {!loading && reviews.length > 0 && 
        <>
          <ButtonGroup 
            variant="contained" 
            disableElevation 
            sx={{ 
              p: 0,
              marginBottom: 2
            }}
            color="primary"
            size="small"
          >
            <LoadingButton 
              sx={{ marginLeft: 1 }}
              text="Notify unsuccessful entrants" 
              color="secondary"
              isLoading={isSendingEmails} 
              onClick={notifyUnsuccessful}
            />
            <LoadingButton 
              sx={{ marginLeft: 1 }}
              text="Export to CSV" 
              color="primary" 
              isLoading={isExportingReviews} 
              onClick={exportReviews}
            />
            <Button 
              sx={{
                marginLeft: 1
              }} 
              target="new" 
              component={Link} 
              variant="outlined"
              color="secondary"
              to={`/reviews/${season_id}/print`}
            >
              Print
            </Button>
         </ButtonGroup>

          
          <Table bordered cellPadding={10} cellSpacing={0}>
            <thead>
              <tr>
                <td>Concept</td>
                <td>Reviewer</td>
                <td>Total&nbsp;score</td>
                <td>Overall&nbsp;%</td>
              </tr>
            </thead>
            <tbody>
              {reviews.map((review, index) => {
                return (
                  <React.Fragment key={`review${index}`}>
                    <tr key={index}>
                      <td valign="top">
                        <table width="100%">
                          <tr>
                            <td valign="top" width={30}>
                              <strong>{index + 1}</strong>
                            </td>
                            <td valign="top">
                              
                              <div 
                                style={{
                                  float: "right",
                                  marginLeft: "1em"
                                }}
                              >
                                {!loadingUnsuccessful && !review.failed &&
                                  <ButtonTooltip text="Mark entry as NOT successful">
                                    <Button 
                                      size="small"
                                      variant="outlined" 
                                      color="error" 
                                      disableElevation 
                                      onClick={() => markAsFailed(review.concept_id)}
                                    >
                                      Mark as unsuccessful
                                    </Button>
                                  </ButtonTooltip>
                                }

                                {loadingUnsuccessful && 
                                  <Spinner 
                                    animation="border" 
                                    role="status"
                                  >
                                  </Spinner>
                                }

                                {!loadingUnsuccessful && review.failed &&
                                  <>
                                    <Badge 
                                      color="secondary" 
                                      badgeContent="Undo"
                                      onClick={() => markAsFailed(review.concept_id, true)}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      }}
                                      style={{
                                        cursor: 'pointer'
                                      }}
                                    >
                                      <Chip 
                                        label="Entry Unsuccessful" 
                                        color="error" 
                                        variant="filled" 
                                        sx={{
                                          marginLeft: 1
                                        }}
                                      />
                                    </Badge>
                                  </>
                                }
                              </div>

                              <HeadingFour>
                                {review.concept_name}
                              </HeadingFour>
                              {review.concept_details.map((concept, con_index) => { 
                                return (
                                  <>
                                    {con_index > 0 && 
                                      <Typography key={`concept${con_index}`} gutterBottom>
                                        <strong>{concept.question}:</strong><br />
                                        {concept.answer}
                                      </Typography>
                                    }
                                  </>
                                )
                              })}
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td valign="top" style={{ padding: 0 }}>
                        <Table bordered cellPadding={10} cellSpacing={0}>
                          <thead>
                            <tr>
                              <td>Name</td>
                              <td>Mark</td>
                              <td>Comments</td>
                            </tr>
                          </thead>
                          <tbody>
                            {review.grades.length > 0 && review.grades.map((reviewer, rev_id) => 
                              <tr key={`rev${rev_id}`}>
                                <td>{reviewer.reviewer}</td>
                                <td>{reviewer.total}%</td>
                                <td>{reviewer.feedback}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </td>
                      <td valign="top">
                        {review.total_score}
                      </td>
                      <td valign="top">
                        {review.total_average.toFixed(0)}%
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </Table>
        </>
      }
    </>
  );
}

export default connect(mapLanguageStateToProps)(Review);