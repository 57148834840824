import { Typography } from '@mui/material';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../redux/props/language.props';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Footer from '../components/landing/Footer';
import HeroBanner from '../components/landing/HeroBanner';
import DefaultLayout from '../components/layouts/Default';
import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ISeason } from '../models/seasons.model';
import axios from 'axios';
import translations from '../data/translations';
import { LanguageProps } from '../models/shared.model';
import { TabPanelHorisontal, tabProps } from '../functions/Tabs';

const Terms: React.FC<LanguageProps> = ({ language }) => {
	const [seasons, setSeasons] = React.useState<ISeason[]>([]);
	const [value, setValue] = useState<number>(0);

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get('active/seasons');
			setSeasons(data);
		}
		fetchData();
	}, []);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<DefaultLayout>
			<HeroBanner />

			<Container component="main" maxWidth="xl" sx={{ mt: 9, mb: 12 }}>
				<Typography variant="h3" component="h3" className="mb-1">
					{language.isEnglish ? translations.terms.eng : translations.terms.afr}
				</Typography>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ marginBottom: 'var(--spacer)' }}>
						<Tabs value={value} onChange={handleChange}>
							{seasons.map((season: ISeason, index: number) => {
								return (
									<Tab
										key={index}
										label={language.isEnglish ? season.title : season.title_afr}
										{...tabProps(index)}
									/>
								);
							})}
						</Tabs>
					</Box>
					{seasons.map((season: ISeason, index: number) => {
						return (
							<TabPanelHorisontal key={index} value={value} index={index}>
								<div
									dangerouslySetInnerHTML={{
										__html: language.isEnglish
											? season.terms_and_conditions
											: season.terms_and_conditions_afr,
									}}
								></div>
							</TabPanelHorisontal>
						);
					})}
				</Box>
			</Container>

			<Footer />
		</DefaultLayout>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(Terms);
