import React, { useCallback, useState } from 'react';
import axios from 'axios';
import AuthLayout from '../../components/layouts/Auth';
import { Navigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import { ILanguage } from '../../models/language.model';
import translations, { ILanguageOptions } from '../../data/translations';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { connect } from 'react-redux';
import {
	Alert,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { EntryTypes } from '../../data/entry-types';

interface IProps {
	language: ILanguage;
}

const Register: React.FC<IProps> = ({ language }) => {
	return <Navigate to={'/'} />;

	const [redirect, setRedirect] = useState<boolean>(false);
	const [errorResponse, setErrorResponse] = useState<string>('');
	const [showPassword, setShowPassword] = React.useState<boolean>(false);
	const [based_in, setBasedIn] = useState<string>('');
	const [student_number, setStudentNumber] = useState<string>('');
	const [company, setCompany] = useState<string>('');
	const [entry_type, setEntryType] = React.useState<string>('');

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	// Google reCAPTCHA
	const [token, setToken] = useState<string>('');
	const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);

	const onVerify = useCallback(
		(token: string): void => {
			setToken(token);
		},
		[token]
	);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (token) {
			const data = new FormData(event.currentTarget);
			await axios
				.post('register', {
					first_name: data.get('firstName'),
					last_name: data.get('lastName'),
					email: data.get('email'),
					contact_number: data.get('contactNumber'),
					password: data.get('password'),
					password_confirm: data.get('passwordConfirm'),
					language: language.isEnglish ? 'English' : 'Afrikaans',
					based_in,
					entry_type,
					company,
					student_number,
				})
				.then(async (response) => {
					await axios
						.post('login', {
							email: data.get('email'),
							password: data.get('password'),
						})
						.then((response) => {
							setRedirect(true);
						})
						.catch((error) => {
							const response = JSON.parse(error.request.response);
							setErrorResponse(response.message);
						});

					setRefreshReCaptcha(true);
				})
				.catch((error) => {
					const response = JSON.parse(error.request.response);
					setErrorResponse(response.message);
				});
		}
	};

	let entry_types = EntryTypes;

	const handleChangeEntryType = (event: SelectChangeEvent) => {
		setEntryType(event.target.value as string);
	};

	if (redirect) {
		return <Navigate to={'/instructions'} />;
	}

	return (
		<AuthLayout
			title={
				language.isEnglish ? translations.signUp.eng : translations.signUp.afr
			}
		>
			<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
				<GoogleReCaptcha
					onVerify={onVerify}
					refreshReCaptcha={refreshReCaptcha}
				/>
				{errorResponse !== '' && (
					<Alert severity="warning" className="mb-3">
						{errorResponse}
					</Alert>
				)}
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h3" component="p" className="mb-0">
							{language.isEnglish
								? 'Personal Details'
								: 'Persoonlike Inligting'}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							autoComplete="given-name"
							name="firstName"
							required
							fullWidth
							id="firstName"
							label={
								language.isEnglish
									? translations.fName.eng
									: translations.fName.afr
							}
							autoFocus
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							fullWidth
							id="lastName"
							label={
								language.isEnglish
									? translations.lName.eng
									: translations.lName.afr
							}
							name="lastName"
							autoComplete="family-name"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							name="contactNumber"
							required
							fullWidth
							id="contactNumber"
							label={
								language.isEnglish
									? translations.contactNo.eng
									: translations.contactNo.afr
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							label={
								language.isEnglish
									? translations.basedIn.eng
									: translations.basedIn.afr
							}
							variant="outlined"
							fullWidth={true}
							defaultValue={based_in}
							onChange={(e) => setBasedIn(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth>
							<InputLabel>
								{language.isEnglish
									? translations.entryType.eng
									: translations.entryType.afr}
							</InputLabel>
							<Select
								value={entry_type}
								label={
									language.isEnglish
										? translations.entryType.eng
										: translations.entryType.afr
								}
								onChange={handleChangeEntryType}
							>
								{entry_types.map((type: ILanguageOptions, index: number) => {
									return (
										<MenuItem key={index} value={type.eng}>
											{language.isEnglish ? type.eng : type.afr}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>

					{entry_type === entry_types[0].eng && (
						<Grid item xs={12} sm={6}>
							<TextField
								className="mb-3"
								label="Student number"
								variant="outlined"
								fullWidth={true}
								defaultValue={student_number}
								onChange={(e) => setStudentNumber(e.target.value)}
							/>
						</Grid>
					)}

					{entry_type === entry_types[1].eng && (
						<Grid item xs={12} sm={6}>
							<TextField
								className="mb-3"
								label="Company"
								variant="outlined"
								fullWidth={true}
								defaultValue={company}
								onChange={(e) => setCompany(e.target.value)}
							/>
						</Grid>
					)}

					<Grid item xs={12} className="mt-4">
						<Typography variant="h3" component="p" className="mb-0">
							{language.isEnglish ? 'Login Details' : 'Inskrywing'}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							id="email"
							label={
								language.isEnglish
									? translations.email.eng
									: translations.email.afr
							}
							name="email"
							autoComplete="email"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl
							margin="normal"
							required
							fullWidth={true}
							variant="outlined"
						>
							<InputLabel htmlFor="outlined-password">
								{language.isEnglish
									? translations.password.eng
									: translations.password.afr}
							</InputLabel>
							<OutlinedInput
								required
								type={showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<IconButton onClick={handleClickShowPassword} edge="end">
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
								label={
									language.isEnglish
										? translations.password.eng
										: translations.password.afr
								}
								name="password"
								autoComplete="new-password"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl
							margin="normal"
							fullWidth={true}
							required
							variant="outlined"
						>
							<InputLabel htmlFor="outlined-password">
								{language.isEnglish
									? `${translations.confirm.eng} ${translations.password.eng}`
									: `${translations.confirm.afr} ${translations.password.afr}`}
							</InputLabel>
							<OutlinedInput
								required
								type={showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<IconButton onClick={handleClickShowPassword} edge="end">
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
								label={
									language.isEnglish
										? `${translations.confirm.eng} ${translations.password.eng}`
										: `${translations.confirm.afr} ${translations.password.afr}`
								}
								name="passwordConfirm"
								autoComplete="new-password-confirm"
							/>
						</FormControl>
					</Grid>
				</Grid>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
				>
					{language.isEnglish
						? translations.signUp.eng
						: translations.signUp.afr}
				</Button>
				<Grid container justifyContent="flex-end">
					<Grid item>
						<Link component={NavLink} to="/sign-in" variant="body2">
							{language.isEnglish
								? `Already have an account? ${translations.signIn.eng}`
								: `Reeds 'n rekening? ${translations.signIn.afr}`}
						</Link>
					</Grid>
				</Grid>
			</Box>
		</AuthLayout>
	);
};

export default connect(mapLanguageStateToProps)(Register);
