import React from 'react';
import { connect } from 'react-redux';
import AdminLayout from '../../components/layouts/Admin';
import SubmissionsListView from '../../components/submissions/ListView';
import { mapStateToProps } from '../../redux/props/user.props';
import { User } from '../../models/user.model';

type IProps = {
	user: User;
};

const Entries: React.FC<IProps> = ({ user }) => {
	return (
		<AdminLayout maxWidth="sm" breadcrumbs={['My Entries']}>
			<SubmissionsListView user={user} />
		</AdminLayout>
	);
};

export default connect(mapStateToProps)(Entries);
