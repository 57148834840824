import { Avatar, CardContent, CardHeader, Chip, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import Card from '@mui/material/Card';
import { roleColor, stringAvatar } from "../functions/Global";

interface IProps {
  person: string;
  role?: string;
  date: string;
  comment: string;
  actions?: ReactNode;
}

const Comment: React.FC<IProps> = ({ person, role, date, comment, actions }) => {
  return (
    <Card variant="outlined" sx={{ marginBottom: 2 }}>
      <CardHeader
        avatar={
          <Avatar {...stringAvatar(person)} />
        }
        action={actions}
        title={
          <>
            {person} 
            {role && 
              <Chip 
                label={role} 
                size="small"
                {...roleColor(role)}
              />
            }
          </>
        }
        subheader={date}
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <Typography variant="body2">
          {comment}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Comment;