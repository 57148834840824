import React, { useEffect, useState } from 'react';
import AdminLayout from '../../components/layouts/Admin';
import axios from 'axios';
import Paginator from '../../components/Paginator';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';
import { ISeason } from '../../models/seasons.model';
import { formatDate } from '../../functions/Global';
import { ILanguage } from '../../models/language.model';
import translations from '../../data/translations';
import { connect } from 'react-redux';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import Currency from '../../components/Currency';
import SeasonOptions from './components/Options';
import Loading from '../../components/Loading';
import NoDataAvailable from '../../components/NoDataAvailable';

type IProps = {
	language: ILanguage;
};

const Seasons: React.FC<IProps> = ({ language }) => {
	const [seasons, setSeasons] = useState<ISeason[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [page, setPage] = useState<number>(1);
	const [lastPage, setLastPage] = useState<number>(0);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			await axios
				.get(`seasons/admin?page=${page}`, {
					params: {
						file: 'src/pages/seasons/Seasons.tsx',
					},
				})
				.then(({ data }) => {
					setSeasons(data.data);
					setLastPage(data.meta.last_page);
					setLoading(false);
				});
		}
		fetchData();
	}, [page]); // every time page variable changes, reload users

	const deleteSeason = (id: number) => async (event: any) => {
		if (window.confirm('Are you sure you want to delete this season?')) {
			await axios.delete(`seasons/${id}`);
			setSeasons(seasons.filter((s: ISeason) => s.id !== id));
		}
	};

	return (
		<AdminLayout maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<Typography variant="h3" component="h2">
						Seasons
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Box
						sx={{
							marginBottom: '1.5em',
							float: 'right',
						}}
					>
						<Button variant="contained" component={Link} to="/seasons/create">
							Create New Season
						</Button>
					</Box>
				</Grid>
			</Grid>

			{loading && <Loading />}

			{!loading && seasons.length === 0 && <NoDataAvailable />}

			{!loading && seasons.length > 0 && (
				<>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell component="th">#</TableCell>
									<TableCell component="th" align="left">
										{language.isEnglish
											? translations.thTitle.eng
											: translations.thTitle.afr}
									</TableCell>
									<TableCell component="th" align="left">
										{language.isEnglish
											? translations.thEntries.eng
											: translations.thEntries.afr}
									</TableCell>
									<TableCell component="th" align="left">
										{language.isEnglish
											? translations.thPayments.eng
											: translations.thPayments.afr}
									</TableCell>
									<TableCell component="th" align="left">
										{language.isEnglish
											? translations.thStartDate.eng
											: translations.thStartDate.afr}
									</TableCell>
									<TableCell component="th" align="left">
										{language.isEnglish
											? translations.thEndDate.eng
											: translations.thEndDate.afr}
									</TableCell>
									<TableCell component="th" align="left">
										{language.isEnglish
											? translations.thCost.eng
											: translations.thCost.afr}
									</TableCell>
									<TableCell component="th" align="left">
										{language.isEnglish
											? translations.thAction.eng
											: translations.thAction.afr}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{seasons.map((season: ISeason) => {
									return (
										<TableRow
											key={season.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="th" scope="row" width={70}>
												{season.id}
											</TableCell>
											<TableCell align="left">
												<Link to={`/seasons/${season.id}/edit`}>
													{language.isEnglish ? season.title : season.title_afr}
												</Link>
											</TableCell>
											<TableCell align="center" width={160}>
												{season.submissions?.length}
											</TableCell>
											<TableCell align="left" width={180}>
												<Currency
													price={
														season.payments_received
															? season.payments_received
															: 0
													}
												/>
											</TableCell>
											<TableCell align="left" width={160}>
												{formatDate(season.start_date)}
											</TableCell>
											<TableCell align="left" width={160}>
												{formatDate(season.end_date)}
											</TableCell>
											<TableCell align="left" width={160}>
												<Currency price={Number(season.cost)} />
											</TableCell>
											<TableCell align="left" width={110}>
												<Tooltip title="Delete Season" placement="top" arrow>
													<IconButton
														color="secondary"
														onClick={deleteSeason(season.id)}
													>
														<DeleteIcon fontSize="small" />
													</IconButton>
												</Tooltip>
												<SeasonOptions
													id={season.id}
													isInTable={true}
													title={season.title}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<Paginator lastPage={lastPage} page={page} pageChanged={setPage} />
				</>
			)}
		</AdminLayout>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(Seasons);
