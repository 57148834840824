import { ILanguageOptions } from './translations';

export const EntryTypes: ILanguageOptions[] = [
	{
		eng: 'Student',
		afr: 'Student',
	},
	{
		eng: 'Company',
		afr: 'Maatskappy',
	},
	{
		eng: 'Private',
		afr: 'Privaat',
	},
];
