import { connect } from 'react-redux';
import { ILanguage } from '../../../models/language.model';
import { mapLanguageStateToProps } from '../../../redux/props/language.props';
import { Divider, Typography } from '@mui/material';
import { formatDate } from '../../../functions/Global';

type IProps = {
	language: ILanguage;
	title?: string;
	title_afr?: string;
	end_date: Date;
	cost?: number;
};

const EntryDetails: React.FC<IProps> = ({
	language,
	title,
	title_afr,
	end_date,
	cost,
}) => {
	return (
		<>
			<Typography component="p" variant="subheadingL">
				Entry Details
			</Typography>
			<Divider />
			<table cellSpacing={0} cellPadding={0} className="table-information">
				<tr>
					<th style={{ width: '125px' }}>
						{language.isEnglish ? 'Season' : 'Seisoen'}:
					</th>
					<td>{language.isEnglish ? title : title_afr}</td>
				</tr>
				<tr>
					<th>{language.isEnglish ? 'Closing Date' : 'Sluitingsdatum'}:</th>
					<td>{formatDate(end_date)}</td>
				</tr>
				<tr>
					<th>{language.isEnglish ? 'Cost' : 'Bedrag'}:</th>
					<td>R{cost?.toFixed(2)}</td>
				</tr>
			</table>
			<Divider
				sx={{
					marginTop: 'var(--spacer)',
					marginBottom: '5px',
				}}
			/>
		</>
	);
};

export default connect(mapLanguageStateToProps)(EntryDetails);
