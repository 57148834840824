import React, { SyntheticEvent, useEffect, useState } from 'react';
import AdminLayout from '../../components/layouts/Admin';
import { User } from '../../models/user.model';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import {
	mapDispatchToProps,
	mapStateToProps,
} from '../../redux/props/user.props';
import {
	Grid,
	Typography,
	Button,
	Alert,
	AlertTitle,
	SelectChangeEvent,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	TextField,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastrSettings } from '../../data/toastr';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Navigate } from 'react-router-dom';
import { EntryTypes } from '../../data/entry-types';
import { ILanguageOptions } from '../../data/translations';

interface IProps {
	user: User;
	setUser: (user: User) => void;
}

const ProfileEdit: React.FC<IProps> = ({ user, setUser }) => {
	const [first_name, setFirstName] = useState<string>('');
	const [last_name, setLastName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [contact_number, setContactNumber] = useState<string>('');
	const [based_in, setBasedIn] = useState<string>('');
	const [student_number, setStudentNumber] = useState<string>('');
	const [company, setCompany] = useState<string>('');
	const [language, setLanguage] = useState<string>('');
	const [entry_type, setEntryType] = React.useState<string>('');
	const [showPassword, setShowPassword] = React.useState<boolean>(false);
	const [redirect, setRedirect] = React.useState<boolean>(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	let entry_types = EntryTypes;

	const handleChangeEntryType = (event: SelectChangeEvent) => {
		setEntryType(event.target.value as string);
	};

	// Password
	const [password, setPassword] = useState<string>('');
	const [password_confirm, setPasswordConfirm] = useState<string>('');
	const [password_updated, setPasswordUpdated] = useState<boolean>(false);

	useEffect(() => {
		setFirstName(user.first_name);
		setLastName(user.last_name);
		setEmail(user.email);
		setContactNumber(user.contact_number);
		setBasedIn(user.based_in);
		setStudentNumber(user.student_number);
		setCompany(user.company);
		setLanguage(user.language);
		setEntryType(user.entry_type);
	}, [user]);

	const submit = async (e: SyntheticEvent) => {
		e.preventDefault();

		const { data } = await axios.put('users/info', {
			first_name,
			last_name,
			email,
			contact_number,
			based_in,
			student_number,
			company,
			language,
			entry_type,
		});

		if (data.id) {
			toast.success('Profile updated successfully!', ToastrSettings);
			setRedirect(true);
		}

		setUser(
			new User(
				data.id,
				data.first_name,
				data.last_name,
				data.email,
				data.role,
				data.contact_number,
				data.based_in,
				data.student_number,
				data.company,
				data.language,
				data.entry_type
			)
		);
	};

	const resetPassword = async (e: SyntheticEvent) => {
		e.preventDefault();

		const resp = await axios
			.put('users/password', {
				password,
				password_confirm,
			})
			.then((response) => {
				toast.success('Password updated successfully!', ToastrSettings);

				setPassword('');
				setPasswordConfirm('');
				setPasswordUpdated(true);
				setRedirect(true);
			})
			.catch((error) => {
				const response = JSON.parse(error.request.response);
				// setPasswordErrorResponse(response.message);
			});
	};

	if (redirect) {
		return <Navigate to={'/profile'} />;
	}

	return (
		<AdminLayout>
			<ToastContainer />
			<Typography variant="h3" component="h2">
				Edit Profile
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={7}>
					<Typography variant="h2" className="mb-3" component="h2">
						Details
					</Typography>
					<Form onSubmit={submit}>
						<TextField
							className="mb-3"
							label="First Name"
							variant="outlined"
							fullWidth={true}
							defaultValue={first_name}
							onChange={(e) => setFirstName(e.target.value)}
							required
						/>

						<TextField
							className="mb-3"
							label="Last Name"
							variant="outlined"
							fullWidth={true}
							defaultValue={last_name}
							onChange={(e) => setLastName(e.target.value)}
							required
						/>

						<TextField
							className="mb-3"
							label="Email"
							variant="outlined"
							fullWidth={true}
							defaultValue={email}
							type="email"
							onChange={(e) => setEmail(e.target.value)}
							required
						/>

						<TextField
							className="mb-3"
							label="Contact Number"
							variant="outlined"
							fullWidth={true}
							defaultValue={contact_number}
							onChange={(e) => setContactNumber(e.target.value)}
							required
						/>

						<TextField
							className="mb-3"
							label="Based in"
							variant="outlined"
							fullWidth={true}
							defaultValue={based_in}
							onChange={(e) => setBasedIn(e.target.value)}
						/>

						<FormControl className="mb-3" fullWidth>
							<InputLabel>Type of Entry</InputLabel>
							<Select
								value={entry_type}
								label="Type of Entry"
								onChange={handleChangeEntryType}
							>
								{entry_types.map((type: ILanguageOptions, index: number) => {
									return (
										<MenuItem key={index} value={type.eng}>
											{type.eng}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						{entry_type === entry_types[0].eng && (
							<TextField
								className="mb-3"
								label="Student number"
								variant="outlined"
								fullWidth={true}
								defaultValue={student_number}
								onChange={(e) => setStudentNumber(e.target.value)}
							/>
						)}

						{entry_type === entry_types[1].eng && (
							<TextField
								className="mb-3"
								label="Company"
								variant="outlined"
								fullWidth={true}
								defaultValue={company}
								onChange={(e) => setCompany(e.target.value)}
							/>
						)}

						<TextField
							className="mb-3"
							label="Language"
							variant="outlined"
							fullWidth={true}
							defaultValue={language}
							onChange={(e) => setLanguage(e.target.value)}
						/>

						<Button
							variant="contained"
							color="success"
							className="mt-2"
							type="submit"
						>
							Update Profile
						</Button>
					</Form>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="h2" className="mb-3" component="h2">
						Reset Password
					</Typography>
					{password_updated === true && (
						<Alert variant="outlined" severity="success" className="mb-4">
							<AlertTitle>Success</AlertTitle>
							Password updated successfully!
						</Alert>
					)}
					<Form onSubmit={resetPassword}>
						<FormControl className="mb-3" fullWidth={true} variant="outlined">
							<InputLabel htmlFor="outlined-password">Password</InputLabel>
							<OutlinedInput
								id="outlined-password"
								type={showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<IconButton onClick={handleClickShowPassword} edge="end">
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
								label="Password"
								defaultValue={''}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
						</FormControl>
						<FormControl fullWidth={true} variant="outlined">
							<InputLabel htmlFor="confirm-password">
								Confirm Password
							</InputLabel>
							<OutlinedInput
								id="confirm-password"
								type={showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<IconButton onClick={handleClickShowPassword} edge="end">
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
								label="Confirm Password"
								defaultValue={''}
								onChange={(e) => setPasswordConfirm(e.target.value)}
								required
							/>
						</FormControl>
						<Button
							variant="contained"
							color="secondary"
							className="mt-2"
							type="submit"
						>
							Reset Password
						</Button>
					</Form>
				</Grid>
			</Grid>
		</AdminLayout>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
