export const isStudent = (entry_type: string): boolean => {
	return entry_type == 'Student' ? true : false;
};

export const entryStatus = (isDraft: boolean): string => {
	return isDraft ? 'Draft' : 'Published';
};

export const countOccurrences = (array: any, element: any): number => {
	let counter = 0;

	for (let i = 0; i <= array.length; i++) {
		if (array[i] == element) {
			counter++;
		}
	}

	return counter;
};
