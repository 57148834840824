import { Step, StepLabel, Stepper } from '@mui/material';
import * as React from 'react';

interface IProps {
	active: number;
}

const StepperNav: React.FC<IProps> = ({ active }) => {
	return (
		<Stepper activeStep={active}>
			<Step key={0}>
				<StepLabel>Confirmation</StepLabel>
			</Step>
			<Step key={1}>
				<StepLabel>Payment Details</StepLabel>
			</Step>
			<Step key={2}>
				<StepLabel>Review Order</StepLabel>
			</Step>
		</Stepper>
	);
};

export default StepperNav;
