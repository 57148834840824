import React, { Fragment, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeOptions } from '../../styles/theme';
import { Breadcrumbs } from '@mui/material';
import Languages from '../../data/languages';
import { connect } from 'react-redux';
import {
	mapLanguageStateToProps,
	mapLanguageDispatchToProps,
} from '../../redux/props/language.props';
import { ILanguage } from '../../models/language.model';
import Copyright from '../admin/Copyright';

type IProps = {
	language: ILanguage;
	setLanguage: (language: string) => void;
	title: string;
	children: React.ReactNode;
};

const AuthLayout: React.FC<IProps> = ({
	language,
	setLanguage,
	title,
	children,
}) => {
	// const [redirect, setRedirect] = useState<boolean>(false);
	// const [loading, setLoading] = useState<boolean>(true);
	// const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

	const theme = createTheme(themeOptions);

	const changeLanguage =
		(newLanguage: string) => (event: React.MouseEvent<HTMLElement>) => {
			event.preventDefault();
			setLanguage(newLanguage);
			localStorage.setItem('language', newLanguage);
		};

	useEffect(() => {
		async function isAuthenticated() {
			// try {
			//   const { data } = await axios.get('auth/verify');
			//   if (data) {
			//     // setLoading(false);
			//     // setIsAuthenticated(true);
			//     //setRedirect(true);
			//   }
			// }
			// catch (error) { }
		}
		isAuthenticated();
	});

	return (
		<ThemeProvider theme={theme}>
			<Grid container component="main" sx={{ height: '100vh' }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						backgroundImage: 'url(/auth-image-2023.png)',
						backgroundRepeat: 'no-repeat',
						backgroundColor: (t) =>
							t.palette.mode === 'dark'
								? t.palette.grey[50]
								: t.palette.grey[900],
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<Box
						sx={{
							my: 4,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-end',
						}}
					>
						<Breadcrumbs aria-label="breadcrumb">
							{Languages.map((lang) => {
								return language.active === lang ? (
									<Typography key={`auth-language-${lang}`} color="primary">
										{lang}
									</Typography>
								) : (
									<Link
										key={`auth-language-${lang}`}
										underline="hover"
										color="inherit"
										href="#"
										onClick={changeLanguage(lang)}
									>
										{lang}
									</Link>
								);
							})}
						</Breadcrumbs>
					</Box>
					<Box
						sx={{
							my: 4,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h3">
							{title}
						</Typography>
						{children}
						{/* <Copyright /> */}
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(AuthLayout);
