import React, { useEffect, useState } from 'react';
import AdminNav from '../admin/nav/Nav';
import AdminMobileNav from '../admin/nav/MobileNav';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { User } from '../../models/user.model';
import { connect } from 'react-redux';
import {
	mapDispatchToProps,
	mapStateToProps,
} from '../../redux/props/user.props';
import { Breakpoint, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { themeOptions } from '../../styles/theme';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import Pattern from '../../assets/background-pattern.svg';

interface IProps {
	children: React.ReactNode;
	setUser: (user: User) => void;
	breadcrumbs?: string[];
	maxWidth?: Breakpoint | false; // xs = left; md = centered
}

const AdminLayout: React.FC<IProps> = ({
	children,
	setUser,
	breadcrumbs = [],
	maxWidth = 'sm',
}) => {
	const [redirect, setRedirect] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		async function getUser() {
			try {
				const { data } = await axios.get('user');

				if (data?.id) {
					setUser(
						new User(
							data.id,
							data.first_name,
							data.last_name,
							data.email,
							data.role,
							data.contact_number,
							data.based_in,
							data.student_number,
							data.company,
							data.language,
							data.entry_type,
							data.registered_date,
							data.logged_in_date,
							data.updated_date
						)
					);
				} else {
					setRedirect(true);
				}

				setLoading(false);
			} catch (error) {
				setRedirect(true);
				setLoading(false);
			}
		}
		getUser();
	}, [setUser]);

	if (redirect && !loading) {
		return <Navigate to="/" />;
	}

	const mdTheme = createTheme(themeOptions);

	if (!redirect && !loading) {
		return (
			<ThemeProvider theme={mdTheme}>
				{/* Mobile nav */}
				<AdminMobileNav />

				<Box sx={{ display: 'flex' }}>
					<CssBaseline />

					{/* Desktop nav */}
					<AdminNav />

					<Box
						component="main"
						sx={{
							backgroundColor: (theme) =>
								theme.palette.mode === 'light'
									? 'var(--grey-050)'
									: theme.palette.grey[900],
							backgroundImage: `url(${Pattern})`,
							backgroundRepeat: 'repeat',
							backgroundPosition: '-4px -4px',
							backgroundSize: '122px',
							flexGrow: 1,
							display: 'flex',
							flexDirection: 'column',
							minHeight: '100vh',
						}}
					>
						<Container
							maxWidth={maxWidth}
							sx={{ p: 5, marginBottom: 3, marginLeft: '70px' }}
						>
							{breadcrumbs && breadcrumbs.length > 0 && (
								<Breadcrumbs
									aria-label="breadcrumb"
									sx={{ marginBottom: '20px' }}
								>
									<Link underline="hover" color="inherit" href="/">
										silwerskerm.tv
									</Link>

									{breadcrumbs.map((item, index) => {
										const current = index === breadcrumbs.length - 1;
										return !current && breadcrumbs.length > 1 ? (
											<Link
												key={`breadcrumb-admin-${index}`}
												underline="hover"
												color="inherit"
												href="/"
											>
												{item}
											</Link>
										) : (
											<Typography
												key={`breadcrumb-admin-${index}`}
												color="text.primary"
											>
												{item}
											</Typography>
										);
									})}
								</Breadcrumbs>
							)}

							{children}
						</Container>
						{/* <Copyright /> */}
					</Box>
				</Box>
			</ThemeProvider>
		);
	}

	return <>Loading ...</>;
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
