import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { URLParams } from '../../models/shared.model';
import AdminLayout from '../../components/layouts/Admin';
import {
	Card,
	Button,
	CardContent,
	CardHeader,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Stack,
	TextField,
	Typography,
	Divider,
	Chip,
	Box,
} from '@mui/material';
import { connect } from 'react-redux';
import EntryAnswers from '../../components/submissions/components/EntryAnswers';
import axios from 'axios';
import Loading from '../../components/Loading';
import { useFieldArray, useForm } from 'react-hook-form';
import { ISeasonGradingField } from '../../models/seasons.model';
import { User } from '../../models/user.model';
import { mapStateToProps } from '../../redux/props/user.props';
import { IGradeModel } from '../../models/grading.model';
import { ToastrSettings } from '../../data/toastr';
import { ToastContainer, toast } from 'react-toastify';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

interface IProps {
	user: User;
}

const ReviewEntry: React.FC<IProps> = ({ user }) => {
	let { entry_id, season_id, reviewer_id } = useParams<URLParams | any>();

	const [loading, setLoading] = useState<boolean>(false);
	const [form, setForm] = useState<ISeasonGradingField[]>([]);
	const [totalGradingScore, setTotalGradingScore] = useState<number>(0);
	const [isValid, setIsValid] = useState<boolean>(false);
	const [feedback, setFeedback] = useState<string>();
	const [feedback_id, setFeedbackId] = useState<number>();
	const [isReviewed, setIsReviewed] = useState<boolean>(false);
	const [grades, setGrades] = useState<IGradeModel[]>();
	const [redirect, setRedirect] = useState<boolean>(false);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const { data } = await axios.get(
				`review-entry/${entry_id}/${reviewer_id}`
			);

			await axios.get(`review-form/${season_id}`).then((form) => {
				setForm(form.data);
				setLoading(false);
			});

			if (data.grades.length > 0 && data.comment.length > 0) {
				const { grades, comment } = data;
				setGrades(grades);
				setFeedback(comment[0].comment);
				setFeedbackId(comment[0].id);
				setIsReviewed(true);
			}
		}
		fetchData();
	}, [entry_id]);

	const defaultValues = {
		id: 0,
		answerid: '',
		feedbackid: '',
		answer: '',
	};

	const { control, register, handleSubmit, watch } = useForm({
		defaultValues: {
			form: [defaultValues],
		},
		values: {
			form: form,
		},
	});

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			if (value?.form && value?.form.length > 0) {
				const sum = value.form
					.map((a) => a?.answer)
					.reduce(function (a, b) {
						return Number(a) + Number(b);
					});

				const totalAnswers = value.form
					.map((item) => item)
					.filter((item) => item?.answer).length;
				const isValid = totalAnswers === value.form.length;

				setIsValid(isValid);

				if (sum) {
					setTotalGradingScore(Number(sum));
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'form',
	});

	const onSave = async (formData: any) => {
		let { form } = formData;

		if (isReviewed) {
			await axios.put(`grading/${entry_id}/submit`, {
				feedback,
				form,
				feedback_id,
			});

			toast.success('Review updated successfully!', ToastrSettings);
		}

		if (!isReviewed) {
			await axios.post(`grading/${entry_id}/submit`, {
				feedback,
				form,
			});

			toast.success('Review submitted successfully!', ToastrSettings);
			setIsReviewed(true);
		}

		setRedirect(true);
	};

	if (redirect) {
		return <Navigate to={`/dashboard`} />;
	}

	return (
		<AdminLayout maxWidth="xl">
			<Card sx={{ marginBottom: 4 }}>
				<CardHeader
					title={`Entry #${entry_id}`}
					sx={{
						backgroundImage: 'none',
						' .MuiCardHeader-content': {
							flex: {
								xs: 1,
								md: 0,
							},
							whiteSpace: {
								xs: 'normal',
								md: 'nowrap',
							},
						},
					}}
					avatar={
						<Link to="/dashboard" className="icon-button small">
							<span className="icon icon-chevron-left"></span>
						</Link>
					}
					action={
						<Box
							sx={{
								marginLeft: 'var(--spacer)',
								marginTop: 'var(--spacer-xxs)',
							}}
						>
							{!isReviewed && (
								<Chip
									icon={<RadioButtonUncheckedIcon />}
									sx={{ background: 'var(--grey-020)' }}
									label="To do"
								/>
							)}
							{isReviewed && (
								<Chip
									icon={<CheckCircleOutlineIcon />}
									label="Done"
									color="success"
								/>
							)}
						</Box>
					}
				/>
				<CardContent>
					<Grid container spacing={4}>
						<Grid item xs={12} lg={7}>
							<Typography
								variant="subheadingL"
								sx={{ marginBottom: 'var(--spacer-xs)' }}
							>
								Entry Details
							</Typography>
							<Divider variant="fullWidth" component="div" />
							<EntryAnswers
								id={Number(entry_id)}
								isReviewer={true}
								userId={user.id}
							/>
						</Grid>
						<Grid item xs={12} lg={5}>
							<Typography
								variant="subheadingL"
								sx={{ marginBottom: 'var(--spacer-l)' }}
							>
								Review
							</Typography>
							{loading && <Loading />}
							{!loading && form.length > 0 && (
								<form onSubmit={handleSubmit(onSave)}>
									{form.map((item: ISeasonGradingField, index) => {
										return (
											<div key={item.id}>
												<TextField
													type="number"
													value={grades ? grades[index].id : ''}
													{...register(`form.${index}.answerid`)}
													hidden
												/>
												<TextField
													type="number"
													value={Number(item.id)}
													{...register(`form.${index}.id`)}
													hidden
												/>
												<TextField
													disabled={user.role.id !== 4}
													{...register(`form.${index}.answer`, {
														required: {
															value: true,
															message: 'required',
														},
													})}
													inputProps={{
														max: item.grading_score,
														inputMode: 'numeric',
														min: 0,
														step: 1,
													}}
													sx={{ marginBottom: 'var(--spacer)' }}
													InputProps={{
														endAdornment: (
															<InputAdornment
																position="end"
																sx={{ color: 'var(--primary-800)' }}
															>
																out of {item.grading_score}
															</InputAdornment>
														),
													}}
													label={item.description}
													variant="outlined"
													defaultValue={grades ? grades[index].grade : ' '}
													type="number"
													required
													fullWidth
												/>
											</div>
										);
									})}
									<TextField type="number" value={feedback_id} hidden />
									<FormControl fullWidth>
										<InputLabel>Feedback *</InputLabel>
										<OutlinedInput
											onChange={(e) => setFeedback(e.target.value)}
											label="Feedback *"
											multiline
											fullWidth
											required={true}
											name="feedback"
											value={feedback}
											type="text"
											minRows="5"
											disabled={user.role.id !== 4}
										/>
									</FormControl>
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={2}
										marginTop={3}
									>
										<Typography component="p">
											{totalGradingScore} / 100
										</Typography>
										{user.role.id === 4 && (
											<Button
												color="primary"
												variant="contained"
												disableElevation
												type="submit"
												disabled={!isValid || !feedback}
											>
												Submit Grades
											</Button>
										)}
									</Stack>
								</form>
							)}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
			<ToastContainer />
		</AdminLayout>
	);
};

export default connect(mapStateToProps)(ReviewEntry);
