import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	onClick: (e: any) => void;
}

const DialogClose: React.FC<IProps> = ({ onClick }) => {
	return (
		<Link to="" className="modal-close" onClick={onClick}>
			<span className="icon icon-close"></span>
		</Link>
	);
};

export default DialogClose;
