import {
	Button,
	FormControl,
	InputLabel,
	NativeSelect,
	OutlinedInput,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import { genres } from '../../data/genre';
import { ILanguageOptions } from '../../data/translations';
import { IFieldAnswerDB } from '../../models/form.model';
import { ILanguage } from '../../models/language.model';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import { capacities } from '../../data/capacity';

type IProps = {
	language: ILanguage;
};

const EntryEdit: React.FC<IProps> = ({ language }) => {
	let { id } = useParams<URLSearchParams | any>();
	const [answers, setAnswers] = useState<IFieldAnswerDB[]>([]);
	const [redirect, setRedirect] = useState<boolean>(false);

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get(`entry/${id}`);

			setAnswers(data.answers);
		}
		fetchData();
	}, [id]);

	const submitEntry = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const form_data = new FormData(event.currentTarget);

		let answers: IFieldAnswerDB[] = [];

		form_data.forEach((answer, answer_id) => {
			answers.push({
				answer: String(answer),
				id: Number(answer_id),
			});
		});

		await axios.put(`entry/${id}/answers`, {
			answers: answers,
		});

		setRedirect(true);
	};

	if (redirect) {
		return <Navigate to={'/entries'} />;
	}

	return (
		<AdminLayout>
			<Typography variant="h3" component="h2">
				Update Entry
			</Typography>
			<Form onSubmit={submitEntry}>
				{answers.map((item: IFieldAnswerDB) => {
					return (
						<>
							{item.question?.type?.id === 5 && (
								<FormControl
									key={item.id}
									className="mt-4"
									fullWidth
									variant="standard"
								>
									<InputLabel>
										{language.isEnglish
											? item.question!.label
											: item.question!.label_afr}
									</InputLabel>
									<NativeSelect defaultValue={item.answer} name={`${item.id}`}>
										{capacities.map((genre: ILanguageOptions, index) => {
											return (
												<option key={index} value={genre.eng}>
													{language.isEnglish ? genre.eng : genre.afr}
												</option>
											);
										})}
									</NativeSelect>
								</FormControl>
							)}

							{item.question?.type?.id === 4 && (
								<FormControl
									key={item.id}
									className="mt-4"
									fullWidth
									variant="standard"
								>
									<InputLabel>
										{language.isEnglish
											? item.question!.label
											: item.question!.label_afr}
									</InputLabel>
									<NativeSelect defaultValue={item.answer} name={`${item.id}`}>
										{genres.map((genre: ILanguageOptions, index) => {
											return (
												<option key={index} value={genre.eng}>
													{language.isEnglish ? genre.eng : genre.afr}
												</option>
											);
										})}
									</NativeSelect>
								</FormControl>
							)}

							{item.question &&
								item.question?.type &&
								item.question?.type?.id &&
								item.question?.type?.id < 4 && (
									<FormControl key={item.id} className="mt-4" fullWidth>
										<InputLabel>
											{language.isEnglish
												? item.question!.label
												: item.question!.label_afr}
										</InputLabel>
										<OutlinedInput
											label={
												language.isEnglish
													? item.question!.label
													: item.question!.label_afr
											}
											multiline
											fullWidth
											required={true}
											name={`${item.id}`}
											defaultValue={item.answer}
											type={item.question?.type?.id === 3 ? 'number' : 'text'}
											minRows={item.question?.type?.id === 2 ? 5 : 1}
										/>
									</FormControl>
								)}
						</>
					);
				})}
				<Button
					className="mt-4"
					variant="contained"
					color="primary"
					type="submit"
				>
					Confirm and save
				</Button>
			</Form>
		</AdminLayout>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(EntryEdit);
