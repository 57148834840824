import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ILanguage } from '../../../models/language.model';
import { mapLanguageStateToProps } from '../../../redux/props/language.props';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Chip,
	Grid,
	MenuItem,
	MenuList,
	Tooltip,
	Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { IMenu } from '../../../models/menu.model';
import { ISeasonID } from '../../../models/submissions.model';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import ListIcon from '@mui/icons-material/List';
import ButtonTooltip from '../../../components/ButtonTooltip';

interface IProps {
	language: ILanguage;
	title?: string;
	total_submissions?: number;
	actions?: React.ReactNode;
}

const ReviewerOptionsMenu: React.FC<IProps> = ({
	language,
	title,
	total_submissions,
	actions,
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const [seasons, setSeasons] = useState<ISeasonID[]>([]);
	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	const menuItems = (id: number): IMenu[] => {
		const url = `/reviewers/${id}`;

		return [
			{
				name: 'Dashboard',
				name_afr: 'Resensente',
				url: `${url}/reviews`,
				icon: <PeopleAltIcon color="secondary" fontSize="small" />,
			},
			{
				name: 'Manage Groups',
				name_afr: 'Groepe',
				url: `${url}/groups`,
				icon: <GroupsIcon color="secondary" fontSize="small" />,
			},
			{
				name: 'Assign Entries',
				name_afr: 'Groepe',
				url: `${url}/entries`,
				icon: <ListIcon color="secondary" fontSize="small" />, // TODO
			},
		];
	};

	useEffect(() => {
		async function fetchData() {
			const { data } = await axios.get('seasons/active/keys');
			setSeasons(data);
		}
		fetchData();
	}, []);

	const toggleDrawer =
		(open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			setOpen(open);
		};

	const list = () => (
		<Box sx={{ width: 250 }} role="presentation">
			<MenuItem component={Link} to="/reviewers">
				<ListItemIcon>
					<ListIcon color="secondary" fontSize="small" />
				</ListItemIcon>
				<ListItemText>Dashboard</ListItemText>
			</MenuItem>
			{seasons.map((season: ISeasonID, seasonIndex) => {
				return (
					<Accordion
						style={{ boxShadow: 'none' }}
						key={seasonIndex}
						expanded={expanded === `s${season.id}`}
						onChange={handleChange(`s${season.id}`)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							className="align-items-center"
						>
							<Typography>
								{language.isEnglish ? season.title : season.title_afr}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<MenuList dense>
								{menuItems(season.id).map((item, index) => {
									return (
										<MenuItem
											key={`menu${index}`}
											component={Link}
											to={item.url}
										>
											<ListItemIcon>{item.icon}</ListItemIcon>
											<ListItemText>
												{language.isEnglish ? item.name : item.name_afr}
											</ListItemText>
										</MenuItem>
									);
								})}
							</MenuList>
						</AccordionDetails>
					</Accordion>
				);
			})}
		</Box>
	);

	return (
		<>
			{actions}
			<Button size="small" variant="contained" onClick={toggleDrawer(true)}>
				&nbsp;&nbsp;&nbsp;Manage
			</Button>
			<Drawer
				anchor="right"
				open={open}
				onClose={toggleDrawer(false)}
				style={{ zIndex: '1201' }}
			>
				<Typography
					sx={{ p: 2, paddingBottom: 0, fontSize: '1.3em' }}
					color="primary"
				>
					Seasons
				</Typography>
				<Typography sx={{ p: 2, paddingTop: 1, color: 'text.secondary' }}>
					Click on the buttons below
					<br />
					to get more options.
				</Typography>
				{list()}
			</Drawer>
		</>
	);
};

export default connect(mapLanguageStateToProps)(ReviewerOptionsMenu);
