import React, { useEffect } from 'react';
import { ILanguage } from '../../models/language.model';
import { connect } from 'react-redux';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SignIn from './SignIn';
import ResetPassword from './ResetPassword';
import SignUp from './SignUp';
import DialogClose from '../DialogClose';
import translations from '../../data/translations';

type IProps = {
	language: ILanguage;
	openLogin?: boolean;
	closeLogin?: (close: boolean) => void;
};

const AppBarAuth: React.FC<IProps> = ({
	language,
	openLogin = false,
	closeLogin,
}) => {
	const [openModal, setOpenModal] = React.useState<boolean>(false);
	const [openSignIn, setOpenSignIn] = React.useState<boolean>(false);
	const [openSignUp, setOpenSignUp] = React.useState<boolean>(false);
	const [openResetPW, setOpenResetPW] = React.useState<boolean>(false);

	const reset = () => {
		setOpenSignUp(false);
		setOpenSignIn(false);
		setOpenResetPW(false);
	};

	const handleModal = (open: boolean = false) => {
		setOpenModal(open);

		if (!open && openLogin && closeLogin) {
			closeLogin(true);
		}

		if (!open) {
			reset();
		}
	};

	const toggleSignUp = (open: boolean = false) => {
		reset();
		setOpenSignUp(true);
	};

	const toggleSignIn = (open: boolean = false) => {
		reset();
		setOpenSignIn(true);
	};

	const toggleForgotPW = (open: boolean = false) => {
		reset();
		setOpenResetPW(true);
	};

	useEffect(() => {
		if (openLogin) {
			reset();
			setOpenModal(true);
			setOpenSignIn(true);
		}
	}, [openLogin]);

	return (
		<>
			<Box display="flex" gap="10px">
				<Button
					variant="contained"
					onClick={(e) => {
						setOpenSignUp(true);
						handleModal(true);
					}}
				>
					{language.isEnglish
						? translations.signUp.eng
						: translations.signUp.afr}
				</Button>
				<Button
					variant="outlined"
					onClick={(e) => {
						setOpenSignIn(true);
						handleModal(true);
					}}
				>
					{language.isEnglish
						? translations.signIn.eng
						: translations.signIn.afr}
				</Button>
			</Box>

			<Dialog fullWidth open={openModal} onClose={() => handleModal(false)}>
				<DialogTitle>
					<Link
						to=""
						className={!openSignIn ? 'not-active' : 'active'}
						onClick={(e) => {
							e.preventDefault();
							setOpenSignIn(true);
							setOpenSignUp(false);
						}}
					>
						{language.isEnglish
							? translations.signIn.eng
							: translations.signIn.afr}
					</Link>
					<span>/</span>
					<Link
						to=""
						className={!openSignUp ? 'not-active' : 'active'}
						onClick={(e) => {
							e.preventDefault();
							setOpenSignIn(false);
							setOpenSignUp(true);
						}}
					>
						{language.isEnglish
							? translations.signUp.eng
							: translations.signUp.afr}
					</Link>
					<DialogClose
						onClick={(e) => {
							e.preventDefault();
							handleModal(false);
						}}
					/>
				</DialogTitle>
				<DialogContent>
					{openSignIn && (
						<SignIn
							toggleSignUp={toggleSignUp}
							toggleForgotPW={toggleForgotPW}
						/>
					)}
					{openSignUp && (
						<SignUp
							toggleSignIn={toggleSignIn}
							toggleForgotPW={toggleForgotPW}
						/>
					)}
					{openResetPW && <ResetPassword toggleSignUp={toggleSignUp} />}
				</DialogContent>
			</Dialog>
		</>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(AppBarAuth);
