import { Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const HeadingFour: React.FC<any> = ({ children, ...props }) => {

  return (
    <Typography 
      variant="h4" 
      gutterBottom
      {...props}
    >
      { children }
    </Typography>
  );
}

export default styled(HeadingFour)({});