import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { NavLink, Navigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { connect } from 'react-redux';
import { ILanguage } from '../../models/language.model';
import translations, { ILanguageOptions } from '../../data/translations';
import {
	Alert,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { EntryTypes } from '../../data/entry-types';
import Links from './Links';

type IProps = {
	language: ILanguage;
	toggleSignIn: (open: boolean) => void;
	toggleForgotPW: (open: boolean) => void;
};

const SignUp: React.FC<IProps> = ({
	language,
	toggleSignIn,
	toggleForgotPW,
}) => {
	const [redirect, setRedirect] = useState<boolean>(false);
	const [errorResponse, setErrorResponse] = useState<string>('');
	const [showPassword, setShowPassword] = React.useState<boolean>(false);
	const [based_in, setBasedIn] = useState<string>('');
	const [student_number, setStudentNumber] = useState<string>('');
	const [company, setCompany] = useState<string>('');
	const [entry_type, setEntryType] = React.useState<string>('');

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	// Google reCAPTCHA
	const [token, setToken] = useState<string>('');
	const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);

	const onVerify = useCallback(
		(token: string): void => {
			setToken(token);
		},
		[token]
	);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (token) {
			const data = new FormData(event.currentTarget);
			await axios
				.post('register', {
					first_name: data.get('firstName'),
					last_name: data.get('lastName'),
					email: data.get('email'),
					contact_number: data.get('contactNumber'),
					password: data.get('password'),
					password_confirm: data.get('passwordConfirm'),
					language: language.isEnglish ? 'English' : 'Afrikaans',
					based_in,
					entry_type,
					company,
					student_number,
				})
				.then(async (response) => {
					await axios
						.post('login', {
							email: data.get('email'),
							password: data.get('password'),
						})
						.then((response) => {
							setRedirect(true);
						})
						.catch((error) => {
							const response = JSON.parse(error.request.response);
							setErrorResponse(response.message);
						});

					setRefreshReCaptcha(true);
				})
				.catch((error) => {
					const response = JSON.parse(error.request.response);
					setErrorResponse(response.message);
				});
		}
	};

	let entry_types = EntryTypes;

	const handleChangeEntryType = (event: SelectChangeEvent) => {
		setEntryType(event.target.value as string);
	};

	if (redirect) {
		return <Navigate to={'/instructions'} />;
	}

	return (
		<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
			<GoogleReCaptcha
				onVerify={onVerify}
				refreshReCaptcha={refreshReCaptcha}
			/>

			{errorResponse !== '' && (
				<Alert severity="warning" className="mb-3">
					{errorResponse}
				</Alert>
			)}

			<Box gap="15px" display="flex" flexDirection="column" marginBottom={2}>
				<Typography
					component="p"
					className="mb-0 mt-2"
					variant="bodyL"
					fontWeight="bold"
				>
					{language.isEnglish ? 'Personal Details' : 'Persoonlike Inligting'}
				</Typography>

				<TextField
					autoComplete="given-name"
					name="firstName"
					required
					fullWidth
					id="firstName"
					label={
						language.isEnglish ? translations.fName.eng : translations.fName.afr
					}
					autoFocus
				/>

				<TextField
					required
					fullWidth
					id="lastName"
					label={
						language.isEnglish ? translations.lName.eng : translations.lName.afr
					}
					name="lastName"
					autoComplete="family-name"
				/>

				<TextField
					name="contactNumber"
					required
					fullWidth
					id="contactNumber"
					label={
						language.isEnglish
							? translations.contactNo.eng
							: translations.contactNo.afr
					}
				/>

				<TextField
					required
					label={
						language.isEnglish
							? translations.basedIn.eng
							: translations.basedIn.afr
					}
					variant="outlined"
					fullWidth={true}
					defaultValue={based_in}
					onChange={(e) => setBasedIn(e.target.value)}
				/>

				<FormControl fullWidth>
					<InputLabel>
						{language.isEnglish
							? translations.entryType.eng
							: translations.entryType.afr}
					</InputLabel>
					<Select
						value={entry_type}
						label={
							language.isEnglish
								? translations.entryType.eng
								: translations.entryType.afr
						}
						onChange={handleChangeEntryType}
					>
						{entry_types.map((type: ILanguageOptions, index: number) => {
							return (
								<MenuItem key={index} value={type.eng}>
									{language.isEnglish ? type.eng : type.afr}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>

				{entry_type === entry_types[0].eng && (
					<Grid item xs={12} sm={6}>
						<TextField
							className="mb-3"
							label={
								language.isEnglish
									? translations.studentNumber.eng
									: translations.studentNumber.afr
							}
							variant="outlined"
							fullWidth={true}
							defaultValue={student_number}
							onChange={(e) => setStudentNumber(e.target.value)}
						/>
					</Grid>
				)}

				{entry_type === entry_types[1].eng && (
					<Grid item xs={12} sm={6}>
						<TextField
							className="mb-3"
							label={
								language.isEnglish
									? translations.companyName.eng
									: translations.companyName.afr
							}
							variant="outlined"
							fullWidth={true}
							defaultValue={company}
							onChange={(e) => setCompany(e.target.value)}
						/>
					</Grid>
				)}

				<Typography
					component="p"
					className="mb-0"
					variant="bodyL"
					fontWeight="bold"
				>
					{language.isEnglish ? 'Login Details' : 'Inskrywing'}
				</Typography>

				<TextField
					required
					fullWidth
					id="email"
					label={
						language.isEnglish ? translations.email.eng : translations.email.afr
					}
					name="email"
					autoComplete="email"
				/>

				<FormControl required fullWidth={true} variant="outlined">
					<InputLabel htmlFor="outlined-password">
						{language.isEnglish
							? translations.password.eng
							: translations.password.afr}
					</InputLabel>
					<OutlinedInput
						required
						type={showPassword ? 'text' : 'password'}
						endAdornment={
							<InputAdornment position="end">
								<IconButton onClick={handleClickShowPassword} edge="end">
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label={
							language.isEnglish
								? translations.password.eng
								: translations.password.afr
						}
						name="password"
						autoComplete="new-password"
					/>
				</FormControl>

				<FormControl fullWidth={true} required variant="outlined">
					<InputLabel htmlFor="outlined-password">
						{language.isEnglish
							? `${translations.confirm.eng} ${translations.password.eng}`
							: `${translations.confirm.afr} ${translations.password.afr}`}
					</InputLabel>
					<OutlinedInput
						required
						type={showPassword ? 'text' : 'password'}
						endAdornment={
							<InputAdornment position="end">
								<IconButton onClick={handleClickShowPassword} edge="end">
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label={
							language.isEnglish
								? `${translations.confirm.eng} ${translations.password.eng}`
								: `${translations.confirm.afr} ${translations.password.afr}`
						}
						name="passwordConfirm"
						autoComplete="new-password-confirm"
					/>
				</FormControl>
			</Box>

			<Links toggleSignIn={toggleSignIn} signInText />

			<Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
				{language.isEnglish ? translations.signUp.eng : translations.signUp.afr}
			</Button>
		</Box>
	);
};

export default connect(mapLanguageStateToProps)(SignUp);
