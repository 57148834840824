import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import {
	Button,
	createTheme,
	responsiveFontSizes,
	ThemeProvider,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { themeOptions } from '../../styles/theme';
import logo from '../../assets/logo.svg';
import Copyright from '../admin/Copyright';
import { User } from '../../models/user.model';
import axios from 'axios';
import {
	mapDispatchToProps,
	mapStateToProps,
} from '../../redux/props/user.props';
import { connect } from 'react-redux';
import AppBarLanguage from '../admin/bar/AppBarLanguage';
import HeaderNav from '../landing/HeaderNav';
import AppBarAuth from '../auth/AppBarAuth';
import { ErrorResponse } from '../../models/shared.model';

interface IProps {
	closeLogin?: (close: boolean) => void;
	openLogin?: boolean;
	children: React.ReactNode;
	setUser: (user: User) => void;
	user: User;
}

const DefaultLayout: React.FC<IProps> = ({
	children,
	setUser,
	user,
	openLogin = false,
	closeLogin,
}) => {
	let mdTheme = createTheme(themeOptions);
	mdTheme = responsiveFontSizes(mdTheme);
	const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(true);

	useEffect(() => {
		async function getUser() {
			const verify = await axios.get('auth/verify');

			if (verify.data) {
				setLoggedIn(true);
				setLoading(false);
			}

			try {
				const { data } = await axios.get('user');

				if (data?.id) {
					setUser(
						new User(
							data.id,
							data.first_name,
							data.last_name,
							data.email,
							data.role,
							data.contact_number,
							data.based_in
						)
					);
				}
			} catch (err: any) {
				const error: ErrorResponse = err;

				if (error.response.status === 401) {
					setUser(new User());
				}
			}
		}
		getUser();
	}, [setUser]);

	const onCloseLogin = (close: boolean) => {
		closeLogin && closeLogin(close);
	};

	const theme = useTheme();

	return (
		<ThemeProvider theme={mdTheme}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<AppBar
					position="static"
					variant="outlined"
					sx={{
						borderRadius: 0,
						'.MuiContainer-root.MuiContainer-maxWidthXl': {
							marginBottom: 0,
							paddingBottom: '10px',
							paddingTop: '10px',
						},
					}}
				>
					<Container maxWidth="xl">
						<Toolbar
							sx={{
								minHeight: {
									xs: '0',
									lg: '56px',
								},
							}}
							disableGutters
						>
							<Typography
								component="a"
								href="/"
								sx={{
									display: 'block',
									height: {
										xs: '26px',
										lg: '40px',
									},
								}}
							>
								<img
									src={logo}
									height="100%"
									alt="Silwerskerm Fees"
									className="me-4"
								/>
							</Typography>

							<HeaderNav />

							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									flexGrow: 0,
									[theme.breakpoints.down('lg')]: {
										marginLeft: 'auto',
									},
									'.MuiButtonBase-root': {
										[theme.breakpoints.down('lg')]: {
											padding: '0 var(--spacer-xs)',
											maxHeight: '35px',
											fontSize: '14px',
											lineHeight: '14px',
											minHeight: '35px',
										},
									},
								}}
							>
								<Box
									sx={{
										display: { xs: 'none', lg: 'block' },
									}}
								>
									<AppBarLanguage />
								</Box>
								{user.id === 0 && (
									<AppBarAuth
										openLogin={openLogin}
										closeLogin={(close) => onCloseLogin(close)}
									/>
								)}
								{user.id > 0 && (
									<Button href="/dashboard" variant="contained" color="primary">
										<span className="icon icon-dashboard"></span>Dashboard
									</Button>
								)}
							</Box>
						</Toolbar>
					</Container>
				</AppBar>

				{children}

				<Copyright />
			</Box>
		</ThemeProvider>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
