import React from 'react';
import { Tooltip } from '@mui/material';

interface IProps {
	children: React.ReactElement<any, any>;
	text: string;
}

const ButtonTooltip: React.FC<IProps> = ({ children, text }) => {
	return <Tooltip title={text} placement="top" arrow children={children} />;
};

export default ButtonTooltip;
