import { Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import AdminLayout from '../../components/layouts/Admin';
import { ILanguage } from '../../models/language.model';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../../redux/props/language.props';
import ActiveSeasons from '../../components/landing/Seasons';

type IProps = {
	language: ILanguage;
};

const EntryCreate: React.FC<IProps> = ({ language }) => {
	return (
		<AdminLayout
			breadcrumbs={[language.isEnglish ? 'Create new entry' : 'Skryf in!']}
			maxWidth="lg"
		>
			{/* <Typography variant="h3" component="h2">
				{language.isEnglish ? 'Create new entry' : 'Skryf in!'}
			</Typography> */}
			<ActiveSeasons dashboard={true} language={language} gridView={true} />
		</AdminLayout>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(EntryCreate);
