import ButtonTooltip from '../../../components/ButtonTooltip';
import { Link } from 'react-router-dom';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from '@mui/material';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastrSettings } from '../../../data/toastr';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

interface IParams {
	row: {
		id: number;
	};
}

export const RenderSubmissionButtons = (params: IParams) => {
	const [deleteDialogOpen, setDeleteDialogState] = useState<boolean>(false);

	const openDeleteDialog = () => {
		setDeleteDialogState(true);
	};

	const closeDeleteDialog = () => {
		setDeleteDialogState(false);
	};

	const deleteSubmission = async () => {
		await axios.delete(`submissions/${params.row.id}/delete`);
		toast.success('Entry deleted successfully!', ToastrSettings);
		closeDeleteDialog();
	};

	return (
		<>
			<ToastContainer />

			<ButtonTooltip text="View Entry">
				<IconButton
					color="secondary"
					component={Link}
					to={`/submissions/${params.row.id}`}
				>
					<ReadMoreIcon fontSize="small" />
				</IconButton>
			</ButtonTooltip>

			<ButtonTooltip text="Delete Entry">
				<IconButton color="secondary" onClick={openDeleteDialog}>
					<DeleteIcon fontSize="small" />
				</IconButton>
			</ButtonTooltip>

			<Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>Are you sure you want to delete this?</DialogTitle>
				<DialogContent>
					<DialogContentText>
						You won't be able to undo this action.
					</DialogContentText>
				</DialogContent>
				<DialogActions className="justify-content-center">
					<Button variant="outlined" autoFocus onClick={closeDeleteDialog}>
						Cancel
					</Button>
					<Button variant="contained" onClick={deleteSubmission}>
						Yes, proceed!
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
