import { IFieldType } from './form.model';
import { ReviewerGroup } from './reviewers.model';
import { ISubmission } from './submissions.model';

export type ISeasonType = 'Short documentaries' | 'Shortfilms';

export interface ISeason {
	id: number;
	title: string;
	title_afr?: string;
	description?: string;
	description_afr?: string;
	cost?: number;
	start_date: Date;
	end_date: Date;
	thumbnail_image_url?: string;
	banner_image_url?: string;
	closed?: boolean;
	form?: ISeasonFormField[];
	submissions?: ISubmission[];
	terms_and_conditions: string;
	terms_and_conditions_afr: string;
	reviewer_groups?: ReviewerGroup[];
	payments_received?: number;
	type?: ISeasonType;
	group?: ISeasonGroup;
}

export interface ISeasonMeta {
	last_page: number;
	page: number;
	total: number;
}

export class Season {
	constructor(
		public id = 0,
		public title = '',
		public title_afr = '',
		public desctiption = '',
		public description_afr = '',
		public start_date = new Date(),
		public end_date = new Date(),
		public cost = 0,
		public terms_and_conditions = '',
		public terms_and_conditions_afr = ''
	) {}

	get name() {
		return '';
	}
}

export interface ISeasonFormField {
	id?: number;
	label: string;
	label_afr?: string;
	max_chars: any;
	required: boolean;
	hide_on_review_form: boolean;
	type?: IFieldType;
	season?: number;
}

export interface ISeasonGradingField {
	id?: number;
	description: string;
	grading_score: number | string;
	season?: number | string;
	answer?: number | string;
}

export interface ISeasonMenu {
	id: number;
	title: string;
	title_afr?: string;
}

export type ISeasonGroup = {
	id: number;
	title: string;
	title_afr: string;
};

export type ISeasonsPerGroup = {
	id: number;
	title: string;
	title_afr: string;
	seasons: ISeason[];
	totalApplications?: number;
};
