import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import { useForm, useFieldArray } from 'react-hook-form';
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	Link,
	MenuItem,
	Select,
	Switch,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { IFieldType } from '../../models/form.model';
import axios from 'axios';
import { ISeason, ISeasonFormField, Season } from '../../models/seasons.model';
import SaveIcon from '@mui/icons-material/Save';
import SeasonBreadcrumbs from './components/Breadcrumbs';
import SeasonOptions from './components/Options';

interface IProps {}

interface URLParams {
	id: number;
}

// TODO NB

const SeasonForm: React.FC<IProps> = () => {
	const { id } = useParams<URLParams | any>();
	const [season, setSeason] = useState<ISeason>(new Season());
	const [field_types, setFieldTypes] = useState<IFieldType[]>([]);
	const [form, setForm] = useState<ISeasonFormField[]>([]);
	const [redirect, setRedirect] = useState<boolean>(false);

	useEffect(() => {
		async function getData() {
			const types = await axios.get('field-types');
			const get_season = await axios.get(`seasons/${id}`, {
				params: {
					file: 'src/pages/seasons/SeasonForm.tsx',
				},
			});
			const season = get_season.data;

			setSeason(season);
			setForm(season.form);
			setFieldTypes(types.data);
		}
		getData();
	}, [id]);

	const defaultValues = {
		id: null,
		season: Number(id),
		label: '',
		label_afr: '',
		max_chars: '',
		required: false,
		hide_on_review_form: false,
		type: 1,
	};

	const { control, register, handleSubmit } = useForm({
		defaultValues: {
			form: [defaultValues],
		},
		values: {
			form: form,
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'form',
	});

	const onSave = (data: any) => {
		let { form } = data;

		axios.put(`seasons/${id}/form`, {
			form,
		});

		setRedirect(true);
	};

	if (redirect) {
		return <Navigate to={`/seasons/${id}/edit`} />;
	}

	return (
		<AdminLayout>
			<SeasonBreadcrumbs id={id} title={season.title} page="Application Form" />
			<form onSubmit={handleSubmit(onSave)}>
				<Grid container spacing={2} className="mb-2">
					<Grid item xs={6}>
						<Typography variant="h3" component="h2">
							{form.length > 0 ? 'Update' : 'Create'} Application Form
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Box
							sx={{
								marginBottom: '1.5em',
								float: 'right',
							}}
						>
							<SeasonOptions id={id} />

							<Button variant="contained" color="success" type="submit">
								<SaveIcon fontSize="small" className="me-2" />
								Save Details
							</Button>
						</Box>
					</Grid>
				</Grid>

				{fields.map((field, index) => (
					<Grid key={field.id} container spacing={2} className="mb-5">
						<TextField
							type="number"
							value={Number(id)}
							{...register(`form.${index}.season`)}
							hidden
						/>
						<Grid item xs={5}>
							<TextField
								id="outlined-basic"
								label="Label English"
								variant="outlined"
								size="small"
								fullWidth
								multiline
								required
								className="mb-2"
								{...register(`form.${index}.label`)}
							/>
							<TextField
								id="outlined-basic"
								label="Label Afrikaans"
								variant="outlined"
								size="small"
								fullWidth
								multiline
								required
								{...register(`form.${index}.label_afr`)}
							/>
						</Grid>
						<Grid item xs={3}>
							<FormControl fullWidth size="small">
								<InputLabel id={'type' + index}>Type</InputLabel>
								<Select
									required
									labelId={'type' + index}
									label="Type"
									defaultValue={
										fields[index].type!.id ? fields[index].type!.id : 1
									}
									{...register(`form.${index}.type`)}
								>
									{field_types.map((f: IFieldType) => {
										return (
											<MenuItem key={f.id} value={f.id}>
												{f.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<FormControlLabel
								sx={{ marginTop: 1 }}
								id={index + ''}
								control={
									<Switch
										id={index + ''}
										defaultChecked={fields[index].hide_on_review_form}
										{...register(`form.${index}.hide_on_review_form`)}
									/>
								}
								label="Hide this answer on review form"
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								id="outlined-basic"
								label="Maximum Characters"
								variant="outlined"
								size="small"
								type="number"
								fullWidth
								required
								{...register(`form.${index}.max_chars`)}
							/>
						</Grid>
						<Grid item>
							<FormControlLabel
								id={index + ''}
								control={
									<Switch
										id={index + ''}
										defaultChecked={fields[index].required}
										{...register(`form.${index}.required`)}
									/>
								}
								label="Required"
							/>
						</Grid>
						<Grid item>
							{fields.length !== 1 && (
								<Tooltip title="Delete" placement="right" arrow>
									<IconButton color="secondary" onClick={() => remove(index)}>
										<DeleteIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							)}
						</Grid>
					</Grid>
				))}

				<Button
					className="mt-2 mb-2 float-end"
					size="medium"
					variant="outlined"
					fullWidth
					color="secondary"
					endIcon={<AddBoxIcon />}
					onClick={() => append(defaultValues)}
				>
					Add Row
				</Button>
			</form>
		</AdminLayout>
	);
};

export default SeasonForm;
