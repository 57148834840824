import { Typography } from '@mui/material';
import React from 'react';
import { Spinner } from 'react-bootstrap';

interface IProps {
	text?: string;
}

const Loading: React.FC<IProps> = ({ text = 'Loading' }) => {
	return (
		<Typography
			variant="h6"
			component="div"
			sx={{
				display: 'flex',
				justifyContent: 'flex-start',
				marginTop: 2,
			}}
		>
			<Spinner
				animation="border"
				role="status"
				style={{
					marginRight: '20px',
				}}
			>
				<span className="visually-hidden">{text}...</span>
			</Spinner>{' '}
			{text}...
		</Typography>
	);
};

export default Loading;
