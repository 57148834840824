import React, { useEffect, useState } from 'react';
import AdminLayout from '../../components/layouts/Admin';
import ReviewerOptionsMenu from './components/ReviewerOptionsMenu';
import { ILanguage } from '../../models/language.model';
import { connect } from 'react-redux';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import axios from 'axios';
import { ISeasonID } from '../../models/submissions.model';
import translations from '../../data/translations';
import { Link } from 'react-router-dom';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Loading from '../../components/Loading';
import NoDataAvailable from '../../components/NoDataAvailable';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BallotIcon from '@mui/icons-material/Ballot';

interface IProps {
	language: ILanguage;
}

type iReviewer = {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
};

const Reviewers: React.FC<IProps> = ({ language }) => {
	const [loadingReviewers, setLoadingReviewers] = useState<boolean>(false);
	const [reviewers, setReviewers] = useState<iReviewer[]>([]);
	const [seasons, setSeasons] = useState<ISeasonID[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		async function fetchData() {
			setLoadingReviewers(true);
			setLoading(true);

			await axios.get('seasons/active/reviewers').then((seasons) => {
				setLoading(false);
				setSeasons(seasons.data);
			});

			await axios.get('reviewers/all/').then((reviewers) => {
				setLoadingReviewers(false);
				setReviewers(reviewers.data);
			});
		}
		fetchData();
	}, []);

	return (
		<AdminLayout>
			<Card sx={{ minWidth: 275 }}>
				<CardHeader
					style={{ backgroundImage: 'none' }}
					title={
						language.isEnglish
							? `Manage ${translations.reviewers.eng}`
							: `Bestuur ${translations.reviewers.afr}`
					}
					action={<ReviewerOptionsMenu />}
				/>
				<CardContent>
					{loading && <Loading />}

					{!loading && seasons.length > 0 && (
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell component="th">#</TableCell>
										<TableCell component="th" align="left">
											{language.isEnglish
												? translations.thTitle.eng
												: translations.thTitle.afr}
										</TableCell>
										<TableCell component="th" align="left">
											{language.isEnglish
												? translations.thEntries.eng
												: translations.thEntries.afr}
										</TableCell>
										<TableCell component="th" align="left">
											{language.isEnglish
												? translations.thAction.eng
												: translations.thAction.afr}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{seasons.map((season: ISeasonID) => {
										return (
											<TableRow
												key={season.id}
												sx={{
													'&:last-child td, &:last-child th': { border: 0 },
												}}
											>
												<TableCell component="th" scope="row" width={70}>
													{season.id}
												</TableCell>
												<TableCell align="left">
													<Link to={`/reviewers/${season.id}/reviews`}>
														{language.isEnglish
															? season.title
															: season.title_afr}
													</Link>
												</TableCell>
												<TableCell align="left">
													{season.total_submissions}
												</TableCell>
												<TableCell align="right" width={150}>
													<Tooltip
														title="Assign reviewers to groups"
														placement="top"
														arrow
													>
														<IconButton
															color="secondary"
															component={Link}
															to={`/reviewers/${season.id}/groups`}
														>
															<GroupAddIcon fontSize="small" />
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										);
									})}

									{!loading && seasons.length === 0 && (
										<NoDataAvailable message="There are no seasons available" />
									)}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</CardContent>
			</Card>

			{/* Reviewers */}
			<Card sx={{ minWidth: 275, marginTop: 3 }}>
				<CardHeader
					style={{ backgroundImage: 'none' }}
					title={
						language.isEnglish
							? translations.reviewers.eng
							: translations.reviewers.afr
					}
				/>
				<CardContent>
					{loadingReviewers && <Loading />}

					{!loadingReviewers && reviewers.length > 0 && (
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell component="th">#</TableCell>
										<TableCell component="th" align="left">
											{language.isEnglish
												? translations.thUserName.eng
												: translations.thUserName.afr}
										</TableCell>
										<TableCell component="th" align="left">
											{language.isEnglish
												? translations.thUserEmail.eng
												: translations.thUserEmail.afr}
										</TableCell>
										<TableCell component="th" align="left"></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{reviewers.map((reviewer, index) => {
										return (
											<TableRow
												key={`reviewer-${reviewer.id}-${index}`}
												sx={{
													'&:last-child td, &:last-child th': { border: 0 },
												}}
											>
												<TableCell component="th" scope="row" width={70}>
													{reviewer.id}
												</TableCell>
												<TableCell align="left">
													{reviewer.first_name} {reviewer.last_name}
												</TableCell>
												<TableCell align="left">{reviewer.email}</TableCell>
												<TableCell align="right" width={100}>
													<Button
														color="secondary"
														component={Link}
														to={`/reviewer/${reviewer.id}`}
														variant="outlined"
													>
														{/* <BallotIcon fontSize="small" /> */}
														&nbsp;View&nbsp;
													</Button>
												</TableCell>
											</TableRow>
										);
									})}

									{!loadingReviewers && reviewers.length === 0 && (
										<NoDataAvailable message="There are no reviewers available" />
									)}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</CardContent>
			</Card>
		</AdminLayout>
	);
};

export default connect(mapLanguageStateToProps)(Reviewers);
