import { IPermission } from "./permission.model";
import { IUser } from "./user.model";

export interface IRole {
  id: number;
  name: string;
  name_afr?: string;
  permissions?: IPermission[];
  not_customisable?: boolean;
}

export interface IRoleAdvanced {
  id: number;
  name: string;
  permissions?: IPermission[];
  not_customisable?: boolean;
  users: IUser[]
}

export class Role {
  constructor(
    public id = 0,
    public name = ''
  ) {
  }
}