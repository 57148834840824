import React, { useEffect, useState } from 'react';
import AdminLayout from '../../components/layouts/Admin';
import { connect } from 'react-redux';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { useParams } from 'react-router-dom';
import ReviewerBreadcrumbs from './components/ReviewerBreadcrumbs';
import { ToastContainer } from 'react-toastify';
import { LanguageProps, URLParams } from '../../models/shared.model';
import ReviewerOptionsMenu from './components/ReviewerOptionsMenu';
import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import { IDashboardReviewers } from '../../models/reviewers.model';
import axios from 'axios';
import Loading from '../../components/Loading';
import ButtonTooltip from '../../components/ButtonTooltip';
import { stringAvatar } from '../../functions/Global';
import { IReviewersAssignedEntries } from '../../models/reviewers.model';
import { ReactSortable } from 'react-sortablejs';
import { SortableWrapper } from '../../components/sortable/SortableWrapper';
import { IReviewer } from '../../models/user.model';
import SaveIcon from '@mui/icons-material/Save';

interface IParams {
	name: string;
	todo: number;
	done: number;
}

const ReviewersDashboardV2: React.FC<LanguageProps> = ({ language }) => {
	const [reviewers, setReviewers] = useState<IDashboardReviewers[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	let { id } = useParams<URLParams | any>();

	const group = 'entries';
	const animation = 150;

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			await axios.get(`reviewer-entries/${id}`).then((items) => {
				setLoading(false);
				setReviewers(items.data);
			});
		}
		fetchData();
	}, [id]);

	const setGroup = (entries: any, reviewerIndex: number) => {
		reviewers[reviewerIndex].assigned_entries[0].entries = entries;
		setReviewers([...reviewers]);
	};

	const saveEntries = async () => {
		// await axios.put(`reviewer-groups/${id}`, {
		// 	groups: JSON.stringify(groups)
		// })
		// .then(response => {
		// 	toast.success('Groups saved successfully!', ToastrSettings);
		// });
	};

	return (
		<AdminLayout>
			<ToastContainer />
			<ReviewerBreadcrumbs id={Number(id)} />
			<ReviewerOptionsMenu title="Assigned Entries" />

			<Button
				onClick={saveEntries}
				variant="contained"
				color="secondary"
				size="small"
				startIcon={<SaveIcon />}
				sx={{ marginRight: '1em' }}
			>
				Save Selection
			</Button>

			{loading && (
				<Loading text="Loading, please wait. This might take a while" />
			)}

			{!loading && (
				<Grid spacing={2} container>
					{reviewers.map((reviewer, reviewerIndex) => {
						const person = `${reviewer.first_name} ${reviewer.last_name}`;

						return (
							<Grid
								item
								xs={4}
								marginBottom={2}
								key={`reviewer_${reviewerIndex}`}
							>
								<Card>
									<CardHeader
										avatar={<Avatar {...stringAvatar(person)} />}
										title={person}
										// subheader="September 14, 2016"
									/>
									<CardContent>
										<ReactSortable
											group={group}
											tag={SortableWrapper}
											list={reviewer.assigned_entries[0].entries}
											animation={animation}
											setList={(e) => setGroup(e, reviewerIndex)}
										>
											{reviewer.assigned_entries[0].entries.map(
												(entry: IReviewersAssignedEntries, entryIndex) => {
													return (
														<Chip
															clickable={true}
															key={entryIndex}
															label={entry.entry_title}
														/>
													);
												}
											)}
										</ReactSortable>
									</CardContent>
								</Card>
							</Grid>
						);
					})}
				</Grid>
			)}
		</AdminLayout>
	);
};

export default connect(mapLanguageStateToProps)(ReviewersDashboardV2);
