import { Box, Button, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ActiveSeasons from '../components/landing/Seasons';
import DefaultLayout from '../components/layouts/Default';
import HeroBanner from '../components/landing/HeroBanner';
import Footer from '../components/landing/Footer';
import { ILanguage } from '../models/language.model';
import { connect } from 'react-redux';
import {
	mapLanguageDispatchToProps,
	mapLanguageStateToProps,
} from '../redux/props/language.props';

type IProps = {
	language: ILanguage;
};

const Landing: React.FC<IProps> = ({ language }) => {
	const [openLogin, setOpenLogin] = useState<boolean>(false);
	// const [landing_page_title, setLandingPageTitle] = useState<string>('');
	// const [landing_page_title_afr, setLandingPageTitleAfr] = useState<string>('');

	useEffect(() => {
		async function fetchData() {
			// const { data } = await axios.get(`settings/`);
			// const settings: SettingsModel = data;
			// setLandingPageTitle(settings.landing_page_title);
			// setLandingPageTitleAfr(settings.landing_page_title_afr);
		}
		fetchData();
	}, []);

	const onOpenLoginModal = () => {
		setOpenLogin(true);
	};

	const closeLogin = (close: boolean) => {
		setOpenLogin(!close);
	};

	return (
		<DefaultLayout closeLogin={closeLogin} openLogin={openLogin}>
			<HeroBanner />
			<Container component="main" maxWidth="xl" sx={{ mt: 9, mb: 12 }}>
				<Typography
					textAlign="center"
					variant="subheadingL"
					marginBottom="50px"
				>
					{language.isEnglish && (
						<>
							The Silwerskerm Film Festival, known as the Silwerskermfees, is a
							film festival held annually in Camps Bay, South Africa, since 2010
							and is one of a handful of Afrikaans film festivals in the world.
						</>
					)}

					{!language.isEnglish && (
						<>
							Die Silwerskermfees is ’n jaarlikse filmfees wat sedert 2010 in
							Kampsbaai, Suid-Afrika, aangebied word en is een van die enigste
							Afrikaanse filmfeeste ter wêreld.
						</>
					)}
				</Typography>
				<Box
					marginBottom={{
						xs: 'var(--spacer-l)',
						md: 'var(--spacer-xxl)',
					}}
					display="flex"
					justifyContent="center"
				>
					<Button
						variant="outlined"
						color="primary"
						href="/SSF 2025 Ideas Guide.pdf"
						target="_blank"
					>
						<span className="icon icon-download"></span>
						{language.isEnglish ? 'Download ideas guide' : 'Laai ideesgids af'}
					</Button>
				</Box>

				<ActiveSeasons
					openLoginModal={onOpenLoginModal}
					language={language}
					gridView={true}
					noAuthRequired={true}
				/>
			</Container>

			<Footer />
		</DefaultLayout>
	);
};

export default connect(
	mapLanguageStateToProps,
	mapLanguageDispatchToProps
)(Landing);
