export const downloadCsv = (fileName: string, blobData: Blob) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([blobData]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const reviewsCsvName = (title: string) => {
  const season_title = title.replaceAll(' ', '_').toLowerCase();
  return `reviews_${season_title}_${new Date().toLocaleDateString('sv')}.csv`;
}