import React, { useEffect, useState } from 'react';
import AdminLayout from '../../components/layouts/Admin';
import { connect } from 'react-redux';
import { mapLanguageStateToProps } from '../../redux/props/language.props';
import { useParams } from 'react-router-dom';
import ReviewerBreadcrumbs from './components/ReviewerBreadcrumbs';
import { ToastContainer } from 'react-toastify';
import { LanguageProps, URLParams } from '../../models/shared.model';
import ReviewerOptionsMenu from './components/ReviewerOptionsMenu';
import { Box, Chip, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import { IDashboardReviewers } from '../../models/reviewers.model';
import axios from 'axios';
import Loading from '../../components/Loading';
import { tabProps } from '../../functions/Tabs';
import TabPanelVertical from '../../components/TabPanelVertical';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import NoDataAvailable from '../../components/NoDataAvailable';
import AssignedEntries from './AssignedEntries';

interface IParams {
	name: string;
	todo: number;
	done: number;
}

const RenderReviewerTab = (params: IParams) => {
	return (
		<Stack direction="row" spacing={1} marginLeft={1}>
			<Typography>{params.name}</Typography>
			{/* <ButtonTooltip text="Todo">
				<Chip size="small" label={params.todo} />
			</ButtonTooltip>
			<ButtonTooltip text="Done">
				<Chip size="small" label={params.done} color="success" />
			</ButtonTooltip> */}
		</Stack>
	);
};

const ReviewersDashboard: React.FC<LanguageProps> = ({ language }) => {
	const [reviewers, setReviewers] = useState<IDashboardReviewers[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<number>(0);
	let { id } = useParams<URLParams | any>();

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			await axios.get(`reviewer-entries/${id}`).then((items) => {
				setLoading(false);
				setReviewers(items.data);
			});
		}
		fetchData();
	}, [id]);

	return (
		<AdminLayout maxWidth="lg">
			<ToastContainer />
			<ReviewerBreadcrumbs id={Number(id)} />
			<ReviewerOptionsMenu title="Assigned Entries" />
			{loading && <Loading text="Loading reviewers" />}
			{!loading && reviewers?.length === 0 && (
				<NoDataAvailable message="No reviewers available for this season" />
			)}
			{!loading && reviewers?.length > 0 && (
				<Box
					sx={{
						bgcolor: 'background.paper',
						display: 'flex',
						marginTop: 3,
					}}
				>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={activeTab}
						onChange={handleTabChange}
						sx={{
							minWidth: '150px',
							borderRight: 1,
							borderColor: 'divider',
						}}
					>
						{reviewers.map((reviewer: IDashboardReviewers, index) => {
							return (
								<Tab
									sx={{ alignItems: 'flex-end' }}
									key={index}
									label={
										<RenderReviewerTab
											name={`${reviewer.first_name} ${reviewer.last_name}`}
											todo={reviewer.count.todo}
											done={reviewer.count.done}
										/>
									}
									{...tabProps(index)}
								/>
							);
						})}
					</Tabs>
					<Box
						sx={{
							flexGrow: 1,
						}}
					>
						{reviewers.map((reviewer: IDashboardReviewers, index) => {
							return (
								<TabPanelVertical key={index} value={activeTab} index={index}>
									<Stack direction="row" spacing={2} marginBottom={2}>
										<Link
											href={`mailto:${reviewer.email}`}
											color="black"
											underline="none"
										>
											<EmailIcon sx={{ marginRight: 1 }} />
											{reviewer.email}
										</Link>

										{reviewer.contact_number && (
											<Typography>
												<CallIcon sx={{ marginRight: 1 }} />
												{reviewer.contact_number}
											</Typography>
										)}

										{reviewer.based_in && (
											<Typography>
												<LocationOnIcon sx={{ marginRight: 1 }} />
												{reviewer.based_in}
											</Typography>
										)}
									</Stack>
									<AssignedEntries season_id={Number(id)} id={reviewer.id} />
									{/* <Table
                    rows={reviewer.assigned_entries[0].entries}
                    columns={columns}
                  /> */}
								</TabPanelVertical>
							);
						})}
					</Box>
				</Box>
			)}
		</AdminLayout>
	);
};

export default connect(mapLanguageStateToProps)(ReviewersDashboard);
