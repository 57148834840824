import {
	Box,
	Breadcrumbs,
	Button,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	Link,
	MenuItem,
	OutlinedInput,
	Select,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/layouts/Admin';
import SaveIcon from '@mui/icons-material/Save';
import { Form } from 'react-bootstrap';
import SeasonOptions from './components/Options';
import { SeasonTypes } from '../../data/seasons';
import { ISeasonGroup } from '../../models/seasons.model';
// import ImageUpload from "../../components/ImageUpload";

interface IProps {}

interface URLParams {
	id: number;
}

const SeasonEdit: React.FC<IProps> = () => {
	let { id } = useParams<URLParams | any>();
	const [title, setTitle] = useState<string>('');
	const [title_afr, setTitleAfr] = useState<string>('');
	const [cost, setCost] = useState<number>(0);
	const [group, setGroup] = useState<number>(0);
	const [start_date, setStartDate] = useState<string>('');
	const [end_date, setEndDate] = useState<string>('');
	const [redirect, setRedirect] = useState<boolean>(false);
	const [description, setDescription] = useState<string>('');
	const [description_afr, setDescriptionAfr] = useState<string>('');
	const [type, setType] = React.useState(SeasonTypes[0].name);
	const [groups, setGroups] = useState<ISeasonGroup[]>([]);
	// const [banner_image_url, setBannerImage] = useState<string>('');
	// const [thumbnail_image_url, setThumbnailImage] = useState<string>('');

	useEffect(() => {
		async function fetchData() {
			const getGroups = await axios.get('season/groups');

			if (getGroups && getGroups.data) {
				setGroups(getGroups.data);
			}

			if (id) {
				const { data } = await axios.get(`seasons/${id}`, {
					params: {
						file: 'src/pages/seasons/SeasonEdit.tsx',
					},
				});

				if (data?.group && data?.group?.id) {
					setGroup(data.group.id);
				}

				setTitle(data.title);
				setTitleAfr(data.title_afr);
				setCost(data.cost);
				setStartDate(data.start_date);
				setEndDate(data.end_date);
				setDescription(data.description);
				setDescriptionAfr(data.description_afr);
				setType(data.type);
			}
		}
		fetchData();
	}, [id]);

	const isInvalid = () => {
		return !title || !cost || !start_date || !end_date ? true : false;
	};

	const submit = async (e: SyntheticEvent) => {
		e.preventDefault();

		if (id) {
			await axios.put(`seasons/${id}`, {
				title,
				title_afr,
				cost,
				start_date,
				end_date,
				description,
				description_afr,
				type,
				group,
			});
		} else {
			await axios.post(`seasons`, {
				title,
				title_afr,
				cost,
				start_date,
				end_date,
				description,
				description_afr,
				type,
			});
		}

		setRedirect(true);
	};

	const handleTypeChange = (
		event: React.MouseEvent<HTMLElement>,
		newType: string
	) => {
		setType(newType);
	};

	if (redirect) {
		return <Navigate to={'/seasons'} />;
	}

	return (
		<AdminLayout maxWidth="lg">
			<Breadcrumbs aria-label="breadcrumb">
				<Link underline="hover" color="inherit" href="/seasons">
					Seasons
				</Link>
				<Typography color="text.primary">{title}</Typography>
			</Breadcrumbs>
			<Form onSubmit={submit}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography variant="h3" component="h2">
							{id ? 'Edit' : 'Create New'} Season
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Box
							sx={{
								marginBottom: '1.5em',
								float: 'right',
							}}
						>
							<SeasonOptions id={id} />

							<Button
								variant="contained"
								color="success"
								type="submit"
								disabled={isInvalid()}
								className="ms-3"
							>
								<SaveIcon fontSize="small" className="me-2" />
								Save Details
							</Button>
						</Box>
					</Grid>
				</Grid>
				<Typography variant="h3" component="h2" className="mb-3">
					Title
				</Typography>
				<Grid container spacing={3} className="mb-3">
					<Grid item xs={8}>
						<FormControl className="mb-3" fullWidth>
							<InputLabel>English</InputLabel>
							<OutlinedInput
								label="English"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								required={true}
							/>
						</FormControl>
						<FormControl className="mb-3" fullWidth>
							<InputLabel>Afrikaans</InputLabel>
							<OutlinedInput
								label="Afrikaans"
								value={title_afr}
								onChange={(e) => setTitleAfr(e.target.value)}
								required={true}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<FormControl fullWidth>
							<InputLabel>Cost</InputLabel>
							<OutlinedInput
								startAdornment={
									<InputAdornment position="start">R</InputAdornment>
								}
								label="Cost"
								value={cost}
								onChange={(e) => setCost(Number(e.target.value))}
								required={true}
							/>
						</FormControl>
						<ToggleButtonGroup
							className="mt-3"
							color="secondary"
							value={type}
							exclusive
							onChange={handleTypeChange}
							size="small"
						>
							{SeasonTypes.map((type, index) => {
								return (
									<ToggleButton key={`season-type-${index}`} value={type.name}>
										<span className={`icon icon-start ${type.icon}`}></span>
										{type.name}
									</ToggleButton>
								);
							})}
						</ToggleButtonGroup>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<FormControl fullWidth>
							<InputLabel>Start date</InputLabel>
							<OutlinedInput
								type="date"
								label="Start date"
								value={start_date}
								onChange={(e) => setStartDate(e.target.value)}
								required={true}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<FormControl fullWidth>
							<InputLabel>End date</InputLabel>
							<OutlinedInput
								type="date"
								label="End date"
								value={end_date}
								onChange={(e) => setEndDate(e.target.value)}
								required={true}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6} lg={4}>
						<FormControl fullWidth>
							<InputLabel id="season_group">Category / group</InputLabel>
							<Select
								required
								labelId="season_group"
								label="Category / group"
								// defaultValue={group}
								value={group}
								onChange={(e) => setGroup(Number(e.target.value))}
							>
								{groups.map((g) => {
									return (
										<MenuItem key={`season_group_${g.id}`} value={g.id}>
											{g.title}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					{/* NOT READY YET */}
					{/* <Grid item xs={6}>
            <Form.Group>
              <Form.Label>
                Thumbnail Image
              </Form.Label>
            </Form.Group>
            <ImageUpload uploaded={setThumbnailImage} />
          </Grid>
          <Grid item xs={6}>
            <Form.Group>
              <Form.Label>
                Banner Image
              </Form.Label>
            </Form.Group>
            <ImageUpload uploaded={setBannerImage} />
          </Grid> */}
				</Grid>

				<Typography variant="h3" component="h2" marginTop={4} marginBottom={3}>
					Description
				</Typography>
				<FormControl className="mb-3" fullWidth>
					<InputLabel>English</InputLabel>
					<OutlinedInput
						label="English"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						multiline
					/>
				</FormControl>
				<FormControl className="mb-3" fullWidth>
					<InputLabel>Afrikaans</InputLabel>
					<OutlinedInput
						label="Afrikaans"
						value={description_afr}
						onChange={(e) => setDescriptionAfr(e.target.value)}
						multiline
					/>
				</FormControl>
			</Form>
		</AdminLayout>
	);
};

export default SeasonEdit;
