export type ILanguageOptions = {
	afr: string;
	eng: string;
};

type ITranslations = {
	email: ILanguageOptions;
	password: ILanguageOptions;
	signIn: ILanguageOptions;
	signUp: ILanguageOptions;
	confirm: ILanguageOptions;
	fName: ILanguageOptions;
	lName: ILanguageOptions;
	resetPw: ILanguageOptions;
	contactNo: ILanguageOptions;
	studentNumber: ILanguageOptions;
	company: ILanguageOptions;
	companyName: ILanguageOptions;
	language: ILanguageOptions;
	basedIn: ILanguageOptions;
	terms: ILanguageOptions;
	reviewers: ILanguageOptions;
	competition: ILanguageOptions;
	entryType: ILanguageOptions;

	// admin
	thTitle: ILanguageOptions;
	thStartDate: ILanguageOptions;
	thEndDate: ILanguageOptions;
	thEntries: ILanguageOptions;
	thCost: ILanguageOptions;
	thClosed: ILanguageOptions;
	thAction: ILanguageOptions;
	thPayRef: ILanguageOptions;
	thUserName: ILanguageOptions;
	thUserEmail: ILanguageOptions;
	thSeason: ILanguageOptions;
	thDraft: ILanguageOptions;
	thCreatedD: ILanguageOptions;
	thUpdatedD: ILanguageOptions;
	thSubmittedD: ILanguageOptions;
	thPayments: ILanguageOptions;
	logOut: ILanguageOptions;
};

const translations: ITranslations = {
	email: {
		afr: 'E-pos',
		eng: 'Email',
	},
	password: {
		afr: 'Wagwoord',
		eng: 'Password',
	},
	signIn: {
		afr: 'Teken In',
		eng: 'Sign In',
	},
	logOut: {
		eng: 'Log out',
		afr: 'Teken uit',
	},
	signUp: {
		afr: 'Teken Aan',
		eng: 'Sign Up',
	},
	confirm: {
		afr: 'Bevestig',
		eng: 'Confirm',
	},
	fName: {
		afr: 'Naam',
		eng: 'First Name',
	},
	lName: {
		afr: 'Van',
		eng: 'Last Name',
	},
	resetPw: {
		afr: 'Herstel wagwoord',
		eng: 'Reset Password',
	},
	contactNo: {
		afr: 'Kontak nommer',
		eng: 'Contact number',
	},
	studentNumber: {
		afr: 'Studentenommer',
		eng: 'Student number',
	},
	company: {
		afr: 'Maatskappy',
		eng: 'Company',
	},
	companyName: {
		afr: 'Maatskappy naam',
		eng: 'Company name',
	},
	language: {
		afr: 'Taal',
		eng: 'Lanuage',
	},
	basedIn: {
		afr: 'Gebasseer in',
		eng: 'Based in',
	},
	terms: {
		afr: 'Bepalings en voorwaardes',
		eng: 'Terms and conditions',
	},
	reviewers: {
		afr: 'Beoordelaars',
		eng: 'Reviewers',
	},
	entryType: {
		eng: 'Type of entry',
		afr: 'Tipe inskrywing',
	},
	thTitle: {
		afr: 'Titel',
		eng: 'Title',
	},
	thStartDate: {
		afr: 'Openingsdatum',
		eng: 'Start Date',
	},
	thEndDate: {
		afr: 'Sluitingsdatum',
		eng: 'End Date',
	},
	thEntries: {
		afr: 'Totale Inskrywings',
		eng: 'Total Entries',
	},
	thCost: {
		afr: 'Prys',
		eng: 'Cost',
	},
	thClosed: {
		afr: 'Gesluit',
		eng: 'Closed',
	},
	thAction: {
		afr: '',
		eng: '',
	},
	thPayRef: {
		afr: 'Betaling',
		eng: 'Payment',
	},
	thUserName: {
		afr: 'Persoon naam en van',
		eng: 'User fullname',
	},
	thUserEmail: {
		afr: 'Persoon e-pos',
		eng: 'User Email',
	},
	thSeason: {
		afr: 'Seisoen',
		eng: 'Season',
	},
	thDraft: {
		afr: 'Status',
		eng: 'Status',
	},
	thCreatedD: {
		afr: 'Skeppingsdatum',
		eng: 'Created date',
	},
	thUpdatedD: {
		afr: 'Opgedateerde datum',
		eng: 'Updated Date',
	},
	thSubmittedD: {
		afr: 'Inhandigingsdatum',
		eng: 'Submitted Date',
	},
	thPayments: {
		afr: 'Betalings Ontvang',
		eng: 'Payments Received',
	},
	competition: {
		eng: 'Competitions',
		afr: 'Kompetisies',
	},
};

export default translations;
