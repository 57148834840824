import { User } from "../../models/user.model";

export const setUserReducer = (state = { user: new User() }, action: { type: string, user: User }) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        ...action.user
      }
    default:
      return state;
  }
}