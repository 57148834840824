import { Box, Container, Link, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { ILanguage } from '../../models/language.model';
import { mapLanguageStateToProps } from '../../redux/props/language.props';

interface IProps {
	language: ILanguage;
}

const Copyright: React.FC<IProps> = ({ language }) => {
	return (
		<Box
			component="footer"
			sx={{
				backgroundColor: 'var(--grey-020)',
			}}
		>
			<Container
				maxWidth="xl"
				sx={{ paddingTop: '0 !important', paddingBottom: '0 !important' }}
			>
				<Box
					sx={{
						py: 3,
						borderTop: 'solid 1px var(--primary-800)',
					}}
				>
					<Typography
						variant="body2"
						className="mt-auto"
						color="text.secondary"
						sx={{
							marginTop: '2em',
						}}
					>
						{language.isEnglish ? 'Copyright' : 'Kopiereg'} &copy;
						<Link color="inherit" href="https://silwerskerm.tv">
							Silwerskerm Fees
						</Link>{' '}
						{new Date().getFullYear()}
						{'.'}
					</Typography>
				</Box>
			</Container>
		</Box>
	);
};

export default connect(mapLanguageStateToProps)(Copyright);
