import { Dispatch } from 'react';
import { ILanguageAction } from '../../models/language.model';
import { setLanguage } from "../actions/setLanguageAction";

export const mapLanguageStateToProps = (state: { language: ILanguageAction }) => {
  return {
    language: state.language
  };
}

export const mapLanguageDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setLanguage: (language: string) => dispatch(setLanguage(language))
  }
}