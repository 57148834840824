import React from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Users from './pages/users/Users';
import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import UserEdit from './pages/users/UserEdit';
import Roles from './pages/roles/Roles';
import RoleEdit from './pages/roles/RoleEdit';
import Landing from './pages/Landing';
import Seasons from './pages/seasons/Seasons';
import SeasonEdit from './pages/seasons/SeasonEdit';
import Settings from './pages/settings/Settings';
import Reviewers from './pages/reviewers/Reviewers';
import Submissions from './pages/submissions/Submissions';
import SeasonForm from './pages/seasons/SeasonForm';
import Payments from './pages/payments/Payments';
import Apply from './pages/Apply';
import Entries from './pages/entries/Entries';
import ConfirmEntry from './pages/transactions/ConfirmEntry';
import ConfirmPayment from './pages/transactions/ConfirmPayment';
import ConfirmSuccess from './pages/transactions/ConfirmSuccess';
import SeasonTerms from './pages/seasons/SeasonTerms';
import RequestResetPassword from './pages/auth/RequestResetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import TransactCancelled from './pages/transactions/TransactionCancelled';
import EntryEdit from './pages/entries/EntryEdit';
import Terms from './pages/Terms';
import EntryCreate from './pages/entries/EntryCreate';
import ProfileView from './pages/auth/ProfileView';
import ProfileEdit from './pages/auth/ProfileEdit';
import Submission from './pages/submissions/Submission';
import Entry from './pages/entries/Entry';
import AssignToGroups from './pages/reviewers/AssignToGroups';
import AssignEntries from './pages/reviewers/AssignEntries';
import SeasonGradingCategories from './pages/seasons/SeasonGradingCategories';
import EntryReview from './pages/entries/EntryReview';
import ReviewEntry from './pages/review/ReviewEntry';
import ReviewersDashboard from './pages/reviewers/ReviewersDashboard';
import ReviewersDashboardv2 from './pages/reviewers/ReviewersDashboardv2';
import Reviews from './pages/reviews/Reviews';
import ReviewPrint from './pages/reviews/ReviewPrint';
import Instructions from './pages/Instructions';
import ReviewerAssignedEntries from './pages/ReviewerAssignedEntries';
import ReviewerDashboardForAdmin from './pages/users/ReviewerDashboardForAdmin';

const App: React.FC<{}> = () => {
	return (
		<Routes>
			<Route path="/" element={<Landing />} />
			<Route path="/dashboard" element={<Dashboard />} />
			<Route path="/sign-up" element={<Register />} />
			<Route path="/sign-in" element={<Login />} />
			<Route path="/profile/edit" element={<ProfileEdit />} />
			<Route path="/profile" element={<ProfileView />} />
			<Route path="/reset-password" element={<RequestResetPassword />} />
			<Route path="/reset-password/:id/:token" element={<ResetPassword />} />
			<Route path="/sign-ip/verify/:otp/:email" element={<ResetPassword />} />

			<Route path="/users" element={<Users />} />
			<Route path="/users/create" element={<UserEdit />} />
			<Route path="/users/:id/edit" element={<UserEdit />} />

			<Route path="/roles" element={<Roles />} />
			<Route path="/roles/create" element={<RoleEdit />} />
			<Route path="/roles/:id/edit" element={<RoleEdit />} />

			<Route path="/seasons" element={<Seasons />} />
			<Route path="/seasons/create" element={<SeasonEdit />} />
			<Route path="/seasons/:id/edit" element={<SeasonEdit />} />
			<Route path="/seasons/:id/form" element={<SeasonForm />} />
			<Route
				path="/seasons/:id/grading"
				element={<SeasonGradingCategories />}
			/>
			<Route path="/seasons/:id/terms" element={<SeasonTerms />} />
			<Route path="/seasons/:id/apply" element={<Apply />} />

			{/* Random */}
			<Route path="/settings" element={<Settings />} />
			<Route path="/submissions" element={<Submissions />} />
			<Route path="/submissions/:id" element={<Submission />} />
			<Route path="/payments" element={<Payments />} />

			{/* User pages */}
			<Route path="/entries" element={<Entries />} />
			<Route path="/entries/:status" element={<Entries />} />
			<Route path="/entries/:id/edit" element={<EntryEdit />} />
			<Route path="/entries/:id/view" element={<Entry />} />
			<Route path="/entries/create" element={<EntryCreate />} />

			{/* Transaction pages */}
			<Route path="/entries/:id/transact/confirm" element={<ConfirmEntry />} />
			<Route
				path="/entries/:id/transact/pay/:transactionid"
				element={<ConfirmPayment />}
			/>
			<Route
				path="/entries/:id/transact/success/:transactionid"
				element={<ConfirmSuccess />}
			/>
			<Route
				path="/entries/:id/transact/cancelled/:transactionid"
				element={<TransactCancelled />}
			/>

			<Route path="/terms" element={<Terms />} />
			<Route path="/instructions" element={<Instructions />} />

			{/* Reviewer pages */}
			<Route path="/reviews" element={<Reviews />} />
			<Route path="/reviews/:id/print" element={<ReviewPrint />} />
			<Route path="/reviewers" element={<Reviewers />} />
			<Route path="/review/:season/:entry" element={<EntryReview />} />
			<Route path="/reviewers/:id/groups" element={<AssignToGroups />} />
			<Route path="/reviewers/:id/entries" element={<AssignEntries />} />
			<Route path="/reviewers/:id/reviews" element={<ReviewersDashboard />} />
			<Route path="/reviewers/:id/reasign" element={<ReviewersDashboardv2 />} />
			<Route path="/reviewer/:id" element={<ReviewerDashboardForAdmin />} />

			{/* Review pages */}
			<Route
				path="/review-entry/:season_id/:entry_id"
				element={<ReviewEntry />}
			/>
			<Route
				path="/review-entry/:season_id/:entry_id/:reviewer_id"
				element={<ReviewEntry />}
			/>
			<Route path="/assigned-entries/" element={<ReviewerAssignedEntries />} />

			{/* Website pages TODO */}
			{/* <Route path='/faq' element={<Entries />} />
      <Route path='/sponsers' element={<Entries />} />
      <Route path='/news' element={<Entries />} /> */}
		</Routes>
	);
};

export default App;
