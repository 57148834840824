import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { User } from '../../models/user.model';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/props/user.props';
import Loading from '../Loading';
import { IReviewersAssignedEntries } from '../../models/reviewers.model';
import { Box, Card, CardContent, CardHeader, Tab, Tabs } from '@mui/material';
import { ISeasonID } from '../../models/submissions.model';
import { ILanguage } from '../../models/language.model';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { StatusBox } from './components/StatusBox';
import { tabProps } from '../../functions/Tabs';
import { EntriesTable } from './components/EntriesTable';

interface IProps {
	season_id: number;
	user: User;
	season: ISeasonID;
	language: ILanguage;
	reviewerId?: number;
}

export type iRows = IReviewersAssignedEntries[];

const EntriesPerSeason: React.FC<IProps> = ({
	season_id,
	user,
	season,
	language,
	reviewerId,
}) => {
	const [allEntries, setAllEntries] = useState<iRows>([]);
	const [todoEntries, setTodoEntries] = useState<iRows>([]);
	const [doneEntries, setDoneEntries] = useState<iRows>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [value, setValue] = useState<number>(0);
	const [toDo, setToDo] = useState<number>(0);
	const [done, setDone] = useState<number>(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		async function fetchData() {
			setLoading(true);

			const user_id = reviewerId ? reviewerId : user.id;

			await axios
				.get(`reviewer-entries/${season_id}/${user_id}`)
				.then((entries) => {
					if (entries.data) {
						const list =
							entries.data.length > 1 ? entries.data : entries.data[0].entries;

						let totalDone: number = 0;
						let totalToDo: number = 0;

						const data: any[] = list.map((entry: any) => {
							const item = entries.data.length > 1 ? entry.entries[0] : entry;
							if (item.reviewed) {
								totalDone++;
							}
							if (!item.reviewed) {
								totalToDo++;
							}
							return {
								id: item.id,
								reviewer_id: user_id,
								entry_title: item.entry_title,
								reviewed: item.reviewed,
								season_id: season_id,
							};
						});

						setAllEntries(data);
						setTodoEntries(data.filter((item) => item.reviewed === false));
						setDoneEntries(data.filter((item) => item.reviewed === true));
						setToDo(totalToDo);
						setDone(totalDone);
					}
					setLoading(false);
				});
		}
		fetchData();
	}, [season_id]);

	return (
		<>
			{loading && (
				<Loading text={`Loading assigned entries for ${season.title}`} />
			)}
			{!loading && allEntries.length > 0 && (
				<Card sx={{ minWidth: 275 }}>
					<CardHeader
						avatar={
							<span
								className={`icon icon-${
									season.type === 'Shortfilms' ? 'camera' : 'movie'
								}`}
							></span>
						}
						className="season-title"
						title={language.isEnglish ? season.title : season.title_afr}
					/>
					<CardContent>
						<Box display="flex" gap="var(--spacer)">
							<StatusBox
								icon={<RadioButtonUncheckedIcon />}
								label="To Do"
								bgColor="var(--grey-100)"
								num={toDo}
								onClick={() => setValue(1)}
							/>
							<StatusBox
								icon={<CheckCircleOutlineIcon />}
								label="Done"
								bgColor="var(--primary-050)"
								color="var(--primary-800)"
								onClick={() => setValue(2)}
								num={done}
							/>
						</Box>
						<Box sx={{ width: '100%' }}>
							<Box sx={{ margin: 'var(--spacer) 0 var(--spacer-xs)' }}>
								<Tabs value={value} onChange={handleChange}>
									<Tab label="All Entries" {...tabProps(0)} />
									<Tab label="To Do" {...tabProps(1)} />
									<Tab label="Done" {...tabProps(2)} />
								</Tabs>
							</Box>
							<EntriesTable
								reviewerId={reviewerId}
								value={value}
								index={0}
								rows={allEntries}
							/>
							<EntriesTable
								reviewerId={reviewerId}
								value={value}
								index={1}
								rows={todoEntries}
							/>
							<EntriesTable
								reviewerId={reviewerId}
								value={value}
								index={2}
								rows={doneEntries}
							/>
						</Box>
					</CardContent>
				</Card>
			)}
			{/* {!loading && rows.length === 0 && (
				<>There are no entries assigned to you</>
			)} */}
		</>
	);
};

export default connect(mapStateToProps)(EntriesPerSeason);
