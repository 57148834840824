import {
	Box,
	Button,
	Checkbox,
	DialogContentText,
	FormLabel,
} from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import { IUser, User } from '../../models/user.model';
import { Submision } from '../../models/submissions.model';
import { Form } from 'react-bootstrap';

interface IProps {
	toggleNextStep: (step: number) => void;
	entryId: number;
	transactionId: number;
	retry?: boolean;
	cost: any;
}

const Step2PaymentDetails: React.FC<IProps> = ({
	retry = false,
	toggleNextStep,
	transactionId,
	entryId,
	cost,
}) => {
	const [entry, setEntry] = React.useState<Submision>();
	const [checked, setChecked] = React.useState<boolean>(false);
	const [user, setUser] = React.useState<IUser>(new User());
	const [form, setForm] = React.useState<string>('');

	React.useEffect(() => {
		async function fetchData() {
			const get_user = await axios.get('user');
			setUser(get_user.data);

			// Season details
			const get_entry = await axios.get(`entry/${entryId}`);
			const entry: Submision = get_entry.data;
			const reference = entry.answers[0].answer;

			await axios.put(`transaction/${transactionId}/${cost}`);

			setEntry(entry);

			// Payfast form
			const { data } = await axios.post(`entry/${entryId}/form`, {
				entry_id: entryId,
				transaction_id: transactionId,
				transaction_reference: reference,
				first_name: user.first_name,
				last_name: user.last_name,
				email_address: user.email,
				cell_number: user.contact_number,
				amount: entry.season.cost.toFixed(2),
				item_name: `${entry.season.title!}`,
				item_description: `Entry #${entryId}`,
			});

			setForm(data);
		}
		fetchData();
	}, [entryId]);

	return (
		<>
			<DialogContentText>
				You are about to pay{' '}
				<strong style={{ color: 'var(--primary-800)' }}>R</strong>{' '}
				<strong>{cost.toFixed(2)}</strong> and submit your entry{' '}
				<strong>#{entryId}</strong>
			</DialogContentText>
			<FormLabel className="cursor-pointer">
				<Checkbox
					checked={checked}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setChecked(event.target.checked);
					}}
				/>
				&nbsp;I Understand
			</FormLabel>

			<Form
				action="https://www.payfast.co.za/eng/process"
				method="post"
				id="payfast_form"
			>
				<span dangerouslySetInnerHTML={{ __html: form }}></span>
				<Box component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						variant="contained"
						type="submit"
						disabled={!checked}
						fullWidth
						sx={{ marginTop: 'var(--px)' }}
					>
						Confirm and Pay Now
					</Button>
				</Box>
			</Form>
		</>
	);
};

export default Step2PaymentDetails;
